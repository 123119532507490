import React, { useEffect } from "react";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";

import Container from "@mui/material/Container";
import PostCard from "components/Cards/PostCard";
import Grid from "@mui/material/Grid";
import PageCard from "components/Cards/PageCard";
import IncognitoCard from "components/Cards/IncognitoCard";

import MyThema from "components/Cards/MyThema";
import AddExperienceCard from "components/Cards/AddExperienceCard";
import { localStorageData } from "services/auth/localStorageData";
import StarCard from "components/Cards/StarCard";
import { Helmet, HelmetData } from "react-helmet-async";

import AdCard from "components/Cards/AdCard";
import AdData from "components/Data/AdData";
import StatsCard from "components/Cards/StatsCard";

function Home() {
  const [allPost, setallPost] = React.useState([]);
  const [disease, setDisease] = React.useState([]);
  const helmetData = new HelmetData({});

  const [allThema, setallThema] = React.useState([]);

  const [useForChange, setuseForChange] = React.useState(false);
  const [chunksPost, setchunksPost] = React.useState(0);
  const [moreRefetch, setmoreRefetch] = React.useState(true);

  const getAllPosts = useQuery(
    ["allpostdata", useForChange],
    () => userServices.commonGetService(`/post/getAllPost/${chunksPost}`),
    {
      refetchOnWindowFocus: false,
      ///// refetchInterval: moreRefetch == true ? 500 : false,
      refetchIntervalInBackground: true,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        if (res.data.data == "") {
          setmoreRefetch(false);
        } else {
          //// setmoreRefetch(false);
          setchunksPost(chunksPost + 10);

          for (let i = 0; i < res.data.data.length; i++) {
            res.data.data[i]["avgRating"] =
              res.data.data[i].expereince != ""
                ? Math.round(
                    res.data.data[i].expereince.reduce(
                      (sum, { rating }) => sum + rating,
                      0
                    ) / res.data.data[i].expereince.length
                  )
                : 0;
          }

          /// setallPost(res.data.data);
          // let joined = allPost.concat(res.data.data);
          // setallPost(joined);

          setallPost((oldArray) => [...oldArray, ...res.data.data]);
        }
      },
    }
  );

  const getAllDisease = useQuery(
    "allpostdisease",
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);
        const random = Math.floor(Math.random() * res.data.data.length);

        setDisease(res.data.data[random]);
      },
    }
  );

  const getAllThema = useQuery(
    "allpostThema",
    () =>
      userServices.commonGetService(
        `/post/getThemeByUserid/${localStorageData("_id")}`
      ),
    {
      enabled: localStorageData("_id") ? true : false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);

        setallThema(res.data.data);
      },
    }
  );

  function reftechThema(data) {
    getAllThema.refetch();
  }

  function reftechPost(data) {
    const newState = allPost.map((obj) => {
      // 👇️ if id equals 2 replace object
      if (obj._id === data[0]._id) {
        return data[0];
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setallPost(newState);

    ////// getAllPosts.refetch();
  }
  // window.onscroll = function (ev) {
  //   if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
  //     alert('s');
  //     setuseForChange(!useForChange);
  //     getAllPosts.refetch();
  //   }
  // };

  window.onscroll = function () {
    if (
      window.innerHeight + Math.ceil(window.pageYOffset) >=
      document.body.offsetHeight
    ) {
      setuseForChange(!useForChange);
      getAllPosts.refetch();
    }
  };

  return (
    <div className="min-h-screen">
      <Helmet prioritizeSeoTags helmetData={helmetData}>
        <meta charSet="utf-8" />
        <title>XPera | Erfahrungsaustausch in allen Gesundheitsthemen</title>
        <link rel="canonical" href="https://xpera.de/account/themaDetail" />
        <meta
          name="description"
          content="Finde das beste Mittel, die beste Therapie oder die besten Fachleute, auf Basis der Erfahrung von Menschen mit dem gleichen Problem wie Du"
        />
      </Helmet>

      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className=" my-8">
              <StatsCard />

              <AddExperienceCard routevalue={false} />

              <PostCard
                reftechPost={reftechPost}
                data={allPost}
                showAll={false}
                setallPost={setallPost}
              />
            </div>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className="flex my-8  mt-8">
              <PageCard
                data={disease}
                thema={allThema}
                reftechThema={reftechThema}
                setallPost={setallPost}
              />
            </div>
            <div className="flex my-4">
              <IncognitoCard />
            </div>
            {/* <br /> */}
            <div className="mt-6">
              <StarCard />
            </div>

            <div className=" my-4 ">
              <AdCard link={window.location.href} />
            </div>
          </Grid>
          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className=" fixed right-0 h-full">
              <MyThema data={allThema} disease={disease} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Home;
