import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import history from '../history';
import Signin from 'pages/Signin';
import Signup from 'pages/Signup';

import SignupThearapy from 'pages/SignupThearapy';




import UploadProfile from 'pages/UploadProfile';
import ForgotPassword from 'pages/ForgotPassword';
import UpdatePassword from 'pages/UpdatePassword';
import IndexNavbar from 'components/Navbars/IndexNavbar';
import AddInfo from 'pages/AddInfo';

export default function Auth() {
  return (
    <>
      <IndexNavbar />

      <main>
        <section className='relative w-full py-18 h-full  min-h-screen '>
          <Routes>
            <Route path='/signin' element={<Signin />} />
            <Route path='/' element={<Navigate to='/auth/signin' replace />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/updatepass/:email/:id' element={<UpdatePassword />} />
            <Route path='/signup' element={<Signup />} />

            <Route path='/SignupTherapy' element={<SignupThearapy />} />

            

            <Route path='/uploadprofile' element={<UploadProfile />} />
            <Route path='/addinfo' element={<AddInfo />} />
          </Routes>
        </section>
      </main>
    </>
  );
}
