import React, { useEffect } from "react";
import ShowMoreText from "react-show-more-text";
import Rating from "@mui/material/Rating";
import Collapse from "@mui/material/Collapse";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { useMutation } from "react-query";
import {
  localStorageData,
  getLocalUserdata,
} from "services/auth/localStorageData";
import { toast } from "react-toastify";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { strangerName, strangerPic, ImageEndPoint } from "config/config";
import useTranslation from "common/customHooks/translations";
import Options from "./Options";

import Options1 from "./Options1";
import WriteComment from "./WriteComment";

function HelpedComments(props) {
  const t = useTranslation();

  const [expanded, setExpanded] = React.useState(false);
  const [expandedEdit, setExpandedEdit] = React.useState(false);

  const [isEditSubComment, setisEditSubComment] = React.useState("");

  const [SubCommentId, setSubCommentId] = React.useState("");

  const [subComment, setsubComment] = React.useState("");
  const [showMore, setShowMore] = React.useState(1);

  const handleExpandEdit = (id) => {
    // setExpandedEdit(!expandedEdit);

    setExpandedEdit({
      ...expandedEdit,
      [id]: !expandedEdit[id],
    });
  };

  const handleExpandEdit1 = (id, subid, SubCommentId) => {
    setisEditSubComment(subid);

    setSubCommentId(SubCommentId);

    setsubComment(props.item.expereince[id].subcomments[subid].expereince);

    props.item.expereince[id].subcomments[subid].expereince = subComment;
  };

  useEffect(() => {
    if (props.showAll) {
      setShowMore(props.item.expereince.length);
    }
  }, []);

  const handleExpandClick = (id) => {
    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };
  const localtz = moment.tz.guess();

  const { IsCognito, currentPic } = useSelector((state) => state.User);

  const addSubComment = useMutation(
    (NewsubComment) =>
      userServices.commonPostService("/post/uploadChildComment", NewsubComment),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        ////// props.reftechPost(res.data.data);
        // setExpanded(false);
        ///  setuserSechema(user);
      },
    }
  );

  const editSubCommentPost = useMutation(
    (NewsubComment) =>
      userServices.commonPostService("/post/editChildComment", NewsubComment),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setSubCommentId("");

        /// isEditSubComment('')
        ////// props.reftechPost(res.data.data);
        // setExpanded(false);
        ///  setuserSechema(user);
      },
    }
  );

  const PostSubComment = async (Id, i) => {
    if (localStorageData("_id")) {
      if (subComment) {
        let data = {
          Isincognito: IsCognito,
          userId: localStorageData("_id"),
          Id: Id,
          own_experience: subComment,
          expereince: subComment,
          postId: props.item._id,
          timeZone: localtz,
          dateTime: new Date(),
          user: [getLocalUserdata()],
        };

        //////props.item.expereince[i].subcomments =
        let newsun = props.item.expereince[i].subcomments;

        let fim = newsun.concat(data);

        props.item.expereince[i].subcomments = fim;

        addSubComment.mutate(data);
        setsubComment("");
        setisEditSubComment("");
        ////////setShowMore(props.item.expereince.length)
      }
    } else {
      toast.error(t.loginfirst);
    }
  };

  const editSubComment = async (Id, mainid) => {
    if (localStorageData("_id")) {
      if (subComment) {
        let data = {
          Isincognito: IsCognito,
          userId: localStorageData("_id"),
          Id: SubCommentId,
          own_experience: subComment,
          expereince: subComment,
          postId: props.item._id,
          timeZone: localtz,
          dateTime: new Date(),
          user: [getLocalUserdata()],
        };

        props.item.expereince[mainid].subcomments[isEditSubComment].expereince =
          subComment;

        editSubCommentPost.mutate(data);
        setsubComment("");
        setisEditSubComment("");
        ////////setShowMore(props.item.expereince.length)
      }
    } else {
      toast.error(t.loginfirst);
    }
  };

  const updateLike = useMutation(
    (NewLike) => userServices.commonPostService("/social/updatelike", NewLike),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setsubComment('');
        // props.reftechPost(res.data.data);
      },
    }
  );

  function likes(alllikes, expereince) {
    var newlike = false;

    if (alllikes != undefined) {
      for (var i = 0; i < alllikes.length; i++) {
        if (alllikes[i].userId == localStorageData("_id")) {
          newlike = true;
        }
      }
    }

    return (
      <>
        <img
          id={expereince._id}
          src={
            newlike
              ? require("assets/img/like.png")
              : require("assets/img/dislike.png")
          }
          alt="logo"
          className=" h-5 w-5 object-cover"
        />
      </>
    );
  }

  return (
    <>
      <div>
        {props.item.expereince.length != 1 ? (
          <button
            onClick={() => setShowMore(props.item.expereince.length)}
            className="mb-4 text-xs2 mx-4"
          >
            {/* <span className='text-color-btn'>
            {props.item.expereince.length}{' '}
          </span> */}
            weitere Erfahrungen ansehen
            <i className="fas fa-caret-down ml-2"></i>
          </button>
        ) : null}

        {props.item.expereince.slice(0, showMore).map((expereince, i) => (
          <>
            {/* main comments */}
            <div className="bg-color-lightgrey shadow p-4 mx-6 rounded ">
              <div className="flex flex-row ">
                <img
                  id="output"
                  src={
                    expereince.Isincognito == false
                      ? ImageEndPoint + expereince.user[0].pic
                      : strangerPic
                  }
                  className="w-10 h-10 rounded-full object-cover"
                  alt=""
                  loading="lazy"
                />

                <div className="mx-3 w-full">
                  <div className="text-xs text-color-btn">
                    {/* {item.Isincognito ? 'Inkognitomodus' : item.user.fname} */}
                    {expereince.Isincognito == false
                      ? expereince.user[0].fname
                      : strangerName}
                    <span className="text-xs text-color-yellow ml-4 ">
                      &#8226;
                    </span>
                    <span className="text-[12px] text-color-grey ml-2 ">
                      {moment(expereince.dateTime).toNow().replace("in", "vor")}
                    </span>
                    <div className="lg:float-right sm:float-left -mt-1">
                      <Rating
                        name="simple-controlled"
                        value={expereince.rating}
                        readOnly
                        size="small"
                      />

                      <Options
                        id={i}
                        reftechPost={props.reftechPost}
                        expereince={expereince}
                        item={props.item}
                        expand={handleExpandEdit}
                      />
                    </div>
                  </div>
                  <ShowMoreText
                    /* Default options */
                    lines={2}
                    more={t.showmore}
                    less={t.showless}
                    className="text-xs"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    // width={280}
                    truncatedEndingComponent={"..."}
                  >
                    <div className="text-xs">
                      {expereince.expereince.split("\n").map((item, key) => {
                        return (
                          <span key={key}>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  </ShowMoreText>
                </div>
              </div>
            </div>

            <div className="  w-full m-2 ">
              {/* <div className='border-solid border-t border-color-grey p-4 mt-2 ' /> */}
              <button
                className="ml-20 text-xs2 text-color-grey  font-semibold"
                onClick={() => handleExpandClick(i)}
              >
                <span className="desktopview">
                  {expanded[i] ? "verberge Kommentare" : "zeige Kommentare"}
                </span>
              </button>
              <div className="flex text-xs2 mr-10 float-right">
                <a
                  className="cursor-pointer"
                  onClick={() => handleExpandClick(i)}
                >
                  <img
                    src={require("assets/img/comment.png")}
                    alt="logo"
                    className=" h-5 w-5 object-cover"
                  />
                </a>
                <span className="mx-2 ">{expereince.subcomments.length}</span>

                <button
                  onClick={() => {
                    if (localStorageData("_id")) {
                      updateLike.mutate({
                        _id: expereince._id,
                        userId: localStorageData("_id"),
                        postId: props.item._id,
                      });

                      if (
                        document.getElementById(expereince._id).src ==
                        require("assets/img/like.png")
                      ) {
                        document.getElementById(
                          expereince._id
                        ).src = require("assets/img/dislike.png");

                        document.getElementById(expereince._id + 1).innerHTML =
                          parseInt(
                            document.getElementById(expereince._id + 1)
                              .innerHTML
                          ) - 1;
                      } else {
                        document.getElementById(
                          expereince._id
                        ).src = require("assets/img/like.png");

                        document.getElementById(expereince._id + 1).innerHTML =
                          parseInt(
                            document.getElementById(expereince._id + 1)
                              .innerHTML
                          ) + 1;
                      }
                    } else {
                      toast.error(t.loginfirst);
                    }
                  }}
                  className="-mt-1 flex"
                >
                  {likes(expereince.alllikes, expereince)}

                  <span
                    className="m-1"
                    id={expereince._id + 1}
                    // value={expereince.likes}
                  >
                    {expereince.likes}
                    {/* {document.getElementById('628c599e441d5316240202a51')} */}
                  </span>
                </button>
              </div>
            </div>

            <Collapse in={expandedEdit[i]} timeout="auto" unmountOnExit>
              <WriteComment
                isEdit={true}
                reftechPost={props.reftechPost}
                expand={setExpandedEdit}
                item={props.item}
                expereince={expereince}
              />
            </Collapse>

            <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
              <div className="ml-20 ">
                {expereince.subcomments.map((subexpereince, j) => (
                  <>
                    {/* sub comments */}
                    <div className="bg-color-lightgrey shadow p-2 mx-6 my-4 rounded ">
                      <div className="flex flex-row ">
                        <img
                          id="output"
                          src={
                            subexpereince.Isincognito == false
                              ? ImageEndPoint + subexpereince.user[0].pic
                              : strangerPic
                          }
                          className="w-10 h-10 rounded-full object-cover"
                          alt=""
                          loading="lazy"
                        />
                        <div className="mx-4">
                          <div className="text-xs text-color-btn">
                            {/* {item.Isincognito ? 'Inkognitomodus' : item.user.fname} */}
                            {subexpereince.Isincognito == false
                              ? subexpereince.user[0].fname
                              : strangerName}
                            <span className="text-xs text-color-yellow ml-4 ">
                              &#8226;
                            </span>
                            <span className="text-[12px] text-color-grey ml-2 ">
                              {moment(subexpereince.dateTime)
                                .toNow()
                                .replace("in", "vor")}
                            </span>

                            <Options1
                              id={i}
                              subid={j}
                              reftechPost={props.reftechPost}
                              expereince={subexpereince}
                              item={props.item}
                              expand={handleExpandEdit1}
                              SubCommentId={subexpereince._id}
                            />
                          </div>
                          <ShowMoreText
                            /* Default options */
                            lines={2}
                            more={t.showmore}
                            less={t.showless}
                            className="text-xs2"
                            anchorClass="my-anchor-css-class"
                            expanded={false}
                            width={380}
                            truncatedEndingComponent={"..."}
                          >
                            <div className="text-xs2">
                              {subexpereince.expereince}
                            </div>
                          </ShowMoreText>
                        </div>
                      </div>
                    </div>
                  </>
                ))}

                <div className="my-4 mx-4">
                  <div
                    id="input_container"
                    className="relative w-[97%] border border-gray-300	mx-2  px-3 py-3  text-black bg-white rounded text-xs h-12 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-color-btn  ease-linear transition-all duration-150"
                  >
                    <input
                      type="text"
                      id="input"
                      onChange={(e) => setsubComment(e.target.value)}
                      value={subComment}
                      className="h-6 w-[80%] focus:none ml-12 input-hidden"
                      placeholder="schreibe einen Kommentar"
                    />
                    <img
                      className="absolute bottom-1 left-1 h-10 w-10 rounded-full "
                      src={currentPic}
                      id="input_img"
                      loading="lazy"
                    />
                  </div>
                  <div className="flex float-right my-2 mx-1">
                    <button
                      onClick={() =>
                        SubCommentId != ""
                          ? editSubComment(expereince._id, i)
                          : PostSubComment(expereince._id, i)
                      }
                      className="btn2-styl"
                    >
                      {SubCommentId != "" ? <>bearbeiten</> : t.post}
                    </button>
                  </div>
                </div>
              </div>
            </Collapse>
          </>
        ))}
      </div>
    </>
  );
}

export default HelpedComments;
