import React from "react";

function MainTitle({ state, setState }) {
  return (
    <div className="px-20 ">
      <h3 className="center-styl my-4 text-base font-semibold text-color-yellow">
        Gib Deiner Frage eine aussagekräftige Überschrift.
      </h3>

      <input
        id="username"
        placeholder="Bitte eine kurze und knappe Überschrift."
        name="username"
        type="username"
        className="border border-gray-300	 px-3 py-3 text-black bg-white rounded text-xs h-12 placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-color-btn w-full ease-linear transition-all duration-150 mb-4"
        // value={state.name}
        onChange={(e) =>
          setState((prevState) => ({
            ...prevState,
            name: e.target.value,
          }))
        }
      />
    </div>
  );
}

export default MainTitle;
