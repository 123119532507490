import React, { useState } from 'react';

export default function CheckBoxUi({ item, formik, data }) {
  const [checked, setChecked] = useState(item.status);

  return (
    <div className='flex mb-2'>
      <input
        type='checkbox'
        checked={item.status}
        id={item.id}
        key={item.id}
        name={item.id}
        onChange={(e) => {
          // console.log('new=>', data[item.id]);
          formik.values.services[item.id].status = e.target.checked;

          let newJson = formik.values.services.map((item) => {
            return item;
          });

          formik.setFieldValue('services', newJson);
        }}
        className='outline-lime-700 checkbox-styl border-8 border-color-yellow accent-color-yellow w-6 h-6'
      />
      <div className='ml-4 text-xs'>{item.title}</div>
    </div>
  );
}
