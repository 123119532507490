import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import useTranslation from 'common/customHooks/translations';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import SwiperView from 'components/SwiperView';
import { useLocation, useParams } from 'react-router-dom';
import { useCheckEmail_UserNameAccount } from 'hooks';
import moment from 'moment';
import ErrorService from 'services/formatError/ErrorService';
function AddInfo() {
  const ref = React.useRef();

  let navigate = useNavigate();
  const t = useTranslation();
  const { mutateAsync: checkEmailAccount, isLoading } =
    useCheckEmail_UserNameAccount();
  const { state } = useLocation();
  // console.log(state);

  const registerUser = useMutation(
    (registerUser1) =>
      userServices.commonPostService('userAuth/registerByEmail', registerUser1),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        if (res.status) {
          toast.success('Dein Account wurde erstellt. Bitte melde dich an');
          navigate('/auth/signin');
        }
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      fname: '',

      postcode: '',
    },
    validationSchema: Yup.object().shape({
      // postcode: Yup.string().min(2, t.mincharacters2).required(t.required),
      // lname: Yup.string().min(2, t.mincharacters2).required(t.required),
      fname: Yup.string().min(4, t.mincharacters4).required(t.required),
    }),
    onSubmit: async (values) => {
      //// const response = await checkEmailAccount({ username: values.username });

      values.pass = state.pass;
      values.email = state.email;

      registerUser.mutate(values);

      ///// navigate("/uploadprofile", { state: values });
      /////  if (response.status) {

      ///  }
    },
  });
  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h1 className='md:text-lg mt-4 text-color-primary sm:text-sm mb-4 font-semibold text-center'>
              {t.addinfo}
            </h1>
          </Grid>
        </Grid>

        <div className='mobileview'>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SwiperView swiperkey={1} />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='text-color-grey desktopview text-center text-xs '>
              {t.addinfo_details}
            </div>

            <form onSubmit={formik.handleSubmit} className='mt-4'>
              <div className='relative w-full mb-3 '>
                <input
                  id='fname'
                  name='fname'
                  type='text'
                  className='input-styl'
                  placeholder={t.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fname}
                />
                {formik.touched.fname && formik.errors.fname ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.fname}
                  </div>
                ) : null}
              </div>
              {/* 
              <div className='relative w-full mb-3 '>
                <input
                  id='lname'
                  name='lname'
                  type='text'
                  className='input-styl'
                  placeholder={t.lname}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lname}
                />
                {formik.touched.lname && formik.errors.lname ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.lname}
                  </div>
                ) : null}
              </div> */}

              {/* <div className='relative w-full mb-3 '>
                <input
                  id='username'
                  name='username'
                  type='text'
                  className='input-styl'
                  placeholder={t.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.username}
                  </div>
                ) : null}
              </div> */}

              <div className='relative w-full mb-3 '>
                <input
                  id='postcode'
                  name='postcode'
                  type='text'
                  className='input-styl'
                  placeholder='Postleitzahl'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.postcode}
                />
                {formik.touched.postcode && formik.errors.postcode ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.postcode}
                  </div>
                ) : null}
              </div>

              {/* <div className='relative w-full mb-3 '>
                <input
                  id='bday'
                  name='bday'
                  type='text'
                  ref={ref}
                  className='input-styl'
                  format='DD-MM-YYYY'
                  data-date-format='DD MMMM YYYY'
                  max={moment().format('YYYY-MM-DD')}
                  placeholder={t.dob}
                  onFocus={(e) => (e.currentTarget.type = 'date')}
                  onBlur={(e) => (e.currentTarget.type = 'text')}
                  onChange={formik.handleChange}
                />
                {formik.touched.bday && formik.errors.bday ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.bday}
                  </div>
                ) : null}
              </div>

              <div className='relative w-full mb-3 '>
                <select
                  id='gender'
                  name='gender'
                  className='input-styl'
                  placeholder={t.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gender}
                >
                  <option value='' disabled selected>
                    {t.gender}
                  </option>
                  <option value='male'>{t.male}</option>
                  <option value='female'>{t.female}</option>
                </select>

                {formik.touched.gender && formik.errors.gender ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.gender}
                  </div>
                ) : null}
              </div> */}

              {registerUser.isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {' '}
                  <button className='btn-styl' type='submit'>
                    Geschafft
                  </button>
                </>
              )}

              {/* <div className='text-center my-6'>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <button className='btn-styl' type='submit'>
                      {t.almostdone}
                    </button>
                  </>
                )}
              </div> */}
            </form>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='desktopview'>
              <SwiperView swiperkey={1} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AddInfo;
