import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
// components
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import { useNavigate } from 'react-router-dom';
import IndexDropdown from 'components/Dropdowns/IndexDropdown.js';
import AutoSearch from 'components/AutoSearch';
import { localStorageData } from 'services/auth/localStorageData';
import useTranslation from 'common/customHooks/translations';

export default function Navbar() {
  const t = useTranslation();

  const [disease, setDisease] = React.useState([]);
  const [selectDisease, setSelectDisease] = React.useState('');
  let navigate = useNavigate();
  const [input, setInput] = useState(selectDisease);

  function selectvalue(val) {
    let selecteddisease = disease.filter((item) => item.name == val);
    console.log(selecteddisease[0]._id);

    let name = selecteddisease[0].name;

    navigate(
      `/account/thema/${name.replace(/\s/g, '')}/${selecteddisease[0]._id}`
    );
  }

  const getAllDisease = useQuery(
    'diseaseData',
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setDisease(res.data.data);
      },
    }
  );

  return (
    <>
      {/* Navbar */}
      <nav className='lg:fixed md:fixed sm:sticky xs:sticky  top-0 left-0 w-full lg:z-10 md:z-10 sm:z-0 xs:z-0 bg-white shadow md:flex-row md:flex-nowrap md:justify-start flex items-center p-4 '>
        <div className='w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4'>
          {/* Brand */}
          <div className='flex flex-row lg:ml-64 md:ml-64 sm:ml-1 xs:ml-1'>
            <i className='fas fa-search text-color-yellow mt-2'></i>

            <div>
              {disease != '' ? (
                <AutoSearch
                  input='no'
                  defaultvalue={selectDisease}
                  setInput={setInput}
                  selectvalue={selectvalue}
                  placeholder='Suche ...'
                  suggestions={disease}
                  alreadyExist={false}
                  noFound='Dein Suchbegriff ist nicht vorhanden. Bitte versuche etwas anderes.'
                  className=' lg:w-96 sm:w-40 xs:w-40 placeholder-color-btn mx-4 border-search focus:bg-color-lightgrey p-1'
                />
              ) : (
                'loading'
              )}
            </div>
          </div>
          {/* Form */}
          <form className='md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3'>
            <div className='relative flex w-full flex-wrap items-stretch'>
              {/* <span className='z-10 h-full leading-snug font-normal absolute text-center text-blueGray-300  bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3'>
                <i className='fas fa-search'></i>
              </span>
              <input
                type='text'
                placeholder='Search here...'
                className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative  bg-white rounded text-sm shadow outline-none focus:outline-none focus:ring w-full pl-10'
              /> */}
            </div>
          </form>
          {/* User */}
          <ul className='flex-col md:flex-row list-none items-center hidden md:flex'>
            <li className='flex items-center'>
              {localStorageData('_id') ? (
                <IndexDropdown />
              ) : (
                <button
                  className='text-color-btn font-bold'
                  onClick={() => navigate('/auth/signup')}
                >
                  {t.signbtn}
                </button>
              )}
            </li>

            <li className='flex items-center'>
              <a className=' px-3 ' href='#'>
                <i className='fas fa-ellipsis-v text-color-yellow' />
              </a>
            </li>
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}
