export const IS_LOGGED_IN = "IS_LOGGED_IN";
export const IS_LOGGED_OUT = "IS_LOGGED_OUT";
export const TOKEN = "TOKEN";
export const API_SIGN_UP = "API_SIGN_UP";
export const FACEBOOK_API_SIGN_UP = "FACEBOOK_API_SIGN_UP";

export const USER_PROFILE = "USER_PROFILE";

export const TOOGLE_PROFILE = "TOOGLE_PROFILE";


export const EMPTY_STORE = "EMPTY_STORE";




export const VENDOR_APPLICATION_STATUS = "VENDOR_APPLICATION_STATUS";
export const BUSINESS_INFO = "BUSINESS_INFO";
export const RESTAURANTS_LIST = "RESTAURANTS_LIST";
export const BRAND = "BRAND";

export const SET_FAKE_USER = 'SET_FAKE_USER';
