import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';

function MyFooter() {
  let navigate = useNavigate();

  return (
    <>
      <div className=' bg-footer pt-10 w-full '>
        <Container maxWidth='md'>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} className=''>
              <div className=' w-48 h-16 mt-6   '>
                <a onClick={() => navigate('/')}>
                  <img
                    src={require('assets/img/xpera bg logo.png')}
                    className=' '
                    alt=''
                  />
                </a>
              </div>
              <div className='footer-text mt-6  font-thin'>
                <p className='text-white  '>
                  Mit unserem Herzensprojekt Xpera wollen wir Betroffenen echte
                  Hilfe und Perspektiven bieten und gleichermaßen Heilberufler,
                  Therapeuten und Gesundheitscoaches unterstützen.
                </p>
                <p className='mt-10 text-white'>
                  Wir bringen zusammen was zusammen gehört - die Betroffenen mit
                  den passenden Experten.
                </p>
              </div>
              <div className='flex mt-6 '>
                <a href='#'>
                  <i class='fab fa-facebook text-3xl bg-footer-bg p-2 text-white text-start'></i>
                </a>

                <a href='#'>
                  <i class='fab fa-linkedin text-3xl bg-footer-bg p-2 text-white'></i>
                </a>
                <a href='#'>
                  <i class='fab fa-twitter-square  text-3xl bg-footer-bg p-2 text-white'></i>
                </a>
                <a href='#'>
                  <i class='fab fa-whatsapp text-3xl bg-footer-bg p-2 text-white'></i>
                </a>
                <a href='#'>
                  <i class='fab fa-pinterest text-3xl bg-footer-bg p-2 text-white'></i>
                </a>
              </div>
            </Grid>
            <Grid item lg={2} xs={12}>
              <div className='mt-8 '>
                <h2 className=' text-white footer-content   '>RECHTLICHES</h2>
              </div>
              <a
                target='_blank'
                onClick={() => navigate('/agb')}
                className='text-white text-md block mt-16 font-thin'
              >
                AGB
              </a>
              <a
                target='_blank'
                onClick={() => navigate('/impressum')}
                className='text-white text-md  block font-thin'
              >
                Impressum
              </a>
              <a
                target='_blank'
                onClick={() => navigate('/privacypolicy')}
                className='text-white block text-md font-thin'
              >
                Datenschutz
              </a>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className=''>
                <div className=' mt-8 '>
                  <h2 className=' text-white  font-thin flex justify-start  footer-content'>
                    FRAGEN UND WÜNSCHE?
                  </h2>
                  <p className='text-white text-md footer-data flex justify-start mt-16 font-thin'>
                    Dann rufen Sie uns gerne an
                  </p>
                  <p className='text-white text-xl footer-data flex justify-start  font-thin'>
                    oder schreiben Sie eine E-Mail
                  </p>
                </div>
                <div className='mt-9  footer-number flex justify-start   font-thin'>
                  <i class='fas fa-phone text-2xl text-white  '></i>
                  <a
                    className='text-white text-lg ml-5'
                    href='tel:+49015141444337'
                  >
                    +49 (0) 151 41 444 337
                  </a>
                </div>
                <div className='mt-3 flex justify-start  footer-number   font-thin'>
                  <i class='fas fa-envelope text-2xl text-white '></i>
                  <a
                    href='mailto:kontakt@halfmir.de'
                    target='_blank'
                    className='text-white text-lg ml-5'
                  >
                    kontakt@halfmir.de
                  </a>
                </div>
                <div className='mt-3 footer-number flex justify-start  font-thin'>
                  <i class='fab fa-whatsapp text-2xl text-white'></i>
                  <a
                    target='_blank'
                    href='https://wa.me/49015141444337'
                    className='text-white text-lg ml-5'
                  >
                    WhatsApp Chat
                  </a>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
        <hr className='mt-12' />
        <Container maxWidth='md'>
          <p className='text-white text-1xl  font-thin mt-6 pb-6 '>
            © 2023 Jens Jung und Bettina Henk GbR
          </p>
        </Container>
      </div>
    </>
  );
}

export default MyFooter;
