// <Route element={<RequireAuth />}></Route>

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import AddTreatment from 'admin/AddTreatment';
import SidebarAdmin from 'admin/SidebarAdmin';
import RequireAuth from './RequireAuth';
import AddDisease from 'admin/AddDisease';
import AddAd from 'admin/AdService/AddAd';

import Extendedprofile from 'admin/Extendedprofile';
function Admin() {
  return (
    <>
      <Grid container>
        <Grid item lg={2} md={2} sm={12} xs={12}>
          <SidebarAdmin />
        </Grid>

        <Grid item lg={10} md={10} xs={10}>
          <main>
            <section className='relative w-full py-10 h-full  min-h-screen '>
              <Routes>
                <Route>
                  <Route element={<RequireAuth />}>
                    <Route path='addtreatment' element={<AddTreatment />} />
                    <Route path='adddisease' element={<AddDisease />} />
                    <Route path='addad' element={<AddAd />} />

                    <Route
                      path='extendedprofile'
                      element={<Extendedprofile />}
                    />
                  </Route>
                </Route>
              </Routes>
            </section>
          </main>
        </Grid>
      </Grid>
    </>
  );
}

export default Admin;
