import React from 'react';
import { ImageEndPoint } from 'config/config';
import UnLockProfile from './UnLockProfile';
import Grid from '@mui/material/Grid';
import LockProfile from './LockProfile';
import { localStorageData } from 'services/auth/localStorageData';
import ProfileLoading from './ProfileLoading';

export default function MainProfile({ profileData }) {
  // console.log('profileDataprofileDataprofileDataprofileData', profileData);

  if (profileData.length == 0) {
    return (
      <div>
        <ProfileLoading />
      </div>
    );
  } else {
    return (
      <div>
        <div>
          <img
            src={
              profileData.coverPhoto != 'sample.png'
                ? ImageEndPoint + profileData.coverPhoto
                : 'https://i.ibb.co/2jxznfx/TitelPic.jpg'
            }
            alt='cover img'
            id='cover'
            loading='lazy'
            className='object-cover w-full h-32 rounded-lg'
          />
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
              <div className='mx-4 -mt-4 center-div-mob'>
                <img
                  src={ImageEndPoint + profileData.user.pic}
                  alt='img'
                  id='cover'
                  loading='lazy'
                  className='object-cover w-28 h-24 rounded-lg shadow'
                />
              </div>
            </Grid>

            <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
              <div className='  mt-2 '>
                <h1 className='text-color-btn text-xs bold-styl center-div-mob'>
                  {/* {localStorageData('fname')} */}

                  {profileData.user.fname}
                </h1>
                <h3 className='text-xs text-color-grey bold-styl mt-1 lg:text-left xs:text-center sm:text-center'>
                  {profileData.title}
                </h3>
              </div>
            </Grid>
          </Grid>
          <div className='my-8 grid grid-cols-1 lg:grid-cols-4 md:grid-cols-4 gap-4'>
            <a
              className='bg-white shadow p-2  rounded-full flex center-styl'
              href={`tel:${profileData.phone}`}
            >
              <i className='fas fa-phone-alt text-color-yellow' />
              <span className='text-xs mx-2 truncate '>
                {profileData.phone}
              </span>
            </a>
            <a
              className='bg-white shadow p-2 rounded-full flex center-styl'
              target='_blank'
              href={profileData.website}
            >
              <i className='far fa-envelope text-color-yellow' />
              <span className='text-xs mx-2 truncate '>
                {profileData.website}
              </span>
            </a>
            <div className='bg-white shadow p-2 rounded-full flex center-styl  col-span-2 '>
              <i className='fas fa-map-marker-alt text-color-yellow' />

              <span className='text-xs mx-2 truncate'>
                {profileData.address}
              </span>
            </div>
          </div>
        </div>
        {profileData.isApprovedByAdmin ? (
          <UnLockProfile data={profileData} />
        ) : (
          <>
            {localStorageData('_id') == profileData.user._id ? (
              <LockProfile />
            ) : null}
          </>
        )}
      </div>
    );
  }
}
