import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';

export default function ProfileLoading() {
  return (
    <div>
      <Skeleton variant='rectangular' width='100%' height={100} />

      <Grid container spacing={2}>
        <Grid item xl={2} lg={2} md={3} sm={12} xs={12}>
          <div className='-mt-4 mx-4'>
            <Skeleton variant='rectangular' width={80} height={80} />
          </div>
        </Grid>
        <Grid item xl={10} lg={10} md={9} sm={12} xs={12}>
          <Skeleton variant='text' sx={{ fontSize: '1rem', marginTop: 2 }} />
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
        </Grid>
      </Grid>

      <div className='mt-20 flex flex-wrap'>
        <div className='mx-4'>
          <Skeleton variant='rectangular' width={200} height={200} />
          <Skeleton width='23%' />
        </div>

        <div className='mx-4'>
          <Skeleton variant='rectangular' width={200} height={200} />
          <Skeleton width='23%' />
        </div>

        <div className='mx-4'>
          <Skeleton variant='rectangular' width={200} height={200} />
          <Skeleton width='23%' />
        </div>
      </div>
    </div>
  );
}
