module.exports = {
  endPoint: "https://xpera.de/api",
  ///endPoint: 'http://localhost:5005/api',

  ImageEndPoint: 'https://xpera.de/readfiles/',

  ////ImageEndPoint: "https://xpera.de/readfiles/",

  localToken: 'xpereatoken',

  strangerName: 'Inkognitomodus',

  strangerPic: 'https://i.ibb.co/gmbdVjX/incognito-avatar.png'
}
