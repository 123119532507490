/*eslint-disable*/
import React from 'react';
import { Link } from 'react-router-dom';
// components

import IndexDropdown from 'components/Dropdowns/IndexDropdown.js';

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className='top-0  z-50 w-full flex flex-wrap items-center justify-between px-10 py-10 navbar-expand-lg bg-white '>
        <div className='container mx-auto flex flex-wrap items-center justify-between'>
          <div className='w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start'>
            <Link to='/'>
              <img
                src={require('assets/img/logo.png')}
                alt='logo'
                className='desktopview w-full xs:h-6 sm:h-6 md:h-8 lg:h-8'
              />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
}
