import React, { useState } from "react";
import Container from "@mui/material/Container";

import { useFormik } from "formik";
import ReactTagInput from "@pathofdev/react-tag-input";
import * as Yup from "yup";
// import ViewUser from 'Admin/ViewUser';

import { useMutation, useQuery } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import ViewDisease from "admin/ViewDisease";

import ViewExtendedProfile from "admin/ViewExtendedProfile";

function Extendedprofile() {
  const [isEdit, setisEdit] = useState(false);
  const [allProfile, setallProfile] = useState([]);

  const getAllDisease = useQuery(
    "getallprofileName",
    () => userServices.commonGetService(`/social/extendedPofileByAdmin`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setallProfile(res.data);
      },
    }
  );

  // eslint-disable-next-line

  const approveuser = useMutation(
    (deleteService) =>
      userServices.commonPostService(
        "/social/approveExtendedProfile",
        deleteService
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("Disease has been deleted");
        getAllDisease.refetch();
      },
    }
  );

  return (
    <div>
      <Container maxWidth="sm">
        <div className="flex-auto px-2 lg:px-10 py-10 pt-0">
          <div className="text-blueGray-400 text-center text-xl mb-3 font-bold">
            {isEdit === false ? <span>Add </span> : <span>Update</span>}
            Profile
          </div>
        </div>
        {/* eslint-disable-next-line */}
      </Container>
      <div className="mx-10">
        {getAllDisease.isLoading == false && allProfile != "" ? (
          <ViewExtendedProfile
            data={allProfile.data}
            approveuser={approveuser}
            setisEdit={setisEdit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default Extendedprofile;
