import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import "./share.css";
import { v4 as uuidv4 } from "uuid";
import { endPoint } from "../../config/config";

const index = (props) => {
  function sharePost(url) {
    navigator.clipboard.writeText(url);
    toast.success(
      "Die Beitrags-URL wurde in deine Zwischenablage kopiert. Du kannst sie nun überall teilen."
    );
  }

  const rand = uuidv4();

  let url;

  if (props.postId)
    url = `${endPoint}/share/sharePost?post=${props.postId}&rand=${rand}`;
  if (props.disease)
    url = `${endPoint}/share/shareDisease?disease=${props.disease}&rand=${rand}`;
  return (
    <div className="dropdown">
      <div className="margin-mobile">
        <img
          src={require("assets/img/share.png")}
          alt="share"
          className="h-5 w-6 object-cover mx-auto"
        />
      </div>
      <div className="dropdown-content">
        <i
          className="fas fa-light fa-copy text-color-btn dropdown-item"
          onClick={() => {
            if (props.postId)
              sharePost(`https://xpera.de/facebookshare/${props.postId}`);
            else sharePost(`https://xpera.de/ilnessShare/${props.disease}`);
          }}
        ></i>
        <FacebookShareButton
          url={
            props.postId
              ? `https://gesund-durch-erfahrung.de/facebookshare/${props.postId}`
              : `https://gesund-durch-erfahrung.de/ilnessShare/${props.disease}`
          }
          title={props.title}
          hashtag="#Xpera"
        >
          <i className="fab fa-brands fa-facebook dropdown-item facebook"></i>
        </FacebookShareButton>
        <TwitterShareButton url={url} title={props.title} hashtag="#Xpera">
          <i className="fab fa-brands fa-twitter  dropdown-item twitter"></i>
        </TwitterShareButton>
        {/* <WhatsappShareButton
          url={"https://react-share-try.herokuapp.com/deeplink:50025"}
          title={"CampersTribe - World is yours to explore"}
          className={""}
        >
          <i className="fab fa-brands fa-whatsapp dropdown-item whatsapp"></i>
        </WhatsappShareButton> */}
      </div>
    </div>
  );
};

export default index;
