import React, { useState, useEffect, useRef } from "react";
import { ImageEndPoint } from "config/config";

function AutoSearch({
  suggestions,
  placeholder,
  selectvalue,
  input,
  setInput,
  className,
  alreadyExist,
  setOthers,
  countValue,

  setState,
  state,
  noFound,
}) {
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  // const [input, setInput] = useState('');
  const wrapperRef = useRef(null);
  const divRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (input != "" && input != "no") {
      const userInput = input;

      const unLinked = suggestions.filter(
        (suggestion) =>
          suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );

      setInput(input);
      setFilteredSuggestions(unLinked);
      setActiveSuggestionIndex(0);
      setShowSuggestions(true);
    }

    if (input == undefined) {
      setInput("");
    }
  }, []);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowSuggestions(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const onChange = (e) => {
    const userInput = e.target.value;

    // Filter our suggestions that don't contain the user's input
    const unLinked = suggestions.filter(
      (suggestion) =>
        suggestion.name.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    setInput(e.target.value);
    setFilteredSuggestions(unLinked);

    // console.log('-----------', unLinked);
    // console.log('------userInput-----', userInput);

    setActiveSuggestionIndex(0);
    setShowSuggestions(true);

    if (e.target.value == "") {
      setShowSuggestions(false);
    }
  };

  const othersChanges = (e) => {
    if (noFound == "no") {
      if (filteredSuggestions == "") {

        setState((prevState) => ({
          ...prevState,
          dataBaseEnter: { name: e, treatment: countValue },
        }));
      }
    } else {
    }
  };

  const onClick = (e) => {
    setInput(e.target.innerText);
    selectvalue(e.target.innerText, e.target.id);
    setFilteredSuggestions([]);
    othersChanges(e.target.innerText);
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
  };

  const onKeyDown = (e) => {
    // User pressed the enter key
    if (e.keyCode === 13) {
      othersChanges(input);
      setInput(filteredSuggestions[activeSuggestionIndex].name);
      selectvalue(
        filteredSuggestions[activeSuggestionIndex].name,
        filteredSuggestions[activeSuggestionIndex]._id
      );

      setActiveSuggestionIndex(0);
      setShowSuggestions(false);
    }
    // User pressed the up arrow
    else if (e.keyCode === 38) {
      if (activeSuggestionIndex === 0) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex - 1);
    }
    // User pressed the down arrow
    else if (e.keyCode === 40) {
      if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
        return;
      }

      setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };

  const SuggestionsListComponent = () => {
    return filteredSuggestions.length ? (
      <ul
        className="suggestions bg-white rounded border border-color-grey mt-2 h-52 absolute z-10"
        // onBlur={() => setShowSuggestions(false)}
      >
        {filteredSuggestions.map((suggestion, index) => {
          let className = "";

          // Flag the active suggestion with a class
          if (index === activeSuggestionIndex) {
            className = "suggestion-active ";
          }

          return (
            <li
              className={className + "p-3 flex"}
              key={suggestion._id}
              onClick={onClick}
              id={suggestion._id}
            >
              <img
                src={ImageEndPoint + suggestion.image}
                className="w-10 h-10 rounded-full object-cover"
                alt=""
                id={suggestion._id}
              />
              <span id={suggestion._id} className="m-2">
                {suggestion.name}
              </span>
              {/* {alreadyExist ? (
                <>
                  {suggestion.expereince != '' ? (
                    <div className=' absolute right-4 my-2 float-right text-[12px] text-color-white'>
                      Erfahrung vorhanden
                    </div>
                  ) : null}
                </>
              ) : null} */}
            </li>
          );
        })}
      </ul>
    ) : (
      <ul
        className="suggestions bg-white rounded border border-color-grey mt-2 h-52 absolute z-10"
        // onBlur={() => setShowSuggestions(false)}
      >
        <li
          className={"suggestion-active p-3 flex"}
          key="etwas anderes"
          onClick={(e) => {
            if (noFound == "no") {
              onClick(e);
            }
          }}
          id="etwasanderes"
        >
          <span id="etwasanderes" className="m-2">
            {noFound != "no" ? noFound : input}
            {/*  {input} */}
          </span>
        </li>
      </ul>
    );
  };
  return (
    <div className="" ref={wrapperRef}>
      {input != "no" ? (
        <input
          type="text"
          autoFocus="autoFocus"
          onChange={onChange}
          onKeyDown={onKeyDown}
          key={input}
          defaultValue={input}
          value={input}
          placeholder={placeholder}
          className={className}
        />
      ) : (
        <input
          type="text"
          onChange={onChange}
          onKeyDown={onKeyDown}
          // value={input}
          placeholder={placeholder}
          className={className}
        />
      )}

      {showSuggestions && input && <SuggestionsListComponent />}
    </div>
  );
}

export default AutoSearch;
