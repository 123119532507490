import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useTranslation from 'common/customHooks/translations';
import Container from '@mui/material/Container';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { localStorageData } from 'services/auth/localStorageData';

import userService from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';

function UpdatePassword() {
  let navigate = useNavigate();
  const t = useTranslation();

  const formik = useFormik({
    initialValues: {
      oldPass: '',
      newPass: '',
      userId: localStorageData('_id'),
      // cpass: '',
    },
    validationSchema: Yup.object().shape({
      oldPass: Yup.string().min(8, t.mincharacters8).required(t.required),
      newPass: Yup.string().min(8, t.mincharacters8).required(t.required),
      // cpass: Yup.string().oneOf(
      //   [Yup.ref('pass'), null],
      //   'Passwords must match'
      // ),
    }),
    onSubmit: async (values) => {
      // values.email = decodeURIComponent(params.email);
      // values.uniqueId = params.id;
      // toast.info('comming soon');
      // console.log(values);
      mutate(values);
    },
  });

  const { mutate } = useMutation(
    (updatePass) =>
      userService.commonPostService(
        '/userAuth/updatepasswordFromProfile',
        updatePass
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success(t.update);
        // navigate('/');
      },
    }
  );

  return (
    <div>
      <h1 className='md:text mt-4 text-black sm:text-sm font-semibold text-center'>
        Update Password
      </h1>
      <Container maxWidth='sm'>
        <form onSubmit={formik.handleSubmit} className='mt-4'>
          <div className='relative w-full mb-3'>
            <input
              name='oldPass'
              id='oldPass'
              type='password'
              className='input-styl'
              placeholder='Old Passwort'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.oldPass}
            />
            {formik.touched.oldPass && formik.errors.oldPass ? (
              <div className='text-red-500 mt-2 text-xs'>
                {formik.errors.oldPass}
              </div>
            ) : null}
          </div>

          <div className='relative w-full mb-3'>
            <input
              name='newPass'
              id='newPass'
              type='password'
              className='input-styl'
              placeholder={t.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.newPass}
            />
            {formik.touched.newPass && formik.errors.newPass ? (
              <div className='text-red-500 mt-2 text-xs'>
                {formik.errors.newPass}
              </div>
            ) : null}
          </div>

          {/* <div className='relative w-full mb-3'>
            <input
              name='cpass'
              id='cpass'
              type='password'
              className='input-styl'
              placeholder='Confirm passwort'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.cpass}
            />
            {formik.touched.cpass && formik.errors.cpass ? (
              <div className='text-red-500 mt-2 text-xs'>
                {formik.errors.cpass}
              </div>
            ) : null}
          </div> */}

          <div className='text-center my-6'>
            <button className='btn-styl' type='submit'>
              Update
            </button>
          </div>
        </form>
      </Container>
    </div>
  );
}

export default UpdatePassword;
