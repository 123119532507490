import React from 'react';
import Rating from '@mui/material/Rating';

function StarCard() {
  const starReview = [
    {
      id: '1',
      rating: 5,
      title: 'symptomfrei | geheilt',
      subtitle:
        'Hat mich komplett von meinen Beschwerden befreit bzw. geheilt.',
    },
    {
      id: '2',
      rating: 4,
      title: 'erhebliche Besserung',
      subtitle:
        'Hat meinen Zustand erheblich verbessert bzw. mich fast von meinen Beschwerden befreit.*',
    },
    {
      id: '3',
      rating: 3,
      title: 'starke Linderung',
      subtitle: 'Hat meine Beschwerden stark gelindert.*',
    },
    {
      id: '4',
      rating: 2,
      title: 'gute Linderung',
      subtitle: 'Hat meine Beschwerden gut gelindert.*',
    },
    {
      id: '5',
      rating: 1,
      title: 'leichte Linderung',
      subtitle: 'Hat meine Beschwerden leicht gelindert.*',
    },
  ];

  return (
    <div className='bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border border-frame-gray'>
      <div className='flex flex-row border-b p-4 border-color-grey '>
        <div className='text-xs text-black font-semibold'>
          Das bedeuten die Sterne
        </div>
      </div>
      <div className='p-6'>
        <p className='text-center text-xs2 '>
          Jeder Stern heißt grundsätzlich:
        </p>
        <div className='center-styl'>
          <img
            id='output'
            src={require('assets/img/star.png')}
            className='w-20 h-20 object-contain'
            alt='Incognito'
          />
        </div>
        <h3 className='text-center text-xs font-semibold '>
          Das hat mir geholfen!
        </h3>

        <p className='text-center text-xs2 '>
          Wie sehr ein Mittel, eine Therapie, Aktivität oder ein Experte helfen
          konnte zeigt die Anzahl der Stern
        </p>

        {starReview.map((item) => (
          <div className='grid justify-items-center my-2 '>
            <div>
              <Rating name='read-only' value={item.rating} readOnly />
            </div>
            <h4 className='text-center text-xs font-bold my-1'>{item.title}</h4>
            <h5 className='text-center text-xs2 '>{item.subtitle}</h5>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StarCard;
