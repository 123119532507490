import React, { useState } from 'react';
import Container from '@mui/material/Container';

import { useFormik } from 'formik';
import ReactTagInput from '@pathofdev/react-tag-input';
import * as Yup from 'yup';
// import ViewUser from 'Admin/ViewUser';

import { useMutation, useQuery } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import ViewAd from './ViewAd';

function AddAd() {
  const [isEdit, setisEdit] = useState(false);
  const [listAds, setListAds] = useState([]);

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('adimg');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);


      formik.setFieldValue('pics', file);

      if (isEdit == true) {
        const formData = new FormData();
        formData.append('pics', file);
        formData.append('_id', formik.values._id);
      }

      // formData.append('pics', e.target.files[0]);
    }
  };

  const getAllAds = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/admin/fetchallAdd`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setListAds(res.data);
        // console.log('data', res.data);
      },
    }
  );

  let user = {
    _id: '',
    title: '',
    text: '',
    desc: '',
    pics: '',
    link: '',
    surname: '',
    street: '',
    postalcode: '',
    location: '',
    email: '',
    website: '',
    points: '',
    rank: '',
    priority: '',
  };

  const [userSechema, setuserSechema] = useState(user);
  const formik = useFormik({
    initialValues: userSechema,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().required('Required'),
      text: Yup.string().required('Required'),
      priority: Yup.string().required('Required'),

      pics: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      console.log(values);
      const formData = new FormData();
      formData.append('pics', values.pics);
      formData.append('title', values.title);
      formData.append('text', values.text);
      formData.append('desc', values.desc);
      formData.append('link', values.link);
      formData.append('surname', values.surname);
      formData.append('street', values.street);
      formData.append('postalcode', values.postalcode);
      formData.append('location', values.location);
      formData.append('email', values.email);
      formData.append('website', values.website);
      formData.append('points', values.points);
      formData.append('rank', values.rank);
      formData.append('priority', values.priority);
      if (isEdit === false) {
        addNewAds.mutate(formData);
      } else {
        formData.append('_id', values._id);
        updateService.mutate(formData);
      }
    },
  });

  const addNewAds = useMutation(
    (NewAds) => userServices.commonPostService('/admin/addNewAd', NewAds),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllAds.refetch();

        setuserSechema(user);
        formik.resetForm({ NewAds: '' });

        toast.success('Ads has been created');
      },
    }
  );

  // eslint-disable-next-line

  const updateService = useMutation(
    (updateNow) =>
      userServices.commonPostService('/admin/updateOneAd', updateNow),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllAds.refetch();
        setuserSechema(user);
        setisEdit(false);
        formik.resetForm({ updateNow: '' });

        toast.success('updated');
      },
    }
  );

  const deleteService = useMutation(
    (deleteService) =>
      userServices.commonPostService('/admin/deleteOneAd', deleteService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success('Ads has been deleted');
        getAllAds.refetch();
      },
    }
  );
  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex-auto px-2 lg:px-10 py-10 pt-0'>
          <div className='text-blueGray-400 text-center text-xl mb-3 font-bold'>
            {isEdit === false ? <span>Add Ad</span> : <span>Update Ad</span>}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='relative w-full mb-3'>
              <div className='grid content-center items-center justify-center'>
                <img
                  id='adimg'
                  src='https://ui-avatars.com/api/?name=John+Doe'
                  className='w-32 h-32 rounded object-cover'
                  alt=''
                />
                <div className='mt-2 center-styl'>
                  <label htmlFor='contained-button-file' className='mt-6'>
                    <input
                      accept='image/*'
                      id='contained-button-file'
                      type='file'
                      onChange={onChangeHandler}
                      className='img-upload'
                    />
                    <Button variant='contained' component='span'>
                      Upload
                    </Button>
                  </label>
                </div>
                {formik.touched.pics && formik.errors.pics ? (
                  <div className='text-red-500 text-xs mt-2'>
                    {formik.errors.pics}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Title
              </label>
              <input
                id='title'
                name='title'
                type='text'
                className='input-styl'
                placeholder='Title'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.title}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Text
              </label>
              <input
                id='text'
                name='text'
                type='text'
                className='input-styl'
                placeholder='Text'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
              />
              {formik.touched.text && formik.errors.text ? (
                <div className='text-red-500 text-xs'>{formik.errors.text}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Description
              </label>
              <textarea
                type='text'
                rows='4'
                cols='50'
                name='desc'
                id='desc'
                value={formik.values.desc}
                onChange={formik.handleChange}
                className='input-styl desc-styl'
                placeholder='Description'
              ></textarea>
              {formik.touched.desc && formik.errors.desc ? (
                <div className='text-red-500 text-xs'>{formik.errors.desc}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Link xpera site
              </label>
              <input
                id='link'
                name='link'
                type='text'
                className='input-styl'
                placeholder='Link xpera site'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.link}
              />
              {formik.touched.link && formik.errors.link ? (
                <div className='text-red-500 text-xs'>{formik.errors.link}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-surname'
              >
                Surname
              </label>
              <input
                id='surname'
                name='surname'
                type='text'
                className='input-styl'
                placeholder='Surname'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
              />
              {formik.touched.surname && formik.errors.surname ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.surname}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Street
              </label>
              <input
                id='street'
                name='street'
                type='text'
                className='input-styl'
                placeholder='Street'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.street}
              />
              {formik.touched.street && formik.errors.street ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.street}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Postal Code
              </label>
              <input
                id='postalcode'
                name='postalcode'
                type='text'
                className='input-styl'
                placeholder=' Postal Code'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postalcode}
              />
              {formik.touched.postalcode && formik.errors.postalcode ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.postalcode}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-location'
              >
                Location
              </label>
              <input
                id='location'
                name='location'
                type='text'
                className='input-styl'
                placeholder='Location'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
              />
              {formik.touched.location && formik.errors.location ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.location}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Email
              </label>
              <input
                id='email'
                name='email'
                type='email'
                className='input-styl'
                placeholder='Email'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.email}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Website
              </label>
              <input
                id='website'
                name='website'
                type='text'
                className='input-styl'
                placeholder='Website'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website}
              />
              {formik.touched.website && formik.errors.website ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.website}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Points
              </label>
              <input
                id='points'
                name='points'
                type='text'
                className='input-styl'
                placeholder='Points'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.points}
              />
              {formik.touched.points && formik.errors.points ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.points}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Rank
              </label>
              <input
                id='rank'
                name='rank'
                type='text'
                className='input-styl'
                placeholder='Rank'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.rank}
              />
              {formik.touched.rank && formik.errors.rank ? (
                <div className='text-red-500 text-xs'>{formik.errors.rank}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Priority number
              </label>
              <input
                id='priority'
                name='priority'
                type='number'
                className='input-styl'
                placeholder='Priority number'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.priority}
              />
              {formik.touched.priority && formik.errors.priority ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.priority}
                </div>
              ) : null}
            </div>

            <div className='text-center mt-6'>
              <button className='btn-styl' type='submit'>
                {isEdit === false ? <>Add Ad</> : <>Update Ad</>}{' '}
              </button>
            </div>
          </form>
        </div>
        {/* eslint-disable-next-line */}
      </Container>
      <div className='mx-10'>
        {getAllAds.isLoading == false && listAds != '' ? (
          <ViewAd
            data={listAds.data}
            deleteService={deleteService}
            formik={formik}
            setisEdit={setisEdit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AddAd;
