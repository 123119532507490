import React from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useTranslation from 'common/customHooks/translations';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import GoogleApi from 'components/SocialLogin/GoogleApi';
import FacebookApi from 'components/SocialLogin/FacebookApi';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { ImageEndPoint } from 'config/config';
///import {LOGIN} from'./reactStore/actions/Actions'
////import { login } from "store/actions/userAction/userActions";
import { LOGIN } from 'reactStore/actions/Actions';
import { EMPTYSOTRE } from 'reactStore/actions/Actions';
import { useLoginEmailAccount } from 'hooks';

function Signin() {
  const dispatch = useDispatch();
  const { mutateAsync: loginEmailAccount, isLoading } = useLoginEmailAccount();

  let navigate = useNavigate();
  const t = useTranslation();

  const formik = useFormik({
    initialValues: {
      pass: '',
      username: '',
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().min(4, t.mincharacters4).required(t.required),
      pass: Yup.string().min(8, t.mincharacters8).required(t.required),
    }),
    onSubmit: async (values) => {
      // console.log('values---------');

      // console.log(values);

      const response = await loginEmailAccount(values);

      if (response.status) {
        sessionStorage.setItem('mykey', 'yes');

        let res = response.data;
        //// res.rememberMe=false

        res['pic'] = ImageEndPoint + res.pic;

        // console.log(res);

        dispatch(LOGIN(res));

        navigate('/account/home');
      }

      // toast('');
      ////toast.success('Signin');
    },
  });
  return (
    <div>
      <Container maxWidth='lg'>
        <div className='mobileview'>
          <Grid container spacing={5}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <img
                src={require('assets/img/login.png')}
                alt='login'
                className=''
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h1 className='md:text-lg mt-4 text-color-primary sm:text-sm font-semibold text-center'>
              {t.welcome}
            </h1>
            <div className='text-color-grey text-center text-xs'>
              {t.signdescription}
            </div>

            <form onSubmit={formik.handleSubmit} className='mt-4'>
              <div className='relative w-full mb-3 '>
                <input
                  id='username'
                  name='username'
                  type='username'
                  className='input-styl'
                  placeholder={t.emailusername}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.username && formik.errors.username ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.username}
                  </div>
                ) : null}
              </div>

              <div className='relative w-full mb-3'>
                <input
                  name='pass'
                  id='pass'
                  type='password'
                  className='input-styl'
                  placeholder={t.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pass}
                />
                {formik.touched.pass && formik.errors.pass ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.pass}
                  </div>
                ) : null}
              </div>

              <div className='flex justify-between my-10'>
                <div className='flex'>
                  {/* <input
                    type='checkbox'
                    className='outline-lime-700	 accent-color-yellow w-6 h-6'

                  /> */}
                  <div className='ml-4 text-xs'>{/* {t.remember} */}</div>
                </div>
                <a
                  onClick={() => navigate('/auth/forgotpassword')}
                  className='text-xs text-color-primary underline'
                >
                  {t.forgot}
                </a>
              </div>

              <div className='text-center my-6'>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <button className='btn-styl' type='submit'>
                      {t.signbtn}
                    </button>
                  </>
                )}
              </div>
            </form>

            <hr className='separator my-10' />

            <div className='text-center my-6'>
              <GoogleApi name={t.google} />

              <FacebookApi name={t.facebook} />
            </div>

            <div className='text-black text-xs font-semibold mt-4 '>
              Du bist zum ersten Mal hier?{' '}
              <button
                className='text-color-btn font-semibold'
                onClick={() => navigate('/auth/signup')}
              >
                Zur Registrierung!
              </button>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='desktopview'>
              <img
                src={require('assets/img/login.png')}
                alt='login'
                className='w-full'
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Signin;
