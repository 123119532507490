import React, { useEffect } from 'react';
import Home from 'pages/Home';
import { Route, Routes, Navigate } from 'react-router-dom';
import LandingPage from 'pages/Landingpage';

import IndexNavbar from 'components/Navbars/IndexNavbar';
import AdminLogin from 'admin/AdminLogin';
import OrderComplete from 'pages/OrderComplete';

import { useNavigate } from 'react-router-dom';
import { store } from 'reactStore/MainStore';
import IsLogin from './IsLogin';
import Policy from 'pages/StaticPages/Policy';
import TermsConditions from 'pages/StaticPages/TermsConditions';
import PrivacyPolicy from 'pages/StaticPages/PrivacyPolicy';
import Masthead from 'pages/StaticPages/Masthead';
import Pricing from 'pages/Pricing';
import LandingHeader from 'components/Headers/LandingHeader';
import MyFooter from 'components/Footers/MyFooter';
import LandingProfessional from '../pages/Landingpage/LandingProfessional';
import NotFound from 'pages/StaticPages/NotFound';

function Main() {
  let navigate = useNavigate();
  // &&      store.getState().User.profile != null
  // useEffect(async () => {
  //   if (window.location.pathname == '/') {
  //     navigate('/account/home');
  //   }
  // }, []);

  return (
    <>
      <LandingHeader />
      <main>
        <section className='relative w-full py-18 h-full  min-h-screen '>
          <Routes>
            <Route path='/' element={<LandingPage />} />
            <Route path='/professional' element={<LandingProfessional />} />
            <Route path='/pricing' element={<Pricing />} />
            <Route path='/orderstatus/:orderId' element={<OrderComplete />} />
            <Route
              path='/signin'
              element={<Navigate to='/auth/signin' replace />}
            />
            <Route path='/home' element={<Home />} />
            <Route path='adminlogin' element={<AdminLogin />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/agb' element={<TermsConditions />} />
            <Route path='/privacypolicy' element={<PrivacyPolicy />} />
            <Route path='/impressum' element={<Masthead />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </section>
      </main>
      <MyFooter />
    </>
  );
}

export default Main;
