import React from 'react';
// import { toast } from 'react-toastify';
import GoogleLogin from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateGoogleAccount } from 'hooks';
import { LOGIN } from 'reactStore/actions/Actions';
import { ImageEndPoint } from 'config/config';
function GoogleApi(props, { type = 'Signup', handleClose }) {
  const dispatch = useDispatch();
  /////// const dispatch = useDispatch();
  let navigate = useNavigate();
  const { mutateAsync: createGoogleAccount } = useCreateGoogleAccount();

  async function GoogleSuccessRequest(data) {
    // console.log('new data-----------');

    let newdata = {
      email: data.profileObj.email,
      fname: data.profileObj.name,
      id: data.googleId,
      pic: data.profileObj.imageUrl,
    };

    // console.log('new data-----------');

    // console.log(newdata);

    const response = await createGoogleAccount(newdata);

    if (response.status) {
      let res = response.data;

      res['pic'] = ImageEndPoint + res.pic;

      dispatch(LOGIN(res));
      navigate('/account/home');
    }

    ///// dispatch(GOOGLE_API_SIGN_UP(data.profileObj));
  }

  // async function GoogleLoginSuccessRequest(data) {
  //   console.log(data);
  //   dispatch(
  //     GOOGLE_LOGIN({ email: data.profileObj.email }, () => {
  //       handleClose();
  //     })
  //   );
  // }

  async function GoogleFailureRequest(data) {
    // toast.error('Sorry!! Request Failed With Google');
  }
  return (
    <GoogleLogin
      clientId={
        '583809256929-bri6eksm6qtd7nq1gqbe99mkdu9n5v9c.apps.googleusercontent.com'
      }
      render={(renderProps) => (
        <button className='btn-social' onClick={renderProps.onClick}>
          <i className='fab fa-google'></i>
          <span className='ml-4'> {props.name}</span>
        </button>
      )}
      onSuccess={GoogleSuccessRequest}
      onFailure={GoogleFailureRequest}
      cookiePolicy={'single_host_origin'}
    ></GoogleLogin>
  );
}

export default GoogleApi;
