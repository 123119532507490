import React, { useState } from "react";
import Container from "@mui/material/Container";

import { useFormik } from "formik";
import ReactTagInput from "@pathofdev/react-tag-input";
import * as Yup from "yup";
// import ViewUser from 'Admin/ViewUser';

import { useMutation, useQuery } from "react-query";
import ErrorService from "services/formatError/ErrorService";
import userServices from "services/httpService/userAuth/userServices";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import ViewDisease from "admin/ViewDisease";

function AddDisease() {
  const [isEdit, setisEdit] = useState(false);
  const [listDisease, setListDisease] = useState([]);

  const onChangeHandler = async (e) => {
    ////// let url = 'https://smarttravel.life/readfiles/1645635540578-Akupunktur.jpg';

    // fetch(url)
    //   .then((response) => response.blob())
    //   .then(
    //     (blob) =>
    //       new Promise((resolve, reject) => {
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //           resolve(reader.result);
    //           var output = document.getElementById('output');
    //           output.src = reader.result;
    //         };

    //         reader.onerror = reject;
    //         reader.readAsDataURL(blob);
    //       })
    //   );
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output");
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);

      formik.setFieldValue("pics", file);

      if (isEdit == true) {
        const formData = new FormData();
        formData.append("pics", file);
        formData.append("_id", formik.values._id);

        updateImageisease.mutate(formData);
      }

      // formData.append('pics', e.target.files[0]);
    }
  };

  const getAllDisease = useQuery(
    "ordersData",
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setListDisease(res.data);
      },
    }
  );

  let user = {
    _id: "",
    name: "",
    desc: "",
    pics: "",
    keywords: [],
  };

  const [userSechema, setuserSechema] = useState(user);
  const formik = useFormik({
    initialValues: userSechema,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, "Must be more than 4 characters")
        .required("Required"),
      pics: Yup.string().required("Required"),
      keywords: Yup.array().required("Required"),
    }),
    onSubmit: async (values) => {
      if (isEdit === false) {
        const formData = new FormData();
        formData.append("pics", values.pics);
        formData.append("name", values.name);
        values.keywords.forEach((item) => formData.append("keywords[]", item));
        formData.append("desc", values.desc);
        addNewDisease.mutate(formData);
      } else {
        const formData = new FormData();
        //////  formData.append('pics', values.pics);
        formData.append("name", values.name);
        values.keywords.forEach((item) => formData.append("keywords[]", item));
        formData.append("desc", values.desc);
        formData.append("_id", values._id);
        updateService.mutate(values);
      }

      ///// toast.success('User Added');
      // navigate('/dashboard');
    },
  });

  const addNewDisease = useMutation(
    (NewDisease) =>
      userServices.commonPostService("/admin/addNewDisease", NewDisease),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllDisease.refetch();

        setuserSechema(user);
        formik.setFieldValue("name", "");
        formik.setFieldValue("keywords", []);
        formik.setFieldValue("desc", "");
        toast.success("Disease has been created");
      },
    }
  );

  const updateImageisease = useMutation(
    (updateImage) =>
      userServices.commonPostService("/admin/updateImageDisease", updateImage),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllDisease.refetch();

        // setuserSechema(user);
        // formik.setFieldValue('name', '');
        // formik.setFieldValue('keywords', []);
        // formik.setFieldValue('desc', '');
        // toast.success('Disease has been created');
      },
    }
  );

  // eslint-disable-next-line

  const updateService = useMutation(
    (updateNow) =>
      userServices.commonPostService("/admin/updateoneDisease", updateNow),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllDisease.refetch();
        setuserSechema(user);
        setisEdit(false);
        formik.setFieldValue("_id", "");
        formik.setFieldValue("name", "");
        formik.setFieldValue("keywords", []);
        formik.setFieldValue("desc", "");
        toast.success("updated");
      },
    }
  );

  const deleteService = useMutation(
    (deleteService) =>
      userServices.commonPostService("/admin/deleteOneDisease", deleteService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("Disease has been deleted");
        getAllDisease.refetch();
      },
    }
  );
  return (
    <div>
      <Container maxWidth="sm">
        <div className="flex-auto px-2 lg:px-10 py-10 pt-0">
          <div className="text-blueGray-400 text-center text-xl mb-3 font-bold">
            {isEdit === false ? (
              <span>Add Disease</span>
            ) : (
              <span>Update Disease</span>
            )}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="relative w-full mb-3">
              <div className="grid content-center items-center justify-center">
                <img
                  id="output"
                  src="https://ui-avatars.com/api/?name=John+Doe"
                  className="w-32 h-32 rounded object-cover"
                  alt=""
                />
                <div className="mt-2 center-styl">
                  <label htmlFor="contained-button-file" className="mt-6">
                    <input
                      accept="image/*"
                      id="contained-button-file"
                      type="file"
                      onChange={onChangeHandler}
                      className="img-upload"
                    />
                    <Button variant="contained" component="span">
                      Upload
                    </Button>
                  </label>
                </div>
                {formik.touched.pics && formik.errors.pics ? (
                  <div className="text-red-500 text-xs mt-2">
                    {formik.errors.pics}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-name"
              >
                Name
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="input-styl"
                placeholder="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-red-500 text-xs">{formik.errors.name}</div>
              ) : null}
            </div>

            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-name"
              >
                Keywords
              </label>
              <ReactTagInput
                tags={formik.values.keywords}
                onChange={(newTags) =>
                  formik.setFieldValue("keywords", newTags)
                }
              />
              {formik.touched.keywords && formik.errors.keywords ? (
                <div className="text-red-500 text-xs">
                  {formik.errors.keywords}
                </div>
              ) : null}
            </div>

            <div className="relative w-full mb-3">
              <label
                className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                htmlFor="grid-name"
              >
                Description
              </label>
              <textarea
                type="text"
                rows="4"
                cols="50"
                name="desc"
                id="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                className="input-styl desc-styl"
                placeholder="Description"
              ></textarea>
              {formik.touched.desc && formik.errors.desc ? (
                <div className="text-red-500 text-xs">{formik.errors.desc}</div>
              ) : null}
            </div>

            <div className="text-center mt-6">
              <button className="btn-styl" type="submit">
                {isEdit === false ? <>Add Disease</> : <>Update Disease</>}{" "}
              </button>
            </div>
          </form>
        </div>
        {/* eslint-disable-next-line */}
      </Container>
      <div className="mx-10">
        {getAllDisease.isLoading == false && listDisease != "" ? (
          <ViewDisease
            data={listDisease.data}
            deleteService={deleteService}
            formik={formik}
            setisEdit={setisEdit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AddDisease;
