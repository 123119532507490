import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';

function Card({ img, title }) {
  return (
    <div className=' landing-cards  h-32 mt-4'>
      <div className='w-20 h-12 mbl-cards'>
        <img
          src={img}
          className='w-36  h-20 rounded-full object-cover '
          alt=''
        />
        <p className='text-center text-md semibold  mt-2 text-cards-bg font-bold mbl-cards'>
          {title}
        </p>
      </div>
    </div>
  );
}

export default function LandingProfessional() {
  let navigate = useNavigate();

  return (
    <>
      <section className=''>
        <div
          className='bg-cover  w-full   pb-12'
          style={{
            backgroundImage: 'url(' + require('assets/img/pic.png') + ')',
            // backgroundImage: 'url("https://i.ibb.co/fQ66S6B/Titelpic-New.png")',
          }}
        >
          <Container maxWidth='lg'>
            <Grid container spacing={0}>
              <Grid item lg={12} xs={12} className=''>
                <div className='mt-24   '>
                  <div className=' flex xs:items-center xs:justify-center lg:items-center lg:justify-start  '>
                    <h3 className='bg-[#633C01] text-font  text-center px-2 semibold text-white'>
                      Exklusiv nur für Heilpraktiker, Therapeuten & Coachs!
                    </h3>
                  </div>

                  <div className='mt-10 main-heading mbl-heading'>
                    <h1 className='textBold lg:w-7/12 xs:w-full  text-white lg:text-start xs:text-center  landing-shadow'>
                      Erhalten sie mit uns die passenden Patienten für ihr
                      Leistungsangebot
                    </h1>
                  </div>

                  <div className='mt-6  '>
                    <p className='  text-white semibold  text-md lg:text-start xs:text-center '>
                      Effektiv und ohne Aufwand für sie!
                    </p>
                  </div>
                  <div className='mt-6  '>
                    <p className='  text-white lg:w-6/12  sm:w-full lg:text-start xs:text-center  description'>
                      Wir machen sie genau dort sichtbar, wo Betroffene als
                      erstes nach Informationen suchen. Mit Xpera genau die
                      Patienten auf sie aufmerksam machen, die zu Ihnen passen.
                    </p>
                  </div>
                  <div className='flex gap-10 mt-12 landing-buttons'>
                    <div className=' flex justify-center '>
                      <button
                        onClick={() => scroll.scrollTo(1000)}
                        className='btn-sizes bg-white rounded-md  text-amber-900 semibold hover:bg-yellow-300 '
                      >
                        Mehr erfahren
                      </button>
                    </div>
                    <div className=' flex justify-center mobile-btn-margin '>
                      <button
                        onClick={() => navigate('/pricing')}
                        className=' Packeten-btn py-2 semibold rounded-md  text-white border-white landing-btn border-2  font-bold  hover:bg-sky-600 '
                      >
                        Zu den Premium-Paketen
                      </button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </section>
      <section className='py-12'>
        <div className='flex justify-center items-center   '>
          <p className='title-koshto  text-center textBold text-text-clr  '>
            Holen sie Hilfesuchende dort ab, wo sich sich zuerst informieren
          </p>
        </div>

        <Container maxWidth='md'>
          <Grid container spacing={8}>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 text-center'>
                <div className=' flex justify-center'>
                  <img
                    src={require('assets/img/Bild10.png')}
                    className=''
                    alt=''
                  />
                </div>
                <div className='flex justify-center font-bold text-text-clr  ml-3 '>
                  <h2 className='Number-style  textBold'>90%</h2>
                </div>
                <p className='-mt-1 semibold flex justify-center text-text-clr text-style'>
                  der potenziellen Patienten suchen zu erst im Internet nach
                  Informationen
                </p>
              </div>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 text-center'>
                <div className=' flex justify-center'>
                  <img
                    src={require('assets/img/Bild11.png')}
                    className=''
                    alt=''
                  />
                </div>
                <div className='flex justify-center font-bold text-text-clr ml-3 '>
                  <h2 className='Number-style  textBold'>10.000+</h2>
                </div>
                <p className='-mt-1 semibold flex justify-center text-text-clr text-style'>
                  Nutzer informieren sich jeden Monat auf unserer Plattform über
                  die Behandlungserfolge anderer Betroffener
                </p>
              </div>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 text-center'>
                <div className=' flex justify-center'>
                  <img
                    src={require('assets/img/Bild12.png')}
                    className=''
                    alt=''
                  />
                </div>
                <div className='flex justify-center font-bold text-text-clr  ml-3 '>
                  <h2 className='Number-style  textBold'>78%</h2>
                </div>
                <p className='-mt-1 semibold flex justify-center text-text-clr text-style'>
                  der Hilfesuchenden entscheiden anhand der Erfahrungsberichte
                  und Bewertungen Anderer
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='bg-color-bg   py-12  justify-center items-center landing-mbl-back landing-mbl-resp'>
        <Container maxWidth='lg'>
          <div className='grid justify-center items-center   '>
            <div className=' center-styl'>
              <p className='title-koshto second-title textBold  text-text-clr  '>
                Seien sie als Experte sichtbar für Themen wie
              </p>
            </div>
          </div>
          <div className='flex center-styl mt-12'>
            <div className='flex flex-wrap gap-10'>
              <Card title='Akne' img='https://i.ibb.co/t3hz2L7/rashes.jpg' />
              <Card
                title='Depressionen'
                img='https://i.ibb.co/zRxLBVP/Depression.jpg'
              />

              <Card title='Husten' img='https://i.ibb.co/m6v1zzr/Husten.jpg' />

              <Card
                title='Panikattacke'
                img='https://i.ibb.co/sPTpbzL/Panikattacke.jpg'
              />

              <Card
                title='Übergewicht'
                img='https://i.ibb.co/q750GDQ/pic1.jpg'
              />

              <Card
                title='Erschöpfung'
                img='https://i.ibb.co/FDKgWx3/pic2.jpg'
              />

              <Card title='Asthma' img='https://i.ibb.co/82ZH0Y6/Asthma.jpg' />
              <Card
                title='Allergie'
                img='https://i.ibb.co/3f4ptLx/Allergie.jpg'
              />
            </div>
          </div>
        </Container>
      </section>
      <section className='py-16 '>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={12}>
              <div className='mt-24    center-styl '>
                <div className='xpera-center'>
                  <p className='text-expera-bg  semibold xpera-heading'>
                    IHRE INDIVIDUELLE PROFILSEITE
                  </p>
                  <h2 className='mt-2 font-bold text-black text-3xl textBold informative-above '>
                    So informativ und ansprechend wie eine eigene Webseite
                  </h2>
                  <p className=' text-md xpera-para'>
                    Haben sie volle Kontrolle über ihr öffentliches Profil in
                    Webseitenqualität. Zeigen sie Interessenten, welche
                    Schwerpunkte und Leistungen ihre Praxis zu bieten hat und
                    welche Behandlungserfolge sie bereits erzielt haben. Ihr
                    Profil wird von uns stetig weiterentwickelt und erhält
                    regelmäßig nützliche Funktionen und Updates.
                  </p>
                  <div className='mt-20'>
                    <Link
                      className=' koshto-btn textBold text-center py-2 rounded-md hover:bg-[#20A3BB] text-white   px-4'
                      to={'/auth/signup'}
                    >
                      Kostenlos registrieren
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className='mt-16 '>
                <img
                  src={require('assets/img/Bild13.png')}
                  className=' w-full h-full object-cover'
                  alt=''
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='py-20'>
        <div className='  bg-color-bg py-20'>
          <Container maxWidth='lg'>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <div className=' '>
                  <img
                    src={require('assets/img/Bild14.png')}
                    className=' w-full h-full object-cover'
                    alt=''
                  />
                </div>
              </Grid>
              <Grid item lg={6} sm={12}>
                <div className='    center-styl '>
                  <div className='xpera-center'>
                    <p className='text-expera-bg semibold font-bold xpera-heading'>
                      PROMINENTER ANZEIGENPLATZ
                    </p>
                    <h2 className=' font-bold text-black textBold Prominter-text Aless-text'>
                      Wir platzieren Sie bei drei Themen ihrer Wahl für eine
                      optimale Sichtbarkeit
                    </h2>
                    <p className=' mt-6 text-xl xpera-para'>
                      Wählen sie aus Symptomen oder Behandlungen drei Themen
                      aus, bei denen sie als Experte wahrgenommen werden wollen.
                      Seien sie genau dann sichtbar, wenn Betroffene
                      entscheiden, einen Experten zu Hilfe zu ziehen. Ein Klick
                      auf Ihre passend platzierte Anzeige ist der erste Schritt
                      zu einem neuen Patienten für sie.
                    </p>
                    <div className='mt-20 '>
                      <Link
                        className=' themen-btn font-bold textBold rounded-md hover:bg-[#f2b619] text-white py-2  px-4 '
                        to={'/account/home'}
                      >
                        Jetzt von Anzeigen Profitieren
                      </Link>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </section>
      <section className='py-16'>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={12}>
              <div className='mt-16    center-styl '>
                <div className='xpera-center'>
                  <p className='text-expera-bg  semibold xpera-heading'>
                    WEITERE MÖGLICHKEITEN
                  </p>
                  <h2 className=' font-bold text-black text-3xl textBold Nutzen-above  '>
                    Nutzen sie Ihre Behandlungserfolge und Erfahrung für noch
                    mehr Reichweite
                  </h2>
                  <p className=' text-md xpera-para mt-6'>
                    Sicherlich haben sie bereits erfolgreich Menschen helfen
                    können. Berichten sie, mit welchen Therapien oder Methoden
                    sie welche Probleme erfolgreich behandelt haben. Somit
                    helfen sie Betroffenen bei der Entscheidungsfindung und
                    steigern ganz nebenbei Ihre Reputation und Reichweite.
                  </p>
                  <div className='mt-20'>
                    <Link
                      className=' themen-btn font-bold textBold rounded-md hover:bg-[#f2b619] text-white py-2  px-4 '
                      to={'/account/home'}
                    >
                      Behandlungserfolge teilen
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className='mt-2 '>
                <img
                  src={require('assets/img/Bild15.png')}
                  className=' w-full h-full object-cover'
                  alt=''
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='bg-[#FAFAFA] backgroud-height py-20'>
        <Container maxWidth='lg'>
          <div className='grid justify-center items-center   '>
            <div className='center-styl '>
              <p className='title-koshto second-title textBold  text-text-clr  '>
                Der kinderleichte und effektive Weg zu Ihrem Praxiserfolg
              </p>
            </div>
          </div>
          <div className=''>
            <img
              src={require('assets/img/steps.png')}
              className=' w-full h-full object-cover'
              alt=''
            />
          </div>
        </Container>

        <Container maxWidth='md'>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <div className='flex mt-16'>
                <div className=' bg-[#EB910C] w-6 h-6 border-bullet    rounded-full flex center-styl absolute'></div>
                <div className='ml-10'>
                  <p>
                    Starten Sie jetzt und schaffen sie in nur 10 Minuten die
                    perfekte Grundlage dafür, um neue, geeignete Patienten für
                    Ihre Praxis zu gewinnen – und das passiv, ohne jeden
                    weiteren Zeitaufwand
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className='flex mt-16'>
                <div className=' bg-[#1FA8C1] w-6 h-6 second-bullet    rounded-full flex center-styl absolute'></div>

                <div className='ml-10'>
                  <p>
                    Optional - durch Teilnahme an der Community können sie aktiv
                    ihr Ansehen und ihre Reichweite erhöhen und somit nochmals
                    zusätzlich Patienten für ihre Praxis gewinnen.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='py-12'>
        <Container maxWidth='sm'>
          <div className=' flex justify-center text-center'>
            <h3 className='w-full mehr-text text-cards-bg semibold'>
              Mehr Patienten gewinnen mit
            </h3>
          </div>
          <div className=' mt-2 flex justify-center items-center'>
            <img
              src={require('assets/img/Xpera logo.png')}
              className=' logo-below'
              alt=''
            />
          </div>
          <div className='mt-8 flex justify-center items-center'>
            <button
              onClick={() => navigate('/auth/signup')}
              className=' jetzt-btn textBold rounded-md hover:bg-[#20a3bb] text-white   '
            >
              Jetzt Loslegen
            </button>
          </div>
        </Container>
      </section>
      <section className=' mt-12 bg-color-bg flex w-full data-placing '>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <div className=' img-wrapping'>
              <img
                src={require('assets/img/Bild8.png')}
                className='origin-bottom -rotate-12 border-white ml-20 border-8  w-11/12 mt-20 curved-img'
                alt=''
              />
            </div>
          </Grid>
          <Grid item lg={7} xs={12}>
            <div className='bg-white w-10/12 mt-14 p-4 mobile-responsiveness mb-6'>
              <div className='mt-20 ml-4 content-placing'>
                <p className='text-expera-bg text-1xl semibold  '>
                  WAS IST MEINE INTENSION FÜR XPERA?
                </p>
                <p className=' text-2xl textBold mt-4'>
                  Ein Heilpraktiker hat mir mein Leben zurückgegeben - nun
                  möchte ich dieser Berufsgruppe etwas zurückgeben
                </p>
                <p className='font-bold text-1xl   mt-6'>
                  Mit Mitte 30 war ich eine einzige "gesundheitliche Baustelle".
                  Schon geringe Anstrengungen oder ein zu üppiges Essen sorgten
                  für Herzrasen, Herzstolpern und schlaflose Nächte, die nicht
                  selten in Panikattacken mündeten.
                </p>
                <p className='font-bold text-1xl semibold  mt-6'>
                  Der Tag, der mein Leben veränderte.
                </p>
                <p className='mt-8'>
                  Durch eine Bekannte wurde ich auf einen Kinesiologen
                  aufmerksam gemacht. Ich hatte mich zuvor noch nie mit
                  alternativer Medizin auseinandergesetzt und von Kinesiologie
                  hatte ich erst recht nichts gehört, aber ich griff nach jedem
                  Strohhalm, der Besserung versprach.
                </p>
                <p className='mt-8 '>
                  Gleich bei meinem ersten Termin ermittelte er den Grund für
                  meine Probleme. Seine Behandlung führte dazu, dass sich mein
                  Bluthochdruck innerhalb weniger Wochen normalisierte und alle
                  damit verbundenen Symptome rund um Herz und Kreislauf in
                  Wohlgefallen auflösten ... jetzt kann ich das Leben wieder
                  genießen! ( siehe Bild )
                </p>
                <p className='mt-8 '>
                  Dieses Erlebnis hat mein Leben verändert und war die
                  Geburtsstunde für den Wunsch nach einer Plattform wie Xpera.
                </p>
                <p className='mt-8 '>
                  Ich habe gelernt, wie wichtig Eigenverantwortung und der
                  richtige Ansprechpartner sind, wenn es darum geht gesund zu
                  werden und auch gesund zu bleiben.
                </p>
                <p className='font-bold text-1xl   mt-6'>
                  Nach meinem eben geschilderten Erlebnis sind Heilpraktikern
                  für mich die erste Adresse, da ihr Fokus nicht auf der bloßen
                  Behandlung selbst liegt, sondern darauf, die Ursache für das
                  gesundheitliche Problem zu ergründen.
                </p>
                <p className='mt-8 '>
                  Mit Xpera möchte ich Heilpraktikern, Therapeuten und
                  Gesundheits-Coachs dabei unterstützen noch mehr Menschen, wie
                  mir, helfen zu können.
                </p>
                <p className='text-xl mt-8 ml-6 '> Liebe Grüße</p>
                <p className='text-xl mt-3 ml-6'>Euer Jens</p>
              </div>
              <br />
              <br />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}
