const ServicesData = [
  {
    id: 0,
    title: 'Bioresonanz',
    img: 'https://i.ibb.co/s53mtDr/Bioresonanz.jpg',
    status: false,
  },
  {
    id: 1,
    title: 'Blutegeltherapie',
    img: 'https://i.ibb.co/c2FgZS8/Blutegeltherapie.jpg',
    status: false,
  },

  {
    id: 2,
    title: 'Chiropraktik',
    img: 'https://i.ibb.co/Qmtydp5/Chiropraktik.jpg',
    status: false,
  },

  {
    id: 3,
    title: 'Dunkelfeldmikroskopie',
    img: 'https://i.ibb.co/Sv3ySfj/Dunkelfeldmikroskopie.jpg',
    status: false,
  },

  {
    id: 4,
    title: 'Eigenbluttherapie',
    img: 'https://i.ibb.co/HnyXGsy/Eigenbluttherapie.jpg',
    status: false,
  },
  {
    id: 5,
    title: 'Elektrotherapie',
    img: 'https://i.ibb.co/Rh84m5H/Elektrotherapie.jpg',
    status: false,
  },

  {
    id: 6,
    title: 'Entspannungsverfahren',
    img: 'https://i.ibb.co/L5m8qW1/Entspannungsverfahren.jpg',
    status: false,
  },

  {
    id: 7,
    title: 'Ernährungsberatung',
    img: 'https://i.ibb.co/hd12L9v/Ern-hrungsberatung.jpg',
    status: false,
  },
  {
    id: 8,
    title: 'Familienaufstellung',
    img: 'https://i.ibb.co/qrHpbjd/Familienaufstellung.jpg',
    status: false,
  },
  {
    id: 9,
    title: 'Farbtherapie',
    img: 'https://i.ibb.co/9cWrnjB/Farbtherapie.jpg',
    status: false,
  },
  {
    id: 10,
    title: 'Fasziale-Osteopathie',
    img: 'https://i.ibb.co/K5hgtws/Fasziale-Osteopathie.jpg',
    status: false,
  },
  {
    id: 11,
    title: 'Fußreflexbehandlung',
    img: 'https://i.ibb.co/t8BzMkz/Fu-reflexbehandlung.jpg',
    status: false,
  },
  {
    id: 12,
    title: 'Gruppentherapien',
    img: 'https://i.ibb.co/KWSkWsZ/Gruppentherapie.jpg',
    status: false,
  },
  {
    id: 13,
    title: 'Hypnose-Therapie',
    img: 'https://i.ibb.co/2jRWMgH/Hypnose-Therapie.jpg',
    status: false,
  },
  {
    id: 14,
    title: 'IHHT-Therapie',
    img: 'https://i.ibb.co/q1R8QPY/IHHT-Therapie.jpg',
    status: false,
  },
  {
    id: 15,
    title: 'Injektions-Infusionstherapie',
    img: 'https://i.ibb.co/Jq27Vkw/Injektions-Infusionstherapie.jpg',
    status: false,
  },
  {
    id: 16,
    title: 'Kinesiologie',
    img: 'https://i.ibb.co/12ks8PF/Kinesiologie.jpg',
    status: false,
  },
  {
    id: 17,
    title: 'Klangtherapie',
    img: 'https://i.ibb.co/HxnRjxL/Klangtherapie.jpg',
    status: false,
  },
  {
    id: 18,
    title: 'Klassische Homöopathie',
    img: 'https://i.ibb.co/Hrh06J4/Klassische-Hom-opathie.jpg',
    status: false,
  },
  {
    id: 19,
    title: 'Krankengymnastik',
    img: 'https://i.ibb.co/hcdYHcp/Krankengymnastik.jpg',
    status: false,
  },
  {
    id: 20,
    title: 'Magnetfeldtherapie',
    img: 'https://i.ibb.co/kHdK41k/Magnetfeldtherapie.jpg',
    status: false,
  },
  {
    id: 21,
    title: 'Massagen',
    img: 'https://i.ibb.co/HC2RScB/Massagen.jpg',
    status: false,
  },
  {
    id: 22,
    title: 'Mitochondriale-Medizin',
    img: 'https://i.ibb.co/n6GN0Hz/Mitochondriale-Medizin.jpg',
    status: false,
  },
  {
    id: 23,
    title: 'Neuraltherapien',
    img: 'https://i.ibb.co/hs9dyGx/Neuraltherapie.jpg',
    status: false,
  },
  {
    id: 24,
    title: 'Orthomolekulare-Therapie',
    img: 'https://i.ibb.co/dGB23BJ/Orthomolekulare-Therapie.jpg',
    status: false,
  },
  {
    id: 25,
    title: 'Osteopathie',
    img: 'https://i.ibb.co/jfn4CJR/Osteopathie.jpg',
    status: false,
  },

  {
    id: 26,
    title: 'Paartherapie',
    img: 'https://i.ibb.co/k6nF0t5/Paartherapie.jpg',
    status: false,
  },
  {
    id: 27,
    title: 'Physiotherapie',
    img: 'https://i.ibb.co/bNcLKyY/Physiotherapie.jpg',
    status: false,
  },
  {
    id: 28,
    title: 'Phytotherapie',
    img: 'https://i.ibb.co/C9VRRqP/Phytotherapie.jpg',
    status: false,
  },
  {
    id: 29,
    title: 'Psychosomatische-Behandlung',
    img: 'https://i.ibb.co/VYJBsFf/Psychosomatische-Behandlung.jpg',
    status: false,
  },
  {
    id: 30,
    title: 'Psychotherapie',
    img: 'https://i.ibb.co/QfYF5YX/Psychotherapie.jpg[/img',
    status: false,
  },
  {
    id: 31,
    title: 'Regenerationstherapie',
    img: 'https://i.ibb.co/1QhB48H/Regenerationstherapie.jpg',
    status: false,
  },
  {
    id: 32,
    title: 'Reinkarnationstherapie',
    img: 'https://i.ibb.co/mhKshPp/Reinkarnationstherapie.jpg',
    status: false,
  },
  {
    id: 33,
    title: 'Sauerstofftherapie',
    img: 'https://i.ibb.co/7Kc79FP/Sauerstofftherapie.jpg',
    status: false,
  },
  {
    id: 34,
    title: 'Schmerztherapie',
    img: 'https://i.ibb.co/txSvRK9/Schmerztherapie.jpg',
    status: false,
  },
  {
    id: 35,
    title: 'Stoßwellentherapie',
    img: 'https://i.ibb.co/zSnRt3z/Sto-wellentherapie.jpg',
    status: false,
  },
  {
    id: 36,
    title: 'Traditionelle-Chinesische-Medizin',
    img: 'https://i.ibb.co/tDST72z/Traditionelle-Chinesische-Medizin.jpg',
    status: false,
  },
  {
    id: 37,
    title: 'Traumatherapie',
    img: 'https://i.ibb.co/YcWHvHd/Traumatherapie.jpg[/img',
    status: false,
  },
  {
    id: 38,
    title: 'Verhaltenstherapie',
    img: 'https://i.ibb.co/DD1p9wv/Verhaltenstherapie.jpg',
    status: false,
  },
  {
    id: 39,
    title: 'Akupunktur',
    img: 'https://i.ibb.co/mGyRxDb/Akupunktur.jpg',
    status: false,
  },
  {
    id: 40,
    title: 'Allergietherapie',
    img: 'https://i.ibb.co/TtvbsWk/Allergietherapie.jpg',
    status: false,
  },
  {
    id: 41,
    title: 'Atemtechniken',
    img: 'https://i.ibb.co/pXJHtrB/Atemtechniken.jpg',
    status: false,
  },
  {
    id: 42,
    title: 'Augendiagnose',
    img: 'https://i.ibb.co/N2Yh4sp/Augendiagnose.jpg',
    status: false,
  },
  {
    id: 43,
    title: 'Ayurveda',
    img: 'https://i.ibb.co/3CkDdph/Ayurveda.jpg',
    status: false,
  },
  {
    id: 44,
    title: 'Bachblütentherapie',
    img: 'https://i.ibb.co/wY424nN/Bachbl-tentherapie.jpg',
    status: false,
  },
];
export default ServicesData;
