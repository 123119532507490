import React from 'react';
// import { toast } from 'react-toastify';
import FacebookLogin from 'react-facebook-login';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateFacebookAccount } from 'hooks';
import { LOGIN } from 'reactStore/actions/Actions';
import { ImageEndPoint } from 'config/config';
function FacebookApi(props, { type = 'Signup', handleClose }) {
  const dispatch = useDispatch();
  const { mutateAsync: createFacebookAccount } = useCreateFacebookAccount();
  let navigate = useNavigate();
  async function FacebookLoginSuccessRequest(data) {
    let newdata = {
      email: data.email,
      fname: data.name,
      id: data.id,
      pic: data.picture.data.url,
    };

    const response = await createFacebookAccount(newdata);

    if (response.status) {
      let res = response.data;

      res['pic'] = ImageEndPoint + res.pic;

      dispatch(LOGIN(res));

      navigate('/account/home');
    }
  }
  return (
    <FacebookLogin
      appId={'288332070178271'}
      textButton={props.name}
      fields='name,email,picture'
      callback={FacebookLoginSuccessRequest}
      cssClass='btn-social  mt-4'
      icon={<i className='fab fa-facebook  text-blue-600 mr-2'></i>}
    />
  );
}

export default FacebookApi;
