import React, { useState, useEffect } from 'react';
import { ImageEndPoint } from 'config/config';

export default function UnLockProfile({ data }) {
  const services = data.services.map((item) => {
    return JSON.parse(item);
  });

  const [allsiggestions, setallsiggestions] = useState([]);

  useEffect(() => {
    let finalarr = [];

    data.posts.map((item) => {
      if (!finalarr.includes(item.symptons[0].name)) {
        finalarr.push(item.symptons[0].name);
      }
    });
    // console.log(
    //   ' console.log(finalarr); console.log(finalarr); console.log(finalarr);'
    // );
    // console.log(finalarr);

    setallsiggestions(finalarr);
  }, []);

  return (
    <div>
      <p className='text-xs  text-black'>
        {data.welcome.split('\n').map((item, key) => {
          return (
            <span key={key}>
              {item}
              <br />
            </span>
          );
        })}
      </p>
      <section className='my-6'>
        <div className=' bold-styl flex'>
          <i className='fas fa-check-square text-base text-color-yellow'></i>
          <span className='mx-4 text-xs'> Schwerpunkte & Leistungen</span>
        </div>

        <div className='my-3 grid lg:grid-cols-4 md:grid-cols-4 sm:grid-cols-2 xs:grid-cols-2  lg:gap-x-20 sm:gap-x-4 xs:gap-x-4'>
          {services.map((item, index) =>
            item.status ? (
              <div className='bg-white shadow rounded-lg mt-4' key={index}>
                <img
                  src={item.img}
                  alt='img'
                  id='cover'
                  loading='lazy'
                  className='object-cover w-full h-40  rounded-t-lg'
                />

                <div className='text-center text-xs py-2  px-2 truncate'>
                  {item.title}
                </div>
              </div>
            ) : null
          )}
        </div>
      </section>

      <section className='my-6'>
        <div className=' bold-styl flex'>
          <i className='fas fa-heart text-base text-color-yellow'></i>
          <span className='mx-4 text-xs'>
            Meine Behandlungsmethoden waren erfolgreich bei
          </span>
        </div>

        <div className='my-3 flex flex-wrap'>
          {data.posts != ''
            ? allsiggestions.map((item) => (
                <div className='bg-white shadow rounded-full  m-2 p-2 px-4'>
                  <div className='text-center text-xs truncate'>{item}</div>
                </div>
              ))
            : null}
        </div>
      </section>

      <section className='my-6'>
        <div className=' bold-styl flex'>
          <i className='fas fa-info-circle text-base text-color-yellow'></i>
          <span className='mx-4 text-xs'>Weitere Informationen</span>
        </div>

        <div className='my-3 '>
          <p className='text-xs'>
            {/* {data.info} */}

            {data.info.split('\n').map((item, key) => {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </p>
        </div>
      </section>
    </div>
  );
}
