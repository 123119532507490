import { Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { localToken } from 'config/config';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'reactStore/MainStore';

export default function IsLogin() {
  const { IsCognito, currentName, currentPic, profile } = useSelector(
    (state) => state.User
  );
  let location = useLocation();
  let navigate = useNavigate();

  React.useEffect(() => {
    if (store.getState().User.profile == null) {
      toast.error('Bitte melde Dich an!');
      navigate('/auth/signin');
    }
  }, [location, navigate]);

  return <Outlet />;
}
