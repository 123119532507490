import React from 'react';
import { hydrate, ReactDOM, render } from 'react-dom';
// global css
import 'assets/css/index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import '@pathofdev/react-tag-input/build/index.css';
import './assets/fonts/Poppins-Regular.ttf';
import './assets/fonts/Poppins-ExtraBold.ttf';
import './assets/fonts/Poppins-Bold.ttf';
import './assets/fonts/Poppins-SemiBold.ttf';

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';
import { composeWithDevTools } from 'redux-devtools-extension';
import Root from './routes';
import { master } from './store/reducers/combineReducer';
import { HelmetProvider } from 'react-helmet-async';

import { store } from './reactStore/MainStore';
import LanguageContextProvider from 'common/contexts/LanguageContext';
import { ToastContainer } from 'react-toastify';
const queryClient = new QueryClient();

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <LanguageContextProvider>
            <HelmetProvider>
              <Root />
            </HelmetProvider>
          </LanguageContextProvider>
          <ToastContainer limit={1} />
        </React.StrictMode>
      </QueryClientProvider>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <LanguageContextProvider>
            <Root />
          </LanguageContextProvider>
          <ToastContainer limit={1} />
        </React.StrictMode>
      </QueryClientProvider>
    </Provider>,
    rootElement
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
