import * as ActionList from "../actions/ActionsList";
const initialState = {
  IS_LOGGED: false,
  rememberMe: null,
  currentName: null,
  currentPic: null,

  ////// TOKEN: null,
  profile: null,
  IsCognito: false,
  countRandom: 1,
  userOnline: Math.floor(Math.random() * (220 - 50 + 1)) + 50,
};
// eslint-disable-next-line
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionList.IS_LOGGED_OUT:
      return { ...state, IS_LOGGED: false };

    case ActionList.SET_FAKE_USER:
      return {
        ...state,

        countRandom: payload.countRandom,
        userOnline: payload.userOnline,
      };

    case ActionList.TOOGLE_PROFILE:
      return {
        ...state,
        IsCognito: payload.IsCognito,
        currentName: payload.currentName,
        currentPic: payload.currentPic,
      };

    case ActionList.USER_PROFILE:
      return {
        ...state,
        profile: payload,
        currentName: payload.fname,
        currentPic: payload.pic,
        IS_LOGGED: true,
        rememberMe: payload.rememberMe,
      };

    case ActionList.EMPTY_STORE:
      return {
        IS_LOGGED: false,

        currentName: null,
        rememberMe: null,
        currentPic: null,

        ////// TOKEN: null,
        profile: null,
        IsCognito: false,
      };

    default:
      return state;
  }
};
