import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';

function SwiperView(props) {
  const DATA = [
    {
      key: '1',
      img: require('assets/img/account1.png'),
      title: 'Finde die beste Behandlung für Dein Thema',
      subtitle:
        'Lass Dir von Menschen helfen, die genau die gleichen Beschwerden hatten wie Du. Profitiere von ihren Erfahrungen und finde das beste Mittel, die beste Therapie oder die besten Fachleute für Dein Problem! ',
    },
    {
      key: '2',
      img: require('assets/img/account2.png'),
      title: 'Lerne und teile Erfahrungen',
      subtitle:
        'Jeder macht in seinem Leben Erfahrungen mit gesundheitlichen Problemen. Lass Andere an Deinem Wissen teilhaben und erschaffe so gemeinsam mit uns echte Lebenshilfe!',
    },
    {
      key: '3',
      img: require('assets/img/account3.png'),
      title: 'Danke für Deinen Beitrag!',
      subtitle:
        'Jede einzelne Erfahrung ist ein Gewinn für alle! Wir sagen “Herzlichen Dank” dafür!',
    },
  ];

  return (
    <div>
      <Swiper
        pagination={true}
        modules={[Pagination]}
        className='mySwiper'
        activeSlideKey={props.key}
        allowTouchMove={false}
        initialSlide={props.swiperkey}
      >
        {DATA.map((item) => (
          <SwiperSlide className='swiper-slide' key={item.key}>
            <div>
              <img src={item.img} alt='siginup 1' className='mb-6 ' />
              <div className='text-center font-semibold text-xs mt-10'>
                {item.title}
              </div>
              <div className='text-center text-color-grey text-xs mt-2'>
                {item.subtitle}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default SwiperView;
