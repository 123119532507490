import React from 'react';

import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import useTranslation from 'common/customHooks/translations';
import { useLocation, useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import ErrorService from 'services/formatError/ErrorService';
import SwiperView from 'components/SwiperView';
import ImageUploading from 'react-images-uploading';
import { useCreateEmailAccount } from 'hooks';
function UploadProfile() {
  const { mutateAsync: createEmailAccount, isLoading } =
    useCreateEmailAccount();

  let navigate = useNavigate();
  const t = useTranslation();
  const { state } = useLocation();

  const [images, setImages] = React.useState([]);
  const maxNumber = 1;

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  const onError = (errors, files) => {
    console.log(errors.maxFileSize);
    if (errors.maxFileSize == true) {
      toast.error(t.maxfilesize);
    }
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const registerUser = useMutation(
    (registerUser1) =>
      userServices.commonPostService('userAuth/registerByEmail', registerUser1),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        if (res.status) {
          navigate('/auth/signin');
        }
      },
    }
  );

  const submitValues = async () => {
    console.log('----------------allll');

    let file = dataURLtoFile(images[0].data_url, images[0].file.name);

    ////// state.pics = file
    console.log('=================', state);

    // console.log(file);

    const formData = new FormData();
    formData.append('pics', file);
    //// formData.append("bday", state.bday);

    formData.append('email', state.email);
    formData.append('fname', state.fname);

    formData.append('postcode', state.postcode);

    //// formData.append("gender", state.gender);
    ////formData.append("lname", state.lname);
    formData.append('pass', state.pass);

    /////formData.append("username", state.username);

    registerUser.mutate(formData);

    // const response = await createEmailAccount(formData);
    // if (response.status) {
    //   navigate("/signin");
    // }
    ////   toast.success("Upload Successfully");
    //// navigate("/");
  };

  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h1 className='md:text-lg mt-4 text-color-primary sm:text-sm mb-4 font-semibold text-center'>
              {t.uploadprofile}
            </h1>
          </Grid>
        </Grid>

        <div className='mobileview'>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SwiperView swiperkey={3} />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='text-color-grey desktopview text-center text-xs '>
              {t.uploadprofile_details}
            </div>

            <div className='relative w-full mb-3 '>
              <ImageUploading
                value={images}
                onChange={onChange}
                maxNumber={maxNumber}
                maxFileSize={3145728}
                dataURLKey='data_url'
                onError={onError}
              >
                {({
                  imageList,
                  onImageUpload,
                  onImageRemoveAll,
                  onImageUpdate,
                  onImageRemove,
                  isDragging,
                  dragProps,
                }) => (
                  // write your building UI
                  <div className='upload__image-wrapper my-10 flex flex-row '>
                    {imageList.map((image, index) => (
                      <img
                        src={image['data_url']}
                        alt=''
                        className='object-cover w-24 h-24 rounded-full'
                      />
                    ))}
                    <button
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                      className='ml-8 text-xs font-semibold'
                    >
                      <i className='fas fa-upload mx-2 text-xl' />
                      {t.imgupload}
                    </button>
                    &nbsp;
                    {/* eslint-disable-next-line */}
                    {images != '' ? (
                      <button onClick={onImageRemoveAll}>
                        <i className='fas fa-times mx-2 text-xl' />
                      </button>
                    ) : null}
                  </div>
                )}
              </ImageUploading>
            </div>

            <div className='text-center my-6'>
              {registerUser.isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {images != '' ? (
                    <button
                      type='button'
                      className='btn-styl'
                      onClick={() => submitValues()}
                    >
                      {t.finished}
                    </button>
                  ) : (
                    <button type='button' className='btn-disable ' disabled>
                      {t.finished}
                    </button>
                  )}
                </>
              )}

              {/* eslint-disable-next-line */}
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='desktopview -mt-32'>
              <SwiperView swiperkey={3} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default UploadProfile;
