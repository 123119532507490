import React from 'react';
import {
  localStorageData,
  storeLocalData,
  Logout,
} from 'services/auth/localStorageData';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useTranslation from 'common/customHooks/translations';
import Grid from '@mui/material/Grid';
import userService from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { useMutation } from 'react-query';
import { ImageEndPoint } from 'config/config';
import moment from 'moment';
import userServices from 'services/httpService/userAuth/userServices';
import { TOOGLE_PROFILE, EMPTYSOTRE } from 'reactStore/actions/Actions';

function ProfileSettings() {
  // const { IsCognito, currentName, currentPic, profile } = useSelector(
  //   (state) => state.User
  // );
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const t = useTranslation();
  const ref = React.useRef();
  const myimg = localStorageData('pic');

  const formik = useFormik({
    initialValues: {
      userId: localStorageData('_id'),
      pics: ImageEndPoint + localStorageData('pic'),
      fname: localStorageData('fname'),

      postcode: localStorageData('postcode'),
    },
    validationSchema: Yup.object().shape({
      fname: Yup.string().min(2, t.mincharacters2).required(t.required),
      // postcode: Yup.string().min(2, t.mincharacters2).required(t.required),
      // username: Yup.string().min(4, t.mincharacters4).required(t.required),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append('pics', values.pics);
      formData.append('fname', values.fname);
      formData.append('postcode', values.postcode);

      formData.append('userId', localStorageData('_id'));

      mutate(formData);
    },
  });

  const { mutate } = useMutation(
    (updateProfile) =>
      userService.commonPostService('/userAuth/updateuserinfo', updateProfile),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        storeLocalData(data.data.data);
        let newdata = {
          IsCognito: false,

          currentName: data.data.data.fname,
          currentPic: ImageEndPoint + data.data.data.pic,
        };
        // console.log(data.data.data);

        dispatch(TOOGLE_PROFILE(newdata));
        toast.success(t.update);
      },
    }
  );

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('output1');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      formik.setFieldValue('pics', file);
    }
  };

  const deleteProfile = () => {
    if (window.confirm('Do you want to delete your profile?') == true) {
      DeleteProfile.mutate({
        userId: localStorageData('_id'),
      });
    } else {
      // console.log('no');
    }
  };

  const DeleteProfile = useMutation(
    (deleteProfile) =>
      userServices.commonPostService(
        '/userAuth/deleteUserProfile',
        deleteProfile
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success('Profile Deleted');
        Logout();
        dispatch(EMPTYSOTRE());
        navigate('/');
      },
    }
  );

  return (
    <div>
      <div className=' justify-start py-6 border-b border-color-lightgray'>
        <div className='text-xs font-bold text-black '>
          {t.profile}

          {/* {localStorageData('bday')} */}
        </div>

        <div className='text-xs text-color-grey'>{t.profiledetail}</div>
        <button
          className='bg-[#D3B2B1] w-36 float-right border border-[#CD6458] rounded lg:-mt-8 sm:mt-1'
          onClick={() => deleteProfile()}
        >
          <i className='far fa-trash-alt text-xs mr-1'></i>
          {t.deleteprofile}
        </button>
      </div>

      <form onSubmit={formik.handleSubmit}>
        {/* <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <div className='text-xs text-black'>{t.username}</div>
            </Grid>

            <Grid item xl={3} lg={3} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='username'
                name='username'
                className=' bg-color-lightgrey text-color-btn border-search truncate break-words'
                value={localStorageData('username')}
              />
            </Grid>
          </Grid>
        </div> */}

        <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <div className='text-xs text-black'>{t.yourphoto}</div>
            </Grid>

            <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
              <img
                src={ImageEndPoint + myimg}
                // src='https://ui-avatars.com/api/?name=NA'
                alt='img'
                id='output1'
                className='object-cover w-16 h-16 rounded-full  -mt-4'
              />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={6} xs={6}>
              <label
                className='text-color-btn font-semibold float-left '
                htmlFor='contained-button-file'
              >
                <input
                  type='file'
                  onChange={onChangeHandler}
                  accept='image/*'
                  id='contained-button-file'
                />
                Profilbild ändern
              </label>
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>{t.username}</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='fname'
                name='fname'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fname}
              />
              {formik.touched.fname && formik.errors.fname ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.fname}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Postleitzahl</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='postcode'
                name='postcode'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postcode}
              />
              {formik.touched.postcode && formik.errors.postcode ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.postcode}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        {/* <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <div className='text-xs text-black'>{t.dob}</div>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <div className='relative w-full  -mt-2'>
                <input
                  id='bday'
                  name='bday'
                  type='text'
                  ref={ref}
                  className='input-styl'
                  format='DD-MM-YYYY'
                  max={moment().format('YYYY-MM-DD')}
                  placeholder={t.dob}
                  onFocus={(e) => (e.currentTarget.type = 'date')}
                  onBlur={(e) => (e.currentTarget.type = 'text')}
                  onChange={formik.handleChange}
                  value={formik.values.bday}
                />
                {formik.touched.bday && formik.errors.bday ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.bday}
                  </div>
                ) : null}
              </div>
              <div className='text-black ml-2'>
                {moment(localStorageData('bday')).format('YYYY-MM-DD')}
              </div>
            </Grid>
          </Grid>
        </div> */}

        {/* <div className='flex justify-start mt-2 py-6 border-b border-color-lightgray'>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <div className='text-xs text-black'>{t.gender}</div>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <div className='relative w-full -mt-2 '>
                <select
                  id='gender'
                  name='gender'
                  className='input-styl'
                  placeholder={t.gender}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gender}
                >
                  <option value='' disabled selected>
                    {t.gender}
                  </option>
                  <option value='male'>{t.male}</option>
                  <option value='female'>{t.female}</option>
                </select>

                {formik.touched.gender && formik.errors.gender ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.gender}
                  </div>
                ) : null}
              </div>
            </Grid>
          </Grid>
        </div> */}

        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={8} xs={8}>
            <div className='text-center my-6 '>
              <button className='btn-styl' type='submit'>
                Einstellungen speichern
              </button>
            </div>
          </Grid>
        </Grid>
      </form>

      <hr className='' />
    </div>
  );
}

export default ProfileSettings;
