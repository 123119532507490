import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { createPopper } from "@popperjs/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TOOGLE_PROFILE, EMPTYSOTRE } from "reactStore/actions/Actions";
import { Logout, localStorageData } from "services/auth/localStorageData";
import { account } from "routes/config";
import { ImageEndPoint } from "config/config";

const IndexDropdown = () => {
  let navigate = useNavigate();

  const { IsCognito, currentName, currentPic, profile } = useSelector(
    (state) => state.User
  );

  const dispatch = useDispatch();
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const wrapperRef = useRef(null);

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  console.log("profile", localStorageData("uniqueProfileName"));
  return (
    <>
      <button
        className='hover:text-blueGray-500 text-blueGray-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold'
        ref={btnDropdownRef}
        onClick={(e) => {
          // e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <img
          id='output'
          src={currentPic}
          className='w-10 h-10 rounded-full object-cover'
          alt=''
          loading='lazy'
        />
      </button>
      <div
        ref={popoverDropdownRef}
        onBlur={closeDropdownPopover}
        onMouseLeave={closeDropdownPopover}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-xs z-40 py-2 list-none  text-left rounded shadow-lg min-w-96"
        }
      >
        <button
          onClick={() => {
            if (IsCognito) {
              let data = {
                IsCognito: false,

                currentName: profile.fname,
                currentPic: profile.pic,
              };

              dispatch(TOOGLE_PROFILE(data));
            } else {
              let data = {
                IsCognito: true,

                currentName: "Inkognitomodus",
                currentPic: "https://i.ibb.co/gmbdVjX/incognito-avatar.png",
              };

              dispatch(TOOGLE_PROFILE(data));
            }
          }}
          className='flex  py-4 px-4 font-normal hover:bg-color-light-yellow w-full whitespace-nowrap bg-transparent text-blueGray-700'
        >
          <img
            id='output'
            src={currentPic}
            className='w-10 h-10 rounded-full object-cover'
            alt=''
          />
          <span className='text-xs m-2 font-semibold'> {currentName} </span>
        </button>
        {localStorageData("uniqueProfileName") ? (
          <button
            onClick={() => navigate(`/account/${profile.uniqueProfileName}`)}
            className='text-xs py-2 px-4 font-normal block hover:bg-color-light-yellow  w-full whitespace-nowrap bg-transparent text-blueGray-700'
          >
            mein öffentliches Profil
          </button>
        ) : null}

        <button
          onClick={() => navigate(`/${account}/settings`)}
          className='text-xs py-2 px-4 font-normal block hover:bg-color-light-yellow  w-full whitespace-nowrap bg-transparent text-blueGray-700'
        >
          Profil | Einstellungen
        </button>
        <button
          onClick={() => {
            Logout();
            dispatch(EMPTYSOTRE());

            navigate("/signin");
          }}
          className='text-xs py-2  font-normal block hover:bg-color-light-yellow  w-full whitespace-nowrap bg-transparent text-blueGray-700'
        >
          Ausloggen
        </button>
      </div>
    </>
  );
};

export default IndexDropdown;
