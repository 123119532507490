import React from "react";
import Container from "@mui/material/Container";
import LandingHeader from "components/Headers/LandingHeader";
import Grid from "@mui/material/Grid";

function OrderComplete() {
  return <h1 className="pb-20">orderStatus will come here</h1>;
}

export default OrderComplete;
