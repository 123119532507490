import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import userServices from 'services/httpService/userAuth/userServices';
import { ImageEndPoint } from 'config/config';
import Skeleton from '@mui/material/Skeleton';

export default function AdCard({ link }) {
  const [listAds, setListAds] = useState([]);

  useEffect(() => {
    showAds();
  }, []);

  async function showAds() {
    const data = await userServices.commonPostService('/admin/fetAddByPage', {
      link: link,
    });

    // console.log(' new data=>', data.data.data);
    setListAds(data.data.data);
  }

  return (
    <>
      {listAds != ''
        ? listAds.map((item) => (
            <div className='bg-white border border-frame-gray p-4 rounded-lg mb-4 w-full'>
              <a target='_blank' type='button' href={item.website}>
                <h5 className='text-black text-xs2 '>{item.text}</h5>
                <div className='text-xs text-black bold-styl'>{item.title}</div>
                <div className='flex flex-row my-2 '>
                  <img
                    id='output'
                    src={ImageEndPoint + item.pic}
                    className='w-20 h-20 rounded object-cover'
                    alt=''
                  />
                  <p className='grid center-styl text-xs2 ml-2'>{item.desc}</p>
                </div>
              </a>
            </div>
          ))
        : null}
    </>
  );
}
