import React, { useState } from 'react';
import Container from '@mui/material/Container';

import { useFormik } from 'formik';
import ReactTagInput from '@pathofdev/react-tag-input';
import * as Yup from 'yup';
// import ViewUser from 'Admin/ViewUser';
import Category from 'components/Data/index';
import { useMutation, useQuery } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

import Stack from '@mui/material/Stack';
import { toast } from 'react-toastify';
import ViewTreatment from 'admin/ViewTreatment';

function AddTreatment() {
  const [isEdit, setisEdit] = useState(false);
  const [listTreatment, setListTreatment] = useState([]);

  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('output');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      formik.setFieldValue('pics', file);

      if (isEdit == true) {
        const formData = new FormData();
        formData.append('pics', file);
        formData.append('_id', formik.values._id);

        updateImageTreatment.mutate(formData);
      }
      // formData.append('pics', e.target.files[0]);
    }
  };

  const getAllTreatment = useQuery(
    'ordersData',
    () => userServices.commonGetService(`/admin/getallTreatment`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setListTreatment(res.data);
      },
    }
  );

  let user = {
    _id: '',
    name: '',
    desc: '',
    pics: '',
    category: '',
    keywords: [],
  };

  const [userSechema, setuserSechema] = useState(user);
  const formik = useFormik({
    initialValues: userSechema,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .min(3, 'Must be more than 4 characters')
        .required('Required'),
      pics: Yup.string().required('Required'),
      keywords: Yup.array().required('Required'),
      category: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      if (isEdit === false) {
        formData.append('pics', values.pics);
        formData.append('name', values.name);

        values.keywords.forEach((item) => formData.append('keywords[]', item));

        formData.append('desc', values.desc);
        formData.append('category', values.category);
        addNewTreatment.mutate(formData);
      } else {
        let data = {
          name: values.name,
          keywords: values.keywords,
          desc: values.desc,
          category: values.category,
          _id: values._id,
        };

        // formData.append('name', values.name);

        // values.keywords.forEach((item) => formData.append('keywords[]', item));

        // formData.append('desc', values.desc);
        // formData.append('_id', values._id);


        updateService.mutate(data);

        // updateUser.mutate(values);
      }

      ///// toast.success('User Added');
      // navigate('/dashboard');
    },
  });

  const addNewTreatment = useMutation(
    (NewTreatment) =>
      userServices.commonPostService('/admin/addNewTreatment', NewTreatment),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllTreatment.refetch();

        setuserSechema(user);
        formik.setFieldValue('name', '');
        formik.setFieldValue('keywords', []);
        formik.setFieldValue('desc', '');
        toast.success('Treatment has been created');
      },
    }
  );
  // eslint-disable-next-line

  const updateImageTreatment = useMutation(
    (updateImage) =>
      userServices.commonPostService(
        '/admin/updateImageTreatment',
        updateImage
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllTreatment.refetch();

        // setuserSechema(user);
        // formik.setFieldValue('name', '');
        // formik.setFieldValue('keywords', []);
        // formik.setFieldValue('desc', '');
        // toast.success('Disease has been created');
      },
    }
  );

  const updateService = useMutation(
    (updateNow) =>
      userServices.commonPostService('/admin/updateoneTreatment', updateNow),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        getAllTreatment.refetch();
        setuserSechema(user);
        setisEdit(false);
        formik.setFieldValue('_id', '');
        formik.setFieldValue('name', '');
        formik.setFieldValue('keywords', []);
        formik.setFieldValue('desc', '');
        toast.success('updated');
      },
    }
  );

  const deleteService = useMutation(
    (deleteService) =>
      userServices.commonPostService(
        '/admin/deleteOneTreatment',
        deleteService
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success('Treatment has been deleted');
        getAllTreatment.refetch();
      },
    }
  );
  return (
    <div>
      <Container maxWidth='sm'>
        <div className='flex-auto px-2 lg:px-10 py-10 pt-0'>
          <div className='text-blueGray-400 text-center text-xl mb-3 font-bold'>
            {isEdit === false ? (
              <span>Add Treatment</span>
            ) : (
              <span>Update Treatment</span>
            )}
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className='relative w-full mb-3'>
              <div className='grid content-center items-center justify-center'>
                <img
                  id='output'
                  src='https://ui-avatars.com/api/?name=John+Doe'
                  className='w-32 h-32 rounded object-cover'
                  alt=''
                />
                <div className='mt-2 center-styl'>
                  <label htmlFor='contained-button-file' className='mt-6'>
                    <input
                      accept='image/*'
                      id='contained-button-file'
                      type='file'
                      onChange={onChangeHandler}
                      className='img-upload'
                    />
                    <Button variant='contained' component='span'>
                      Upload
                    </Button>
                  </label>
                </div>
                {formik.touched.pics && formik.errors.pics ? (
                  <div className='text-red-500 text-xs mt-2'>
                    {formik.errors.pics}
                  </div>
                ) : null}
              </div>
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Name
              </label>
              <input
                id='name'
                name='name'
                type='text'
                className='input-styl'
                placeholder='Name'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className='text-red-500 text-xs'>{formik.errors.name}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Keywords
              </label>
              <ReactTagInput
                tags={formik.values.keywords}
                onChange={(newTags) =>
                  formik.setFieldValue('keywords', newTags)
                }
              />
              {formik.touched.keywords && formik.errors.keywords ? (
                <div className='text-red-500 text-xs'>
                  {formik.errors.keywords}
                </div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Category
              </label>
              <select
                name='category'
                id='category'
                className='input-styl h-12'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option value='' disabled selected>
                  Category
                </option>
                {Category.map((s, i) => (
                  <option value={s}>{s}</option>
                ))}
              </select>

              {formik.touched.category && formik.errors.category ? (
                <div className='text-red-600'>{formik.errors.category}</div>
              ) : null}
            </div>

            <div className='relative w-full mb-3'>
              <label
                className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                htmlFor='grid-name'
              >
                Description
              </label>
              <textarea
                type='text'
                rows='4'
                cols='50'
                name='desc'
                id='desc'
                value={formik.values.desc}
                onChange={formik.handleChange}
                className='input-styl desc-styl'
                placeholder='Description'
              ></textarea>
              {formik.touched.desc && formik.errors.desc ? (
                <div className='text-red-500 text-xs'>{formik.errors.desc}</div>
              ) : null}
            </div>

            <div className='text-center mt-6'>
              <button className='btn-styl' type='submit'>
                {isEdit === false ? <>Add Treatment</> : <>Update Treatment</>}{' '}
              </button>
            </div>
          </form>
        </div>
        {/* eslint-disable-next-line */}
      </Container>
      <div className='mx-10'>
        {getAllTreatment.isLoading == false && listTreatment != '' ? (
          <ViewTreatment
            data={listTreatment.data}
            deleteService={deleteService}
            formik={formik}
            setisEdit={setisEdit}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AddTreatment;
