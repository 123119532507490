import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { useUploadPost } from "hooks";
import useTranslation from "common/customHooks/translations";
import { localStorageData } from "services/auth/localStorageData";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import Symptoms from "./Symptoms";
import UploadImage from "./UploadImage";
import Information from "./Information";
import MainTitle from "./MainTitle";

export default function AddQuestion() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const location = useLocation();

  console.log("new val=>", location.state.newValue);

  const localtz = moment.tz.guess();
  const { mutateAsync: UploadPost, isLoading } = useUploadPost();

  const userId = localStorage.getItem("userId");

  console.log(userId, "userId---------------");

  const { IsCognito, currentName, currentPic, profile } = useSelector(
    (state) => state.User
  );

  const t = useTranslation();
  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output1");
      output.src = reader.result;
    };

    if (e.target.files[0]) {
      const file = e.target.files[0];

      console.log(file, "file------------");
      reader.readAsDataURL(file);
    }
  };

  const [state, setState] = React.useState({
    disease: [],
    selectDisease: "",
    symptons_id: "",

    meantreatment: [],
    meanselectTreatment: "",

    therapytreatment: [],
    therapyselectTreatment: "",
    means_thearay_id: "",
    file: "",
    name: "",
    address: "",
    lat: "",
    long: "",

    others: "",

    rating: 0,
    own_experience: "",
    dataBaseEnter: "",
    whatHelped: "",
  });
  const getAllDisease = useQuery(
    "diseaseData",
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setState((prevState) => ({ ...prevState, disease: res.data.data }));
      },
    }
  );

  const handleNext = () => {
    if (activeStep === 0) {
      if (state.symptons_id === "") {
        toast.error(
          "Bitte wählen sie ein Thema aus der Liste aus. Eigene Eingaben sind nicht möglich"
        );
        return;
      }
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 1) {
      // console.log(state);
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2) {
      // console.log(state);
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = ["1", "2", "3", "4"];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Symptoms
            state={state}
            setState={setState}
            routevalue={location.state.newValue}
          />
        );
      case 1:
        return <MainTitle state={state} setState={setState} />;
      case 2:
        return <UploadImage state={state} setState={setState} />;
      case 3:
        return <Information state={state} setState={setState} />;

      default:
        return null;
    }
  };

  const onSubmit = async () => {
    console.log(state);
    const formData = new FormData();
    formData.append("questionTitle", state.name);
    formData.append("IsCognito", IsCognito);
    formData.append("pics", state.file);
    ///// formData.append("disease", state.selectDisease);
    formData.append("symptons_id", state.symptons_id);
    formData.append("questionDetail", state.own_experience);
    formData.append("rating", state.rating);
    formData.append("userId", localStorageData("_id"));
    formData.append("localtz", localtz);
    formData.append("whatHelped", "5");

    mutate(formData);

    for (const value of formData.values()) {
      console.log(value);
    }
  };

  const { mutate } = useMutation(
    (callAPIQuestion) =>
      userServices.commonPostService("/post/addQuestions", callAPIQuestion),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Erfahrung gepostet");

        navigate("/account/home");

        // storeLocalData(data.data.data)

        ////navigate("");
      },
    }
  );

  return (
    <div className="min-h-screen">
      <Container maxWidth="lg">
        <div className="my-6 mx-4 py-6">
          <h1 className="text-sm center-styl text-color-btn font-semibold tracking-wider">
            Füge deine Frage hinzu
          </h1>
          <div className="bg-white w-full container-post  p-10 my-6 border rounded border-color-yellow">
            {getStepContent(activeStep)}

            {activeStep === steps.length - 1 ? (
              <div className="center-styl my-10">
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    className="btn2-styl h-12 w-6/12"
                    onClick={() => onSubmit()}
                  >
                    FRAGE STELLEN
                  </button>
                )}
              </div>
            ) : null}

            <div className="center-styl my-6">
              {activeStep !== 0 && (
                <button className="btn2-styl mx-4" onClick={handleBack}>
                  {t.back}
                </button>
              )}

              {activeStep === steps.length - 1 ? (
                ""
              ) : (
                <button className="btn2-styl" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? t.next : t.next}
                </button>
              )}
            </div>

            <div className="flex flex-row center-styl">
              {steps.map((item) =>
                activeStep == item - 1 ? (
                  <div className="bg-color-yellow h-1.5 w-7 rounded mx-1" />
                ) : (
                  <div className="text-sm text-color-dotgrey mx-1">&#8226;</div>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
