import React, { useEffect } from "react";
import ShowMoreText from "react-show-more-text";
import Grid from "@mui/material/Grid";

import Rating from "@mui/material/Rating";
import { ImageEndPoint } from "config/config";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import useTranslation from "common/customHooks/translations";
import { localStorageData } from "services/auth/localStorageData";
import Share from "../Share";

function ThemaDetailCard({ data, affectedStyl }) {
  const [disease, setDisease] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const t = useTranslation();
  // React.useEffect(() => {
  //   getAllDisease.refetch();
  // }, [data]);

  const getAllDisease = useQuery(
    ["newdiseaseData", data],
    () => userServices.commonGetService(`/admin/getallDisease/${data}`),
    {
      ////  refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        /////  getAllDisease.refetch();

        setValue(
          res.data.data[0].avgrating == ""
            ? 0
            : Math.round(res.data.data[0].avgrating[0].avg)
        );

        setDisease(res.data.data[0]);
      },
    }
  );

  return (
    <>
      <article className="bg-white border border-color-btn rounded-lg p-6 my-6 mt-10">
        <div className="flex flex-row ">
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={4} sm={12} xs={12}>
              <div className="center-styl ">
                <img
                  id="output"
                  loading="lazy"
                  src={ImageEndPoint + disease.image}
                  className="w-20 h-20 rounded-full object-cover mx-auto"
                  alt={disease.name}
                />
              </div>
            </Grid>

            <Grid item xl={9} lg={9} md={4} sm={12} xs={12}>
              <div className="mx-4 w-full">
                <h1 className="text-sm text-color-btn truncate">
                  {disease.name}
                </h1>
                <ShowMoreText
                  /* Default options */
                  lines={1}
                  more={t.showmore}
                  less={t.showless}
                  className="text-xs text-color-grey"
                  anchorClass="my-anchor-css-class"
                  expanded={false}
                  // width={380}
                  truncatedEndingComponent={"..."}
                >
                  {disease.desc}
                  <p></p>
                </ShowMoreText>
              </div>
            </Grid>
            <Grid item xl={1} lg={1} md={1}>
              <Share
                url={`https://xpera.de/account/thema/${disease.name}/${disease._id}`}
                title={disease.name}
                disease={disease._id}
              />
            </Grid>
          </Grid>
        </div>

        <div className="border-t border-color-dotgrey my-6" />

        <div className="grid grid-flow-row auto-rows-max"></div>

        <Grid container spacing={2}>
          <Grid item lg={3} md={4} sm={6} xs={6}>
            {getAllDisease.isLoading ? (
              <></>
            ) : (
              <>
                {" "}
                <div className="center-styl">
                  {disease != "" ? (
                    <>
                      {disease.sympaffected != "" ? (
                        <div className="avatars">
                          {disease.sympaffected.map((item, i) =>
                            i < 4 ? (
                              <span className="avatar ">
                                <img
                                  className="object-cover h-8 w-8 "
                                  loading="lazy"
                                  src={ImageEndPoint + item.user[0].pic}
                                  alt="affected user"
                                />
                              </span>
                            ) : null
                          )}
                        </div>
                      ) : (
                        <div className="avatars flex">
                          <span className="avatar">
                            <img
                              className="object-cover h-8 w-8"
                              src={`${
                                affectedStyl != ""
                                  ? `${
                                      ImageEndPoint + localStorageData("pic")
                                    } `
                                  : "https://ui-avatars.com/api/?name=NA"
                              }`}
                              alt="Betroffene"
                            />
                          </span>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
                <div className="text-xs2 mt-2 center-styl">
                  <span className="text-color-btn font-semibold truncate ">
                    {affectedStyl != "" ? (
                      <>
                        {disease.affected == 0 ? (
                          " Du und "
                        ) : (
                          <>Du und {disease.affected - 1}</>
                        )}
                      </>
                    ) : (
                      <> {disease.affected}</>
                    )}
                    &nbsp; Betroffene
                  </span>
                </div>
              </>
            )}
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={6}>
            <div className="center-styl">
              {/* <i className='fas fa-user-plus text-center text-sm text-color-yellow' /> */}
              <img
                src={require("assets/img/behand.png")}
                className=" h-7 w-7 object-cover mx-auto"
                alt="Behandlungsmethoden"
              />
            </div>

            <div className="text-xs2 mt-3 center-styl">
              <span className="text-color-btn font-semibold truncate">
                {disease.treatments} &nbsp; Behandlungsmethoden
              </span>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={6}>
            <div className="center-styl">
              {/* <i className='fas fa-heart text-center text-sm text-color-yellow' /> */}
              <img
                src={require("assets/img/heartfill.png")}
                className=" h-7 w-8 object-cover mx-auto"
                alt="Menschen geholfen"
              />
            </div>

            <div className="text-xs2 mt-3 center-styl">
              <span className="text-color-btn font-semibold truncate">
                {disease.helped}
                &nbsp; Menschen geholfen
              </span>
            </div>
          </Grid>
          <Grid item lg={3} md={4} sm={6} xs={6}>
            <div className="center-styl">
              <Rating
                name="simple-controlled"
                value={value}
                readOnly
                // size='small'
              />
            </div>

            <div className="text-xs2 mt-3 center-styl">
              <span className="text-color-btn font-semibold truncate">
                Gesamte Erfolgsrate
              </span>
            </div>
          </Grid>
        </Grid>
      </article>
    </>
  );
}

export default ThemaDetailCard;
