import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import { localToken, endPoint, ImageEndPoint } from 'config/config';

const columns = [
  { id: 'title', label: 'Title', minWidth: 170 },
  { id: 'text', label: 'Text', minWidth: 170 },
  { id: 'desc', label: 'Description', minWidth: 170 },
  { id: 'priority', label: 'Priority', minWidth: 100 },

  { id: 'options', label: 'options', minWidth: 170 },
];

function ViewAd(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const deleteService = (row) => {
    const r = window.confirm('Do You Really Want to Delete It ?');
    if (r == true) {
      props.deleteService.mutate(row);
    }
  };
  const editService = (row) => {
    props.setisEdit(true);
    props.formik.setFieldValue('title', row.title);
    props.formik.setFieldValue('text', row.text);
    props.formik.setFieldValue('link', row.link);
    props.formik.setFieldValue('surname', row.surname);
    props.formik.setFieldValue('street', row.street);
    props.formik.setFieldValue('postalcode', row.postalcode);
    props.formik.setFieldValue('location', row.location);
    props.formik.setFieldValue('website', row.website);
    props.formik.setFieldValue('points', row.points);
    props.formik.setFieldValue('rank', row.rank);
    props.formik.setFieldValue('priority', row.priority);
    props.formik.setFieldValue('email', row.email);

    props.formik.setFieldValue('desc', row.desc);

    props.formik.setFieldValue('_id', row._id);

    let url = ImageEndPoint + row.pic;
    const toDataURL = (url) =>
      fetch(url)
        .then((response) => response.blob())
        .then(
          (blob) =>
            new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onloadend = () => {
                resolve(reader.result);
                var output = document.getElementById('adimg');
                output.src = reader.result;
              };

              reader.onerror = reject;
              reader.readAsDataURL(blob);
            })
        );

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    }

    toDataURL(url).then(async (dataUrl) => {
      var fileData = await dataURLtoFile(dataUrl, 'this is new_name.jpeg');
      props.formik.setFieldValue('pic', fileData);
    });
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id === 'options') {
                        return (
                          <div className='m-4'>
                            <IconButton
                              aria-label='edit'
                              onClick={() => editService(row)}
                            >
                              <i className='fas fa-edit' />
                            </IconButton>
                            &nbsp;
                            <IconButton
                              aria-label='delete'
                              onClick={() => deleteService(row)}
                            >
                              <i className='fas fa-trash text-sm' />
                            </IconButton>
                          </div>
                        );
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === 'number'
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component='div'
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ViewAd;
