import React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

export default function LandingHeader() {
  let navigate = useNavigate();

  return (
    <nav className='bg-white px-10 py-8'>
      <Container maxWidth='lg'>
        <div className='flex justify-between'>
          <Link to={'/'}>
            <img
              src='https://i.ibb.co/GVWbcK6/Xpera-logo.png'
              className='w-40 h-10 object-contain'
            />
          </Link>
          {/* <a
            // onClick={() => navigate('/professional')}
            className='text-color-btn text-center mt-2  text-[15px] semibold hover:text-color-yellow truncate head-text'
          >
            Sie sind Heilpraktiker, Therapeut oder Coach ?
          </a> */}
        </div>
      </Container>
    </nav>
  );
}
