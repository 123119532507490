const de = {
  hello: "hello",
  welcome: "Willkommen zurück!",
  emailusername: "Email/Benutzername",
  password: "Passwort",
  signdescription:
    "Finde die besten Behandlungsmethoden für Dein Problem oder hilf anderen mit Deinen Erfahrungen!",
  remember: "Angemeldet bleiben",
  forgot: " Passwort vergessen?",
  signbtn: "Anmeldung",
  google: "Weiter mit Google",
  facebook: "Weiter mit Facebook",
  // Signup
  getstart: "Herzlich Willkommen!",
  signupdescription:
    "Registriere Dich mit einer gültigen Email Adresse und erstelle ein Passwort oder melde Dich über Google bzw. Facebook an.",
  signupbtn: "WEITER",
  required: "Eingabe erforderlich",
  mincharacters4: "Mindestens 4 Zeichen erforderlich",
  mincharacters8: "Mindestens 8 Zeichen erforderlich",
  mincharacters2: "Mindestens 2 Zeichen erforderlich",

  // Add information
  addinfo: "Noch ein paar Infos ...",
  addinfo_details:
    "Hinweis: Die Angabe Deiner Postleitzahl ist optional. Wenn du sie angibst, kann Dir die beste Hilfe in Deiner Nähe gezeigt werden.",
  fname: "Vorname",
  lname: "Nachname",
  username: "Benutzername ",
  dob: "Geburtsdatum",
  gender: "Geschlecht",
  male: "männlich",
  female: "weiblich",
  almostdone: "FAST FERTIG...",
  uploadprofile: "Peppe dein Profil auf ...",
  uploadprofile_details:
    "Wir sind eine Gemeinschaft. Wir alle sind Menschen. Zeig Dich :)",
  imgupload: "Profilbild hochladen",
  finished: "GESCHAFFT!",
  // upload post

  uploadpost: "Beitrag erstellen",
  uploadposthead: "FÜGE DEINE ERFAHRUNG HINZU",
  symptoms: "Zu welchem Thema hast Du eine Frage?",
  addsymptoms: "Nenne uns den Namen des Symptoms oder der Krankheit",
  whathelped: "Was hat Dir geholfen?",
  meanspreparation: "MITTEL / PRÄPARAT",
  meanspreparation_placeholder:
    "Was hast du eingenommen oder äußerlich angewendet?",

  therapyactivity: "THERAPIE / AKTIVITÄT",
  therapyactivity_placeholder:
    "Welche Therapie oder Aktivität hat Dir geholfen?",
  professionals: "FACHLEUTE",
  professionals_placeholder:
    "Nenne uns den Namen des Arztes, Coaches, Berater oder der Klinik!",
  address_placeholder: "Adresse",
  others: "ETWAS ANDERES",
  others_placeholder: "Was oder welche Methode hat Dir sonst geholfen?",
  treatmentsuccess: "Wie erfolgreich war Deine Behandlung?",

  ratetreatment:
    "Bitte bewerte den Erfolg Deiner Behandlung: 1  für gering - 5 für exzellent",
  informationaffected: "Hier kannst Du Deine Frage ausführlich stellen.",
  informationaffected_placeholder: "Beschreibe Dein Problem detailliert.",
  back: "ZURÜCK",
  next: "WEITER",
  finish: "BEITRAG ERSTELLEN",

  // sidebar
  home: "Startseite",
  thema: "Thema",
  postsidebar: "Beitrag erstellen",

  // settings
  settings: "Einstellungen",
  profile: "Profil",
  profiledetail: "Aktualisiere Dein Profilbild und Deine Daten hier",
  deleteprofile: "Profil löschen",
  yourphoto: "Dein Profilbild",

  showmore: "mehr anzeigen",
  showless: "weniger anzeigen",

  // card
  post: "POSTEN",
  update: "Änderungen gespeichert!",
  // alert
  loginfirst: "Bitte melde Dich an!",
  maxfilesize: "Dein Bild darf max. 3 MB groß sein!",
  accountcreated: "Deine Registrierung war erfolgreich!",

  lang: "german",
};

export default de;
