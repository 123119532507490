import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import moment from 'moment-timezone';
function Filters({ filterValue, data }) {
  const [newColor, setnewColor] = React.useState(true);
  const [bestColor, setBestColor] = React.useState(false);

  const filterType = (value) => {
    if (value == 'all') {
      filterValue(data);
    } else {
      const val = data.filter((item) => value == item.whatHelped);

      filterValue(val);
    }
    setnewColor(false);
    setBestColor(false);
  };

  const filterNew = (value) => {
    var filterval = data.sort(function (a, b) {
      return moment(b.dateTime) - moment(a.dateTime);
    });

    filterValue(filterval);
    setnewColor(true);
    setBestColor(false);
  };

  const filterBest = (value) => {
    var filterval = data.sort(function (a, b) {
      return b.avgRating - a.avgRating;
    });
    // console.log(filterval);
    filterValue([]);

    filterValue(filterval);
    setnewColor(false);
    setBestColor(true);
  };

  return (
    <>
      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <button
          type='submit'
          className={` my-2 ${newColor ? 'btn-styl' : 'btn2-styl'}`}
          onClick={() => filterNew()}
        >
          Neueste Erfahrungen
        </button>
      </Grid>

      <Grid item xl={3} lg={3} md={4} sm={6} xs={6}>
        <button
          type='submit'
          className={` my-2 ${bestColor ? 'btn-styl' : 'btn2-styl'}`}
          onClick={() => filterBest()}
        >
          Beste Erfahrungen
        </button>
      </Grid>

      <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
        <select
          name='cars'
          id='cars'
          className='filter-thema-styl my-2 '
          onClick={(e) => filterType(e.target.value)}
        >
          <option value='all'>alle Behandlungsarten</option>
          <option value='1'>Mittel / Präparate</option>
          <option value='2'>Therapien / Aktivitäten</option>
          <option value='3'>Fachleute</option>
          <option value='4'>Alles andere</option>
        </select>
      </Grid>
    </>
  );
}

export default Filters;
