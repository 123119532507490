import React from 'react';
import Container from '@mui/material/Container';

function Masthead() {
  return (
    <div className='mb-10'>
      <Container maxWidth='lg'>
        <div>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '48px', lineHeight: '107%' }}>
              Impressum
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Angaben gemäß § 5 TMG
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Jens Jung und Bettina Henk GbR
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Spielberg, 49 37318 Fretterode
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Vertreten durch:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Jens Jung, Bettina Henk
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Kontakt
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Telefon: 036087-90092
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              E-Mail: kontakt@halfmir.de
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Umsatzsteuer-ID
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Umsatzsteuer-Identifikationsnummer gemäß § 27 a
              Umsatzsteuergesetz: DE337154043
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Redaktionell Verantwortlicher
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Jens Jung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              EU-Streitschlichtung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Europäische Kommission stellt eine Plattform zur
              Online-Streitbeilegung (OS) bereit:
              https://ec.europa.eu/consumers/odr.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Unsere E-Mail-Adresse finden Sie oben im Impressum.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Agenturen und Partner:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              7GB App GmbH, APOPIXX Produktabbildungen, 123RF GmbH, Encodersoft
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Rechtliche Hinweise:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf den Seiten der Jens und Bettina Henk GbR (nachfolgend:
              Anbieter)zur Verfügung gestellte Inhalte dürfen – auch
              auszugsweise – ohne vorherige schriftliche Zustimmung nicht
              übernommen, nachgedruckt, in Internet-Dienste oder Netzwerke jeder
              Art eingestellt, auf Datenträger gespeichert und verbreitet oder
              sonst vervielfältigt, verbreitet, öffentlich zugänglich gemacht
              oder sonst verwertet werden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wichtiger Hinweis: Die Produkte und Dienstleistungen des Anbieters
              sind ausschließlich zu Informationszwecken bestimmt und ersetzen
              in keinem Fall eine persönliche Beratung, Untersuchung oder
              Diagnose durch einen approbierten Arzt. Wir weisen darauf hin,
              dass weder Ferndiagnosen noch Therapievorschläge für den
              Einzelfall gestellt werden. Vielmehr dienen die Produkte und
              Dienstleistungen des Verlages der allgemeinen unverbindlichen
              Unterstützung und Information des Nutzers. Die Informationen
              können einen Arztbesuch oder eine Fachinformation in keinem Fall
              ersetzen. Ferner erfolgt keinerlei Beratung, Empfehlung oder
              Aufforderung im Hinblick auf den Kauf und/oder die Anwendung von
              Medikamenten, sonstigen Gesundheitsprodukten, Diagnose- oder
              Therapieverfahren.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Ebenso wenig dürfen die Produkte und Dienstleistungen des
              Anbieters zur Erstellung eigenständiger Diagnosen und/oder einer
              eigenständigen Auswahl und Anwendung bzw. Veränderung oder
              Absetzung von Medikamenten, sonstigen Gesundheitsprodukten oder
              Heilungsverfahren verwendet werden. Dieses bleibt vielmehr den
              Ärzten und/oder Apothekern vorbehalten.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Seit dem 15. Februar 2016 stellt die EU-Kommission eine Plattform
              für außergerichtliche Streitschlichtung bereit. Verbrauchern gibt
              dies die Möglichkeit, Streitigkeiten im Zusammenhang mit Ihrer
              Online-Bestellung/ mit unserem Online-Angebot zunächst ohne die
              Einschaltung eines Gerichts zu klären. Die
              Streitbeilegung-Plattform ist unter dem externen Link
              http://ec.europa.eu/consumers/odr/ erreichbar. In diesem
              Zusammenhang sind wir gesetzlich verpflichtet, Sie auf unsere
              E-Mail-Adresse hinzuweisen. Diese lautet kontakt@halfmir.de. Wir
              sind bemüht, eventuelle Meinungsverschiedenheiten aus unserem
              Vertrag einvernehmlich beizulegen. Darüber hinaus sind wir zu
              einer Teilnahme an einem Schlichtungsverfahren nicht verpflichtet
              und können Ihnen die Teilnahme an einem solchen Verfahren auch
              nicht anbieten.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Haftung für Links
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Unser Angebot enthält Links zu externen Websites Dritter, auf
              deren Inhalte wir keinen Einfluss haben. Deshalb können wir für
              diese fremden Inhalte auch keine Gewähr übernehmen. Für die
              Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter
              oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
              überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der
              Verlinkung nicht erkennbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist
              jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
              zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
              derartige Links innerhalb der gesetzlichen Frist entfernen.
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default Masthead;
