import React from 'react';
import { ImageEndPoint } from 'config/config';
import { useNavigate } from 'react-router-dom';
import useTranslation from 'common/customHooks/translations';

function MyThema({ data, disease }) {
  let navigate = useNavigate();
  const t = useTranslation();

  function navigateToThemaPage(name, id) {
    navigate(`/account/thema/${name.replace(/\s/g, '')}/${id}`);
  }

  return (
    <nav className='md:right-0 md:block md:fixed md:top-0 md:bottom-0 bg-white md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow  flex flex-wrap items-center justify-between relative md:w-64  py-4 px-6'>
      <div className='md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
        <div
          className={
            'md:flex md:flex-col md:items-stretch md:opacity-100 md:relative mt-24 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded '
          }
        >
          {/* Collapse header */}

          {/* Form */}
          <div className='center-styl text-xs text-color-btn font-semibold mb-2'>
            Meine Themen
          </div>
          <ul className='md:flex-col md:min-w-full flex flex-col list-none'>
            {data.map((item, i) => (
              <li className='items-center mt-2' key={i}>
                {' '}
                <a
                  className='flex flex-row my-2 cursor-pointer'
                  onClick={() => {
                    navigateToThemaPage(item.symptons.name, item.symptons._id);
                  }}
                >
                  <img
                    id='output'
                    src={ImageEndPoint + item.symptons.image}
                    className='w-10 h-10 rounded-full object-cover'
                    alt=''
                    loading='lazy'
                  />

                  <div className='text-xs truncate font-semibold text-black m-2'>
                    {item.symptons.name}
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default MyThema;
