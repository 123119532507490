import React from "react";
import Rating from "@mui/material/Rating";
import Grid from "@mui/material/Grid";
import { ImageEndPoint } from "config/config";
import MapView from "components/MapView";
import Collapse from "@mui/material/Collapse";
import WriteComment from "./WriteComment";
import moment from "moment-timezone";
import "moment/locale/de";
import { toast } from "react-toastify";
import ShowMoreText from "react-show-more-text";
import HelpedComments from "./HelpedComments";
import { useNavigate } from "react-router-dom";
import HeartButton from "components/Button/HeartButton";
import HeartButtonOnlyText from "components/Button/HeartButtonOnlyText";
import useTranslation from "common/customHooks/translations";
import { FontAwesomeIcon } from "@fortawesome/fontawesome-free";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import Share from "../../Share";
import { useJsApiLoader } from "@react-google-maps/api";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import EditQuestionOption from "./EditQuestionOption";

// function ApiLoader() {
//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     googleMapsApiKey: "AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8",
//   });

//   return isLoaded;
// }

function PostCard({ data, reftechPost, showAll, setallPost }) {
  moment.locale("de");
  let navigate = useNavigate();
  const t = useTranslation();

  const [value, setValue] = React.useState(2);
  const [expanded, setExpanded] = React.useState(false);

  const [saveEditQuestionnId, setSaveEditQuestionnId] = React.useState("");
  const [saveEditQuestionnDesc, setSaveEditQuestionnDesc] = React.useState("");

  const postEditedQuestion = (index) => {
    console.log(data[index]);
    data[index].questionDetail = saveEditQuestionnDesc;

    EditQuestionApiCall.mutate({
      postId: saveEditQuestionnId,
      questionDetail: saveEditQuestionnDesc,
    });

    setSaveEditQuestionnId("");
    setSaveEditQuestionnDesc("");
  };

  const handleExpandClick = (id) => {
    // setExpanded(!expanded);

    setExpanded({
      ...expanded,
      [id]: !expanded[id],
    });
  };

  const handleeditQuestionClicked = (id) => {
    //// alert(id);

    let requiredData = data.filter((item) => item._id == id);
    setSaveEditQuestionnId(id);
    console.log(requiredData[0].questionDetail);

    setSaveEditQuestionnDesc(requiredData[0].questionDetail);
  };

  const handleDeleteQuestionClicked = (postId) => {
    console.log(data);
    const newData = [...data];

    // Filter out the item with the specified postId
    const updatedData = newData.filter((item) => item._id !== postId);

    // Update the data variable with the modified copy
    /// data = updatedData;

    setallPost(updatedData);

    DeleteQuestionApiCall.mutate({
      postId: postId,
    });

    //// alert(id);
    // let requiredData = data.filter((item) => item._id == id);
    // setSaveEditQuestionnId(id);
    // console.log(requiredData[0].questionDetail);
    // setSaveEditQuestionnDesc(requiredData[0].questionDetail);
  };
  function navigateToNextPage(name, subname, id) {
    navigate(
      `/account/themaDetail/${name.replace(/\s/g, "")}/${subname.replace(
        /\s/g,
        ""
      )}/${id}`
    );
  }

  function sharePost(name, subname, id) {
    navigator.clipboard.writeText(
      `https://xpera.de/account/themaDetail/${name.replace(
        /\s/g,
        ""
      )}/${subname.replace(/\s/g, "")}/${id}`
    );
    toast.success(
      "Die Beitrags-URL wurde in deine Zwischenablage kopiert. Du kannst sie nun überall teilen."
    );
  }
  // let isLoadedWithConsent = false;
  // if (
  //   window.Cookiebot.consent.preferences ||
  //   window.Cookiebot.consent.statistics ||
  //   window.Cookiebot.consent.marketing
  // ) {
  //   isLoadedWithConsent = ApiLoader();
  // }

  const EditQuestionApiCall = useMutation(
    (newQuestion) =>
      userServices.commonPostService("/post/editQuestions", newQuestion),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("Frage erfolgreich bearbeitet");
        // setComment("");
        // props.reftechPost(res.data.data);
        // props.expand(false);
      },
    }
  );

  const DeleteQuestionApiCall = useMutation(
    (deleteQuestion) =>
      userServices.commonPostService("/post/deleteQuestions", deleteQuestion),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("Frage erfolgreich gelöscht");
        // setComment("");
        // props.reftechPost(res.data.data);
        // props.expand(false);
      },
    }
  );

  return (
    <>
      {data.map((item, i) => (
        <Grid item lg={12} md={12} sm={12} xs={12} key={i}>
          <div
            className={`bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border mb-8  ${
              item.whatHelped === "5"
                ? "border-color-yellow"
                : "border-color-btn"
            } `}
          >
            <div className="flex flex-row border-b p-4	border-color-btn ">
              <img
                id="output"
                src={ImageEndPoint + item.symptons.image}
                className="w-12 h-12 rounded-full object-cover"
                alt={item.symptons.name}
              />
              <div className="mx-4">
                <a
                  onClick={() => {
                    let name = item.symptons.name;

                    navigate(
                      `/account/thema/${name.replace(/\s/g, "")}/${
                        item.symptons._id
                      }`
                    );
                  }}
                  className="text-xs font-semibold  text-black cursor-pointer"
                >
                  {item.symptons.name}
                </a>
                <div className="text-xs text-color-btn">
                  Weitere Erfahrung
                  {/* {item.Isincognito ? 'Inkognitomodus' : 'Weitere Erfahrung'} */}
                  <span className="text-xs text-color-yellow ml-4 ">
                    &#8226;
                  </span>
                  <span className="text-[12px] text-color-grey ml-2 ">
                    {moment(item.dateTime).toNow().replace("in", "vor")}
                  </span>
                </div>
              </div>
            </div>

            {item.whatHelped === "1" || item.whatHelped === "2" ? (
              <>
                <div className="p-4">
                  <div className="flex flex-row  justify-between">
                    <h1 className="text-base font-semibold text-color-btn my-2">
                      <a
                        onClick={() => {
                          navigateToNextPage(
                            item.symptons.name,
                            item.means_thearay[0].name,
                            item._id
                          );
                        }}
                        className="text-base font-semibold text-black cursor-pointer"
                      >
                        {item.means_thearay[0].name}
                      </a>
                    </h1>
                    <Share
                      url={`https://xpera.de/account/themaDetail/${item.symptons.name.replace(
                        /\s/g,
                        ""
                      )}/${item.means_thearay[0].name.replace(/\s/g, "")}/${
                        item._id
                      }`}
                      postId={item._id}
                      title={item.means_thearay[0].name}
                    />
                  </div>
                  <a
                    onClick={() => {
                      navigateToNextPage(
                        item.symptons.name,
                        item.means_thearay[0].name,
                        item._id
                      );
                    }}
                    className="cursor-pointer"
                  >
                    <img
                      className="w-full h-full mb-4 rounded-lg object-cover overflow-auto  "
                      src={ImageEndPoint + item.means_thearay[0].image}
                      alt={item.means_thearay[0].name}
                    />
                  </a>

                  <ShowMoreText
                    /* Default options */
                    lines={2}
                    more={t.showmore}
                    less={t.showless}
                    className="text-xs"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    // width={280}
                    truncatedEndingComponent={"... "}
                  >
                    <p className="text-xs text-black mt-2">
                      {item.means_thearay[0].desc}
                    </p>
                  </ShowMoreText>
                </div>
              </>
            ) : item.whatHelped === "3" ? (
              <>
                <div className="p-4">
                  <div className="flex flex-row  justify-between">
                    <div className="text-base font-semibold text-color-btn my-2">
                      <a
                        onClick={() => {
                          navigateToNextPage(
                            item.symptons.name,
                            item.professional_other.name,
                            item._id
                          );
                        }}
                        className="text-base font-semibold text-black cursor-pointer"
                      >
                        {item.professional_other.name}
                      </a>
                    </div>
                    <Share
                      url={`https://xpera.de/account/themaDetail/${item.symptons.name.replace(
                        /\s/g,
                        ""
                      )}/${item.professional_other.name.replace(/\s/g, "")}/${
                        item._id
                      }`}
                      postId={item._id}
                      title={item.professional_other.name}
                    />
                  </div>

                  <MapView
                    lat={item.professional_other.lat}
                    lng={item.professional_other.long}
                  />
                  <div className="text-xs text-black mt-6">
                    {item.professional_other.address}
                  </div>
                </div>
              </>
            ) : item.whatHelped === "4" ? (
              <>
                <div className="p-4">
                  <div className="flex flex-row  justify-between">
                    <div className="text-base font-semibold text-color-btn my-2">
                      <a
                        onClick={() => {
                          navigateToNextPage(
                            item.symptons.name,
                            item.professional_other.name,
                            item._id
                          );
                        }}
                        className="text-base font-semibold text-black cursor-pointer"
                      >
                        {item.professional_other.name}
                      </a>
                    </div>
                    <Share
                      url={`https://xpera.de/account/themaDetail/${item.symptons.name.replace(
                        /\s/g,
                        ""
                      )}/${item.professional_other.name.replace(/\s/g, "")}/${
                        item._id
                      }`}
                      postId={item._id}
                      title={item.professional_other.name}
                    />
                  </div>

                  <div className="h-6" />
                  <div className="text-base text-black my-4">
                    {/* {item.professional_other.address} */}
                  </div>
                </div>
              </>
            ) : item.whatHelped === "5" ? (
              <>
                <div className="p-4">
                  <div className="flex flex-row  justify-between">
                    <h1 className="text-base font-semibold text-color-btn my-2">
                      <a
                        onClick={() => {
                          navigateToNextPage(
                            item.symptons.name,
                            item.questionTitle,
                            item._id
                          );
                        }}
                        className="text-base font-semibold text-black cursor-pointer"
                      >
                        {item.questionTitle}
                      </a>
                    </h1>
                    <Share
                      url={`https://xpera.de/account/themaDetail/${item.symptons.name.replace(
                        /\s/g,
                        ""
                      )}/${item.questionTitle.replace(/\s/g, "")}/${item._id}`}
                      postId={item._id}
                      title={item.questionTitle}
                    />
                  </div>
                  <a
                    onClick={() => {
                      navigateToNextPage(
                        item.symptons.name,
                        item.questionTitle,
                        item._id
                      );
                    }}
                    className="cursor-pointer"
                  >
                    <img
                      className="w-full h-full mb-4 rounded-lg object-cover overflow-auto  "
                      src={ImageEndPoint + item.questionPic}
                    />
                  </a>
                  <ShowMoreText
                    /* Default options */
                    lines={2}
                    more={t.showmore}
                    less={t.showless}
                    className="text-xs"
                    anchorClass="my-anchor-css-class"
                    expanded={false}
                    // width={280}
                    truncatedEndingComponent={"... "}
                  >
                    <p className="text-xs text-black mt-2">
                      {item.questionDetail}
                    </p>
                  </ShowMoreText>

                  {saveEditQuestionnId == item._id ? (
                    <div className="py-2 px-4">
                      <div>
                        <textarea
                          type="text"
                          rows="3"
                          cols="10"
                          name="desc"
                          id="desc"
                          value={saveEditQuestionnDesc}
                          onChange={(e) =>
                            setSaveEditQuestionnDesc(e.target.value)
                          }
                          className="input-styl desc-styl"
                          placeholder="Erzähle mehr über Deine gemachten Erfahrungen und gib damit anderen Menschen Perspektiven und Hilfe. Dankeschön!"
                        ></textarea>
                      </div>

                      <div className="float-right my-4">
                        <button
                          type="submit"
                          onClick={() => postEditedQuestion(i)}
                          ///props.isEdit ? PostEditComment() : PostNewComment()

                          className="btn2-styl"
                        >
                          Frage posten
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            ) : null}

            {item.hasOwnProperty("link") ? (
              <div className="mb-3 mx-5">
                <a
                  type="button"
                  className="bg-[#fffff] text-[#9A9A9A] border border-[#9A9A9A] text-xs2  font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg  w-full ease-linear transition-all duration-150 center-styl cursor-pointer"
                  target="_blank"
                  href={item.link}
                  // onClick={() => item.link}
                >
                  <span className="flex text-center">{item.linkName} </span>
                </a>
              </div>
            ) : null}

            <Grid container>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                <div className="pl-4">
                  <HeartButtonOnlyText
                    handleExpandClick={handleExpandClick}
                    index={i}
                    item={item}
                  />
                </div>
              </Grid>

              <Grid xl={3} lg={3} md={3} sm={3} xs={3}>
                <div className="flex float-right  ">
                  <Rating
                    name="read-only"
                    value={
                      item.expereince != ""
                        ? Math.round(
                            item.expereince.reduce(
                              (sum, { rating }) => sum + rating,
                              0
                            ) / item.expereince.length
                          )
                        : 0
                    }
                    readOnly
                    size="small"
                  />
                </div>
              </Grid>
              <Grid xl={1} lg={1} md={1} sm={1} xs={1}>
                <EditQuestionOption
                  editQuestionClicked={handleeditQuestionClicked}
                  handleDeleteQuestionClicked={handleDeleteQuestionClicked}
                  item={item}
                />
              </Grid>
            </Grid>

            <div className="border-t border-color-btn border-opacity-50 w-full my-1 mb-4" />
            <Grid container>
              <Grid item xl={9} lg={9} md={9} sm={7} xs={7}>
                <div className="pl-4">
                  <HeartButton
                    handleExpandClick={handleExpandClick}
                    index={i}
                    item={item}
                  />
                </div>
              </Grid>

              {/* <Grid xl={3} lg={3} md={3} sm={5} item xs={5}>
                <div className="flex float-right xl:mr-10 lg:lr-10 md:lr-6 sm:mr-1 xs:mr-1">
                  <Rating
                    name="read-only"
                    value={
                      item.expereince != ""
                        ? Math.round(
                            item.expereince.reduce(
                              (sum, { rating }) => sum + rating,
                              0
                            ) / item.expereince.length
                          )
                        : 0
                    }
                    readOnly
                    size="small"
                  />
                </div>
              </Grid> */}
            </Grid>

            <Collapse in={expanded[i]} timeout="auto" unmountOnExit>
              <WriteComment
                reftechPost={reftechPost}
                expand={setExpanded}
                item={item}
                isEdit={false}
              />
            </Collapse>
            <HelpedComments
              reftechPost={reftechPost}
              item={item}
              showAll={showAll}
            />
          </div>
        </Grid>
      ))}
    </>
  );
}

export default PostCard;
