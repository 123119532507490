import React from 'react';
import Container from '@mui/material/Container';

function TermsConditions() {
  return (
    <div className='mb-10'>
      <Container maxWidth='lg'>
        <p
          style={{
            marginTop: '15.0pt',
            marginRight: '0in',
            marginBottom: '12.0pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ fontSize: '48px', color: 'black' }}>AGB -&nbsp;</span>
          <span style={{ fontSize: '48px', color: 'black' }}>
            Allgemeine Geschäftsbedingungen
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Letzte Aktualisierung: 01.05.22
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span
            style={{ fontSize: '15px', color: '#333333', background: 'white' }}
          >
            Die Informationen auf dieser Seite gelten für folgende Domains der
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ color: 'black', background: 'white' }}>Xpera.de</span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ color: 'black', background: 'white' }}>
            Gesund-durch-Erfahrung.de
          </span>
        </p>
        <p
          style={{
            margin: '0in',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
          }}
        >
          <span style={{ color: 'black', background: 'white' }}>
            Halfmir.de
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            1. &nbsp;Geltungsbereich
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: 'black' }}>
            Die Gesundheitsportale Xpera.de, Gesund-durch-Erfahrung.de und
            Halfmir.de (im Folgenden: Angebot) werden durch die Jens Jung und
            Bettina Henk GbR
          </span>
          <span style={{ fontSize: '14px', color: 'black' }}>
            &nbsp;(im Folgenden: Anbieter)&nbsp;
          </span>
          <span style={{ fontSize: '14px', color: 'black' }}>
            betrieben. Für die Nutzung der Gesundheitsportale gelten im
            Verhältnis zwischen Nutzer und Anbieter die folgenden Bedingungen.
            Mit der Nutzung&nbsp;
          </span>
          <span style={{ fontSize: '14px', color: 'black' }}>unseres</span>
          <span style={{ fontSize: '14px', color: 'black' }}>
            &nbsp;Angebotes erkennt der Nutzer diese allgemeinen
            Geschäftsbedingungen an.
          </span>
        </p>
        <h3
          style={{
            marginTop: '2.0pt',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: '107%',
            fontSize: '16px',
            fontFamily: '"Calibri Light",sans-serif',
            color: '#1F4D78',
            fontWeight: 'normal',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              lineHeight: '107%',
              fontFamily: '"Calibri",sans-serif',
              color: 'windowtext',
            }}
          >
            Abweichende Bedingungen, die mit diesen Allgemeinen
            Geschäftsbedingungen in Widerspruch stehen, finden keine Anwendung.
          </span>
        </h3>
        <h3
          style={{
            marginTop: '2.0pt',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: '107%',
            fontSize: '16px',
            fontFamily: '"Calibri Light",sans-serif',
            color: '#1F4D78',
            fontWeight: 'normal',
          }}
        >
          <span
            style={{
              fontSize: '14px',
              lineHeight: '107%',
              fontFamily: '"Calibri",sans-serif',
              color: 'windowtext',
            }}
          >
            Die Nutzung unseres Angebotes&nbsp;ist ausschließlich Personen
            gestattet, die mindestens 18 Jahre alt sind.
          </span>
        </h3>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            2. &nbsp;Inhalte unseres Angebotes
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Unser<em>&nbsp;</em>Angebot bietet dem Nutzer Inhalte zum Thema
            Gesundheit. Diese Inhalte werden von Nutzern unseres Angebotes in
            Form von Erfahrungsberichten für andere Nutzer zur Verfügung
            gestellt. Der Nutzer kann diese Informationen nach Inhalt und
            Bewertung filtern. Die Nutzer können sich mittels einer
            Kommentarfunktion gegenseitig austauschen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Alle Inhalte auf dem Portal sind urheberrechtlich geschützt.
            Jegliche Nutzung oder Verwertung ohne schriftliche Genehmigung der
            Anbieterin ist nicht gestattet.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter ist frei in der Gestaltung der Inhalte und jederzeit
            berechtigt, die Angebote auf dem Portal zu ändern, einzuschränken,
            zu erweitern oder ganz einzustellen. Der Anbieter ist bei der
            Erbringung seiner Leistung frei, diese auch durch Dritte nach
            eigener Wahl zu erbringen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Unser&nbsp;Angebot&nbsp;stellt weder eine ärztliche Beratung dar,
            noch ist es dazu bestimmt oder geeignet, eine individuelle ärztliche
            Betreuung zu ergänzen oder zu ersetzen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            &nbsp;3. &nbsp;Verbot individueller Beratung sowie der
            Fernbehandlung
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die Diagnostizierung konkreter Erkrankungen sowie deren Behandlung
            ohne eine vorausgehende Untersuchung im Einzelfall sind gesetzlich
            verboten.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die Ihnen durch unser&nbsp;Angebot<em>&nbsp;</em>zugänglich
            gemachten Inhalte sind weder dazu geeignet noch dazu bestimmt, die
            professionelle Leistung eines Arztes oder Angehöriger sonstiger
            Heilberufe vorwegzunehmen oder ganz oder teilweise an deren Stelle
            zu treten. Auf keinen Fall ersetzt die Nutzung unseres Angebotes
            <em>&nbsp;</em>die individuelle Diagnose und/oder Behandlung durch
            den Haus- bzw. Facharzt! Bei konkreten Fragen wenden Sie sich bitte
            an einen niedergelassenen Arzt oder ein Krankenhaus Ihrer Wahl.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            &nbsp;4. &nbsp;Sicherheitshinweis
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            DIE ERKRANKUNGEN, ZU DENEN IHNEN In UNSEREM ANGEBOT<em>&nbsp;</em>
            INHALTE ZUGÄNGLICH GEMACHT WERDEN, SIND ERNSTE, ZUM TEIL
            LEBENSGEFÄHRLICH VERLAUFENDE ERKRANKUNGEN, DIE EINE SOFORTIGE
            ÄRZTLICHE BETREUUNG UNERLÄSSLICH MACHEN! SOLLTEN IHNEN ANHALTSPUNKTE
            FÜR EINE ERKRANKUNG BEKANNT SEIN ODER IM ZUGE DER NUTZUNG DES
            ANGEBOTES<em>&nbsp;</em>BEKANNT WERDEN, SUCHEN SIE BITTE UMGEHEND
            DEN ARZT IHRES VERTRAUENS AUF! UNTERLASSEN SIE IN JEDEM FALL EINE
            SELBSTMEDIKATION ODER EIGENMÄCHTIGE AUFNAHME SONSTIGER
            BEHANDLUNGSMETHODEN OHNE DIE VORHERIGE EINHOLUNG ÄRZTLICHEN RATES!
            NUTZER, DIE DAS ANGEBOT<em>&nbsp;</em>ODER DIE HIERÜBER VERMITTELTEN
            INHALTE ENTGEGEN DIESER ALLGEMEINEN NUTZUNGSBEDINGUNGEN ZUR
            EIGENMÄCHTIGEN DIAGNOSE UND/ODER BEHANDLUNG VERWENDEN, HANDELN AUF
            EIGENE GEFAHR!&nbsp;
          </span>
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            5. &nbsp;Zugang zu unserem Angebot
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die Inhalte unseres Angebotes<em>&nbsp;</em>richten sich an
            medizinisch und gesundheitlich interessierte Personen und sind
            kostenlos. Um Erfahrungsberichte verfassen und mit Inhalten und
            anderen Nutzer interagieren zu können ist eine vorherige
            Registrierung nötig.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die Registrierung erfolgt unter Angabe personenbezogener Daten und
            setzt die Anerkennung dieser Nutzungsbedingungen voraus. Jeder
            Nutzer darf sich nur einmal registrieren. Der Nutzer ist
            verpflichtet, bei der Registrierung ausschließlich wahrheitsgemäße
            und vollständige Angaben zu machen und diese im Falle von späteren
            Änderungen unverzüglich zu aktualisieren.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer darf seinen Zugang nicht Dritten zur Nutzung überlassen.
            Der Nutzer ist verpflichtet, seine Zugangsdaten geheim zu halten und
            vor dem Zugriff Dritter zu schützen. Der Nutzer haftet für alle
            Folgen der Drittnutzung, sofern ein Missbrauch von Zugangsdaten von
            Ihnen zu vertreten ist. Sollte dem Nutzer die missbräuchliche
            Nutzung seines Zugangs bekannt werden, hat er dies dem Anbieter
            unverzüglich mitzuteilen und sein Passwort unverzüglich zu ändern.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter behält sich vor, die Registrierung einzelner Nutzer
            ohne Angabe von Gründen abzulehnen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            6. &nbsp;Leistungen des Anbieters
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter stellt innerhalb des Angebotes ein Informationsangebot
            bereit. Darüber hinaus gestattet der Anbieter dem Nutzer, im Rahmen
            dieser Nutzungsbedingungen Inhalte zu veröffentlichen.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter ist bemüht, seinen Dienst verfügbar zu halten. Es
            besteht kein Anspruch des Nutzers auf eine ständige Verfügbarkeit
            des Dienstes.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter ist nicht verpflichtet, die Nutzerinhalte auf ihre
            rechtliche Zulässigkeit und auf eine mögliche Verletzung von Rechten
            Dritter hinzu überprüfen. Der Anbieter übernimmt keine Gewähr für
            die Richtigkeit, Vollständigkeit, Verlässlichkeit, Aktualität und
            Brauchbarkeit der bereit gestellten Inhalte.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            7. Pflichten des Nutzers
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer verpflichtet sich, keine rechts- oder sittenwidrigen
            Beiträge zu veröffentlichen. Der Nutzer verpflichtet sich
            insbesondere dazu, keine Inhalte zu veröffentlichen, deren
            Veröffentlichung einen Straftatbestand erfüllt oder eine
            Ordnungswidrigkeit darstellt, die das Recht am geistigen Eigentum
            Dritter verletzen, die gegen Wettbewerbsrecht verstoßen, die
            rassistischen, diskriminierenden oder pornographischen Inhalt haben
            oder die Werbung enthalten.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Jeder Verstoß des Nutzers gegen die Verpflichtung aus dem vorherigen
            Absatz, berechtigt den Anbieter, die betroffenen Beiträge abzuändern
            oder zu löschen und den Zugang des Nutzers zu sperren. Der Nutzer
            ist verpflichtet, dem Anbieter den durch die Pflichtverletzung
            entstandenen Schaden zu ersetzen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer ist allein und umfassend für die von ihm auf unserem
            Angebot eingestellten Inhalte verantwortlich und verpflichtet, dem
            Anbieter sämtlichen Schaden, einschließlich aller Aufwendungen, zu
            ersetzen, welche diesem aufgrund schuldhafter Verstöße gegen diese
            Nutzungsbedingungen entstehen. Der Nutzer stellt der Anbieterin
            insoweit ausdrücklich auf erstes Anfordern auch von allen Ansprüchen
            Dritter und den Kosten der damit verbundenen Rechtsverfolgung frei.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            8. Inhalte des Nutzers/ Rechteinräumung
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer ist für den Inhalt seiner Äußerungen in unserem Angebot
            verantwortlich. Wir distanzieren uns hiermit ausdrücklich von den
            Inhalten der Nutzer in unserem Angebot und übernehmen keine Haftung
            für die Inhalte der Nutzer. Darüber hinaus kontrollieren wir nicht
            die von den Nutzern in unserem Angebot ausgetauschten Inhalte.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer sichert zu, dass er nur solche Texte, Bilder und Videos
            veröffentlicht, bzw. hochlädt oder übermittelt, für die er über die
            dazu erforderlichen Urheber- sowie sonstigen Rechte verfügt, dass
            abgebildete Personen, die nicht nur Beiwerk zu einer Örtlichkeit
            oder Teil von abgebildeten Versammlungen, Aufzügen oder ähnlichen
            Vorgängen sind, mit der Veröffentlichung einverstanden sind (bei
            Personen unter 18 Jahren gilt das Einverständnis des
            Erziehungsberechtigten) und dass die Inhalte nicht sonstige Rechte
            verletzten oder sonst wie rechtswidrig sind.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer räumt uns mit dem Einstellen bzw. Hochladen von Inhalten
            ein einfaches, räumlich und zeitlich unbeschränktes Recht ein, die
            Inhalte in unserem Angebot vervielfältigen und öffentlich zugänglich
            machen zu dürfen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            9. Technische Anforderungen
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Abruf der Inhalte setzt zudem eine aktive Internetverbindung
            voraus. Verwenden Sie das unser<em>&nbsp;</em>Angebot&nbsp;über ein
            mobiles Netzwerk, können Ihnen dabei zusätzliche Kosten entstehen,
            wie etwa Verbindungsentgelte und ggf. Roaminggebühren. Ob und welche
            Kosten anfallen, richtet sich nach den Vertragsbedingungen Ihres
            Anbieters. Die Entstehung dieser Kosten liegt nicht in unserem
            Einflussbereich. Sie sind verantwortlich für diese zusätzlichen
            Kosten.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            10. Nutzung, Nutzungsrechte an Inhalten
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die Inhalte unseres Angebotes<em>&nbsp;</em>sind urheber- oder
            leistungsschutzrechtlich geschützt. Sie dürfen die Inhalte
            ausschließlich zu eigenen Zwecken verwenden.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Weitere Rechte werden Ihnen an den Inhalten nicht eingeräumt. Sie
            sind insbesondere nicht berechtigt, Inhalte jenseits der
            gesetzlichen oder durch diese Ziffer statuierten
            Erlaubnistatbestände zu vervielfältigen, zu verbreiten, vorzuführen,
            öffentlich zugänglich zu machen oder zu senden und/oder
            Bearbeitungen der Inhalte zu veröffentlichen oder zu verwerten. Dies
            betrifft vor allem das Einräumen von Zugängen für Dritte auf die
            Inhalte, das Einspeisen der Inhalte in ein Netzwerk (Internet,
            Intranet etc.) sowie die gewerbliche oder im Rahmen einer
            selbstständigen Tätigkeit erfolgende Informationsvermittlung
            gegenüber Dritten.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            &nbsp;11. Datenschutz
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Bitte beachten Sie zum Datenschutz unsere&nbsp;
          </span>
          <span style={{ color: 'black' }}>
            <a target='_blank' href='/privacypolicy'>
              <span style={{ fontSize: '14px', color: '#0154A4' }}>
                Datenschutzerklärung
              </span>
            </a>
          </span>
          <span style={{ fontSize: '14px', color: '#333333' }}>.</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            12. Haftungsausschluss
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Ansprüche des Nutzers auf Schadensersatz sind ausgeschlossen.
            Hiervon ausgenommen sind Schadensersatzansprüche des Nutzers aus der
            Verletzung wesentlicher Vertragspflichten (sog. „Kardinalpflichten“)
            sowie die Haftung für sonstige Schäden, die auf einer vorsätzlichen
            oder grob fahrlässigen Pflichtverletzung des Anbieters, ihrer
            gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche
            Vertragspflichten sind solche, deren Erfüllung zur Erreichung des
            Ziels des Vertrags notwendig ist. Bei der Verletzung wesentlicher
            Vertragspflichten haftet die Anbieter nur auf den vertragstypischen,
            vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht
            wurde, es sei denn, es handelt sich um Schadensersatzansprüche des
            Nutzers aus einer Verletzung des Lebens, des Körpers oder der
            Gesundheit. Die Einschränkungen der vorgenannten Einschränkungen
            gelten auch zugunsten der gesetzlichen Vertreter und
            Erfüllungsgehilfen der Anbieterin, wenn Ansprüche direkt gegen diese
            geltend gemacht werden.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Die von unserem Angebot bereitgestellten Inhalte sind ausschließlich
            zu Informationszwecken bestimmt und ersetzen in keinem Falle eine
            persönliche Beratung, Untersuchung oder Diagnose durch einen
            approbierten Arzt. Wir weisen darauf hin, dass weder Ferndiagnosen
            noch Therapievorschläge für den Einzelfall gestellt werden. Vielmehr
            dienen die hier erteilten Inhalte der allgemein unverbindlichen
            Unterstützung und Information des Ratsuchenden. Die Informationen
            dienen also ausschließlich zum Informationsaustausch und des Dialogs
            zwischen den Nutzern, sie können den ärztlichen Besuch in keinem
            Falle ersetzen. Es wird ferner keinerlei Beratung, Empfehlung oder
            Aufforderung im Hinblick auf den Kauf und/oder die Anwendung von
            Medikamenten, sonstigen Gesundheitsprodukten, Diagnose- oder
            Therapieverfahren geleistet.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Eindringlich zu warnen ist vor einer Selbstmedikation. Die von
            Nutzern unseres Angebotes bereit gestellten Auskünfte und Inhalte
            können und dürfen nicht zur Erstellung eigenständiger Diagnosen
            und/oder einer eigenständigen Auswahl und Anwendung bzw. Veränderung
            oder Absetzung von Medikamenten, sonstigen Gesundheitsprodukten oder
            Heilungsverfahren verwendet werden. Dieses bleibt vielmehr Ärzten
            und/der Apothekern vorbehalten.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Da der Inhalt unseres Angebotes lediglich der allgemeinen
            Information dient, ist die Geltendmachung des Ersatzes von Schäden
            oder Unannehmlichkeiten, die durch den Gebrauch oder Missbrauch
            dieser Informationen, direkt oder indirekt, mittelbar oder
            unmittelbar, entstehen, ausgeschlossen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Anbieter übernimmt des Weiteren keine Gewährleistung für die
            Vollständigkeit, Richtigkeit, Genauigkeit und Aktualität der Inhalte
            auf diesen Webseiten. Mit dem Einstellen eines Beitrags räumt der
            Verfasser dem Anbieter die Nutzungsrechte daran ein. Wir behalten
            uns vor, Beiträge in Einzelfällen zu löschen, insbesondere wenn
            diese gewerbliche Inhalte haben, beleidigend bzw. in der Wortwahl
            inakzeptabel sind oder allgemein gegen die Menschenwürde bzw.
            geltendes Recht verstoßen. Dies gilt insbesondere für Beiträge, die
            Gewalttätigkeiten, den sexuellen Missbrauch von Kindern oder
            sexuelle Handlungen von Menschen mit Tieren zum Gegenstand haben (§
            184 StGB).
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die
            Inhalte externer Links. Für die Inhalte externer Links sind
            ausschließlich deren Betreiber verantwortlich. Gleiches gilt für
            alle Verbindungen, auf die diese Webseite direkt oder indirekt
            verweist. Die Anbieter sind für den Inhalt einer Seite, die mit
            einem solchen Link erreicht wird, nicht verantwortlich. Die Anbieter
            übernehmen ferner keine Haftung für unverlangt eingesandte
            Manuskripte, Fotos, Illustrationen. Namentlich gekennzeichnete
            Beiträge geben die Meinung des Urhebers, nicht aber (unbedingt) die
            Meinung des Anbieters wieder. Der Anbieter behält sich das Recht
            vor, ohne vorherige Ankündigung Änderungen oder Ergänzungen der
            bereit gestellten Informationen vorzunehmen.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            13. Haftung des Nutzers und Freistellung
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Der Nutzer stellt uns von sämtlichen Ansprüchen frei, die Dritte
            gegenüber und aufgrund einer Verletzung ihrer Rechte durch vom
            Nutzer im Rahmen der Nutzung vom Angebot vorgenommene Handlungen
            erheben. Der Nutzer übernimmt hierbei die Kosten einer notwendigen
            Rechtsverteidigung von uns, einschließlich sämtlicher Gerichtskosten
            und angemessener Anwaltskosten. Dies gilt dann nicht, wenn die
            Rechtsverletzung nicht auf ein schuldhaftes Verhalten des Nutzers
            zurückzuführen ist.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            14. Kommunikation&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Für Fragen wenden Sie sich einfach an unseren Kundenservice. Senden
            Sie uns eine E-Mail an kontakt@halfmir.de oder Sie schreiben uns
            einfach an:
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Jens Jung und Bettina Henk GbR
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Spielberg 49
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            37318 Fretterode
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '0in',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            15. Änderungen von Allgemeinen Geschäftsbedingungen und
            Leistungsbeschreibung&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Änderungen dieser Allgemeinen Geschäftsbedingungen können nur gemäß
            Absatz 2 vereinbart werden, soweit ein sachlicher Grund dies
            erfordert und durch die Änderung das ursprüngliche Verhältnis von
            Leistung und Gegenleistung gewahrt bleibt, sodass die Änderung für
            Sie zumutbar ist.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Änderungen gemäß Absatz 1 werden Ihnen mindestens 6 Wochen vor Ihrem
            geplanten Wirksamwerden in Textform mitgeteilt. Widersprechen Sie
            der beabsichtigten Änderung nicht innerhalb von 6 Wochen nach Zugang
            der Änderungsmitteilung in Textform, so stellt dies Ihre Zustimmung
            zu der Änderung dar und diese wird mit Ablauf der Widerspruchsfrist
            wirksam. Hierauf werden wir Sie in der Änderungsmitteilung gesondert
            hinweisen. Widersprechen Sie der beabsichtigten Änderung
            fristgerecht in Textform, läuft der Vertrag zu den bisherigen
            Bedingungen weiter.
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            16. Schlussbestimmungen
          </span>
        </p>
        <p
          style={{
            marginTop: '0in',
            marginRight: '0in',
            marginBottom: '11.25pt',
            marginLeft: '0in',
            lineHeight: 'normal',
            fontSize: '15px',
            fontFamily: '"Calibri",sans-serif',
            background: 'white',
          }}
        >
          <span style={{ fontSize: '14px', color: '#333333' }}>
            Wir sind berechtigt, ihre vertraglichen Rechte und Pflichten an
            einen Dritten zu übertragen. Eine Abtretung der vertraglichen Rechte
            des Nutzers ist ausgeschlossen. Die vertraglichen Rechte und
            Pflichten aus dem geschlossenen Vertrag des Nutzers können nur dann
            einem Dritten übertragen werden, wenn dem durch uns schriftlich
            zugestimmt wird.
          </span>
        </p>
      </Container>
    </div>
  );
}

export default TermsConditions;
