import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';

import Sidebar from 'components/Sidebar/Sidebar';
import AuthNavbar from 'components/Navbars/AdminNavbar.js';
import Home from '../pages/Home';
import UploadPost from 'pages/UploadPost';
import Thema from '../pages/Thema';

import ThemaDetail from 'pages/ThemaDetail';

import IsLogin from './IsLogin';
import Settings from 'pages/Settings';
import Profile from 'pages/Profile';
import NotFound from 'pages/StaticPages/NotFound';
import AddQuestion from 'pages/UploadPost/AddQuestion'

function Account() {
  const [themaName, setThemaname] = React.useState('');

  const themaData = (value) => {
    setThemaname(value);
  };

  return (
    <div className='bg-color-lightgrey '>
      <Sidebar themaName={themaName} setThemaname={setThemaname} />
      <div className='relative md:ml-64 bg-blueGray-100'>
        <AuthNavbar />
        <main>
          <section className=' w-full h-full lg:mt-20 md:mt-20 sm:mt-1 xs:mt-1 bg-color-lightgrey  '>
            <Routes>
              <Route>
                <Route element={<IsLogin />}>
                  <Route path='post' element={<UploadPost />} />
                  <Route path='addquestion' element={<AddQuestion />} />
                </Route>

                <Route path='home' element={<Home />} />
                <Route
                  path='/'
                  element={<Navigate to='/account/home' replace />}
                />
                <Route
                  path='thema/:name/:id'
                  element={<Thema themaData={themaData} />}
                />

                <Route
                  path='themaDetail/:name/:subname/:id'
                  element={<ThemaDetail />}
                />
              </Route>
              <Route path='/:name' element={<Profile />} />
              <Route path='settings' element={<Settings />} />
              <Route path='*' element={<NotFound />} />
            </Routes>
          </section>
        </main>
      </div>
    </div>
  )
}

export default Account;
