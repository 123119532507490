import React, { useEffect, useState } from 'react';
import history from '../history';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Main from './Main';
import Admin from './Admin';
import Auth from './Auth';

import RequireAuth from './RequireAuth';
import Account from './Account';
import { account } from 'routes/config';
import { useNavigate } from 'react-router-dom';
import { store } from 'reactStore/MainStore';
import { useDispatch, useSelector } from 'react-redux';

import { EMPTYSOTRE } from 'reactStore/actions/Actions';

function Root() {
  const [isConsent, setIsConsent] = useState(false);

  const { IsCognito, currentName, currentPic, profile, rememberMe } =
    useSelector((state) => state.User);
  const dispatch = useDispatch();

  function callbackFun() {
    if (
      window.Cookiebot.consent.preferences ||
      window.Cookiebot.consent.statistics ||
      window.Cookiebot.consent.marketing
    ) {
      // <!-- Global site tag (gtag.js) - Google Analytics -->
      let scriptEle = document.createElement("script");
      scriptEle.setAttribute(
        "src",
        "https://www.googletagmanager.com/gtag/js?id=G-R6T2YFJF3V"
      );
      scriptEle.setAttribute("type", "text/javascript");
      scriptEle.setAttribute("async", true);
      document.body.appendChild(scriptEle);

      function gtag() {
        window.dataLayer.push(arguments);
      }

      gtag("js", new Date());

      gtag("config", "G-R6T2YFJF3V");

      setIsConsent(true);
      
    }
  }

  window.addEventListener(
    "CookiebotOnAccept",
    function (e) {
      callbackFun();
    },
    false
  );

  window.addEventListener(
    "CookiebotOnLoad",
    function (e) {
      callbackFun();
    },
    false
  );

  return (
    <Router history={history}>
      <Routes>
        <Route path='/*' element={<Main />} />
        <Route path='auth/*' element={<Auth />} />

        <Route element={<RequireAuth />}>
          <Route path='admin/*' element={<Admin />} />
        </Route>

        <Route path={`${account}/*`} element={<Account />} />
      </Routes>
    </Router>
  );
}

export default Root;
