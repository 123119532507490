import React, { useEffect } from 'react';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import { useParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import PostCard from 'components/Cards/PostCard';
import Grid from '@mui/material/Grid';
import PageCard from 'components/Cards/PageCard';
import MyThema from 'components/Cards/MyThema';
import AddExperienceCard from 'components/Cards/AddExperienceCard';
import { localStorageData } from 'services/auth/localStorageData';
// import { Helmet } from 'react-helmet';
import { Helmet, HelmetData } from 'react-helmet-async';

import AdCard from 'components/Cards/AdCard';
import AdData from 'components/Data/AdData';

import { animateScroll as scroll } from 'react-scroll';

function ThemaDetail() {
  let { id } = useParams();
  const helmetData = new HelmetData({});

  const [allPost, setallPost] = React.useState([]);
  const [disease, setDisease] = React.useState([]);

  const [allThema, setallThema] = React.useState([]);
  const [SEOcontent, setSEOcontent] = React.useState('');

  const [chunksPost, setchunksPost] = React.useState(0);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  const getAllPosts = useQuery(
    'allOnedata',
    () => userServices.commonGetService(`/post/getOnePost/${id}`),
    {
      refetchOnWindowFocus: false,
      /// refetchInterval: 5000,
      //////refetchIntervalInBackground: true,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setallPost(res.data.data);
        console.log('na=', res.data.data);

        setchunksPost(chunksPost + 5);
      },
    }
  );
  // console.log(allPost);

  const getAllDisease = useQuery(
    'allpostdisease',
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);
        const random = Math.floor(Math.random() * res.data.data.length);

        setDisease(res.data.data[random]);
      },
    }
  );

  const getAllThema = useQuery(
    'allpostThema',
    () =>
      userServices.commonGetService(
        `/post/getThemeByUserid/${localStorageData('_id')}`
      ),
    {
      enabled: localStorageData('_id') ? true : false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);

        let keywords = '';

        console.log(res.data.data[0].symptons.keywords);

        res.data.data[0].symptons.keywords.map((item) => {
          keywords = keywords + ',' + item;
        });

        keywords = keywords.slice(1);

        setSEOcontent(keywords);

        setallThema(res.data.data);
      },
    }
  );

  function reftechThema(data) {
    getAllThema.refetch();
  }

  function reftechPost(data) {
    getAllPosts.refetch();
  }

  // console.log(allPost);

  return (
    <div className='min-h-screen'>
      {allPost != '' ? (
        <Helmet prioritizeSeoTags helmetData={helmetData}>
          <meta charSet='utf-8' />
          <title>{allPost[0].symptons.name}</title>
          <link
            rel='canonical'
            href={`https://xpera.de/account/themaDetail/${allPost[0]?.symptons.name}/${allPost[0]?.means_thearay[0].name}/${allPost[0]?._id}`}
          />
          <meta name='description' content={allPost[0].symptons.desc} />
          <meta name='keywords' content={SEOcontent} />
          {/* <meta property="og:title" content={allPost[0].symptons.name}/>
          <meta property="og:image" content="your_image_url"/>
          <meta property="og:description" content={allPost[0].symptons.desc}/> */}
        </Helmet>
      ) : null}

      <Container maxWidth='lg'>
        <Grid container spacing={4}>
          <Grid item xl={6} lg={7} md={7} sm={12} xs={12}>
            <div className=' my-10 '>
              <PostCard
                reftechPost={reftechPost}
                data={allPost}
                showAll={true}
              />
            </div>
            <div className=' bg-white border border-color-yellow rounded-lg p-4 mb-6'>
              <h3 className='semibold text-black mb-2'>Wichtiger Hinweis:</h3>
              <p className='mt-2 text-xs text-black'>
                Die auf dieser Seite enthaltenen Berichte und Kommentare sind
                individuelle Erfahrungen und Ansichten unserer Nutzer. Diese
                Informationen dürfen nicht zur Selbstdiagnose oder
                Selbstbehandlung genutzt werden und ersetzen auch keinen
                Arztbesuch. Bitte besprechen die jede Maßnahme immer zuerst mit
                einem Arzt oder Heilpratiker ihres Vertrauens.
              </p>
            </div>
          </Grid>

          <Grid item xl={3} lg={2} md={2} sm={12} xs={12}>
            <div className=' my-10 grid content-center items-center justify-center'>
              <AdCard link={window.location.href} />
            </div>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className=' fixed right-0 h-full'>
              <MyThema data={allThema} disease={disease} />
            </div>
          </Grid>
        </Grid>
        {/* 
        <a className='p-20 my-30'>
          {`https://xpera.de/account/themaDetail/${allPost[0]?.symptons.name}/${allPost[0]?.means_thearay[0].name}/${allPost[0]?._id}`}
        </a> */}
      </Container>
    </div>
  );
}

export default ThemaDetail;
