import React from 'react';

function IncognitoCard() {
  return (
    <div className='bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border border-frame-gray'>
      <div className='flex flex-row border-b p-4	border-color-grey '>
        <div className='text-xs text-black font-semibold'>
          Du möchtest anonym bleiben?
        </div>
      </div>
      <div className='p-6'>
        <p className='text-center text-xs2 '>
          Mit einem Klick auf Dein Profilbild oben rechts, und einem weiteren
          Klick auf Deinen Namen kannst Du in den Inkognitomodus wechseln - und
          auch wieder zurück.
        </p>
        <div className='center-styl'>
          <img
            id='output'
            src={require('assets/img/Bild2.png')}
            loading='lazy'
            className='w-full h-full object-cover'
            alt='Incognito'
          />
        </div>
      </div>
    </div>
  );
}

export default IncognitoCard;
