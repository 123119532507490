import React from 'react';
import Container from '@mui/material/Container';
import LandingHeader from 'components/Headers/LandingHeader';
import Grid from '@mui/material/Grid';
import userService from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { ImageEndPoint } from 'config/config';
import {
  localStorageData,
  storeLocalData,
} from 'services/auth/localStorageData';
function ListItem({ title }) {
  return (
    <div className='flex gap-2 my-2 mt-8'>
      <i class='fas fa-check-circle text-color-yellow'></i>
      <p className='text-xs -mt-1'>{title}</p>
    </div>
  );
}

function Pricing() {
  const { mutate } = useMutation(
    (updateExtented) =>
      userService.commonPostService(
        '/social/MakePaymentForPlan',
        updateExtented
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        console.log('mnew data------------', data.data.data.data);

        window.location.href = data.data.data.data.payment_url;
      },
    }
  );

  const requestForPayment = async (planType, totalAmount) => {
    mutate({
      userId: localStorageData('_id'),
      planType: planType,
      totalAmount: totalAmount,
    });
  };

  return (
    <div className='pb-20'>
      <section className='my-10 '>
        <Container maxWidth='md'>
          <h1 className='semibold text-center text-sm'>
            Was können wir für Sie tun?
          </h1>
          <h2 className='text-center text-sm mt-2'>
            Kostenloser Einstieg, mehr Patienten oder das Komplett-Paket?
          </h2>

          <h3 className='text-center text-sm text-text-clr mt-4'>
            Profitieren Sie von unserem Frühstarter-Angebot
            <br /> und sichern Sie sich die Xpera Vorteile zum Aktionspreis.
          </h3>

          <div className='mt-10 '>
            <Grid container spacing={3}>
              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className='block bg-white shadow-lg border border-color-btn pricing-card  w-full  rounded '>
                  <div className=' bg-white'>
                    <div className='px-6 pt-6 pb-1'>
                      <div className='text-center semibold text '>Basis</div>

                      {/* <p className='text-center bold-styl relative'>
                        <span className='text-4xl absolute mt-2 -ml-2'>€</span>
                        <span className='text-6xl'>0</span>
                      </p> */}

                      <p className='text-6xl textBold text-center'>
                        0<sub className='text-xs'>€</sub>
                      </p>

                      <p className='text-center text-xs2'>
                        Kostenloses Basisprofil für Heilpraktiker, Therapeuten
                        und Coachs
                      </p>
                      <p className='text-center text-xs2 my-2 text-text-clr'>
                        Kostenloser Plan
                      </p>
                      <div className='flex center-styl my-4'>
                        <button
                          onClick={() => requestForPayment('Basis', 0)}
                          className='border border-color-btn w-full px-10 py-3 rounded-full text-center text-color-btn'
                        >
                          Auswählen
                        </button>
                      </div>
                    </div>
                    <div className='border-t border-color-btn my-6' />
                  </div>

                  <div className='px-6 pb-4'>
                    <ListItem title='Basisprofil mit invidivuellem Hintergrundbild' />
                    <ListItem title='Verlinkung der eigenen Webseite' />
                    <ListItem title='Zugriff auf Beitragserstellung und Social Media Funktionen' />
                  </div>
                </div>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className='block bg-white shadow-lg border border-color-btn pricing-card  w-full  rounded '>
                  <div className='absolute -mt-4 center-div'>
                    <div className='bg-color-btn p-2 text-white semibold w-32 text-xs2 text-center rounded'>
                      Am Beliebtsten
                    </div>
                  </div>

                  <div className=' bg-[#f7f7f7]'>
                    <div className='px-6 pt-6 pb-1'>
                      <div className='text-center semibold text '>Premium</div>
                      {/* <p className='text-center bold-styl relative'>
                        <span className='text-4xl absolute mt-2 -ml-2'>€</span>
                        <span className='text-6xl'>29</span>
                      </p> */}

                      <div className='flex gap-2 center-styl'>
                        <p className='text-xl  text-text-clr line-through	 text-center -ml-6'>
                          29 €
                        </p>

                        <p className='text-6xl textBold text-center'>
                          12<sub className='text-xs'>€</sub>
                        </p>
                      </div>

                      <p className='text-center text-[10px] text-text-clr mb-2 -mt-2'>
                        pro Monat
                      </p>

                      <p className='text-center text-xs2'>
                        hohe Sichtbarkeit durch Premium-Profil und gezielte
                        Anzeigen zu Wunschthemen
                      </p>
                      <p className='text-center text-xs2 my-2 text-text-clr'>
                        gilt für 12 Monate
                      </p>
                      <div className='flex center-styl my-4'>
                        <button
                          onClick={() => requestForPayment('Premium', 29)}
                          className=' bg-[#eb910c] w-full px-10 py-3 rounded-full text-center text-white'
                        >
                          Auswählen
                        </button>
                      </div>
                    </div>
                    <div className='border-t border-color-btn my-6' />
                  </div>

                  <div className='px-6 pb-4'>
                    <ListItem title='BAlle Basis-Funktionen und zusätzlich:' />
                    <ListItem title='3 Anzeigenplätze auf Themenseiten Ihrer Wahl' />
                    <ListItem title='professionelles Profil in Webseitenqualität' />
                    <ListItem title='Individueller Begrüßungstext' />
                    <ListItem title='visuelle Darstellung Ihres Angebotes' />
                    <ListItem title='automatische Darstellung Ihrer Behandlungserfolge' />
                    <ListItem title='Platz für zusätzliche Informationen über Sie und Ihre Praxis' />
                  </div>
                </div>
              </Grid>

              <Grid item lg={4} md={4} sm={12} xs={12}>
                <div className='block bg-white shadow-lg border border-color-btn pricing-card  w-full  rounded-lg '>
                  <div className=' bg-white'>
                    <div className='px-6 pt-6 pb-1'>
                      <div className='text-center semibold text '>
                        Premium + Web
                      </div>
                      {/* <p className='text-center bold-styl relative'>
                        <span className='text-4xl absolute mt-2 -ml-2'>€</span>
                        <span className='text-6xl'>99</span>
                      </p> */}

                      <div className='flex gap-2 center-styl'>
                        <p className='text-xl  text-text-clr line-through	 text-center -ml-6'>
                          99 €
                        </p>

                        <p className='text-6xl textBold text-center'>
                          59<sub className='text-xs'>€</sub>
                        </p>
                      </div>

                      <p className='text-center text-[10px] text-text-clr mb-2 -mt-2'>
                        pro Monat
                      </p>
                      <p className='text-center text-xs2'>
                        hohe Sichtbarkeit durch Premium-Profil und gezielte
                        Anzeigen zu Wunschthemen
                      </p>
                      <p className='text-center text-xs2 my-2 text-text-clr'>
                        gilt für 24 Monate
                      </p>
                      <div className='flex center-styl my-4'>
                        <button
                          onClick={() => requestForPayment(' Premium Plus', 99)}
                          className='border border-color-btn w-full px-10 py-3 rounded-full text-center text-color-btn'
                        >
                          Auswählen
                        </button>
                      </div>
                    </div>
                    <div className='border-t border-color-btn my-6' />
                  </div>

                  <div className='px-6 pb-4'>
                    <ListItem title='Alle Premium-Funktionen und zusätzlich:' />
                    <ListItem title='prominenter Anzeigeplatz auf der Startseite' />
                    <ListItem title='Eigene Webseite mit 3 Unterseiten' />
                    <ListItem title='Behandlungen' />
                    <ListItem title='Therapieverfahren' />
                    <ListItem title='Kontakt' />
                    <ListItem title='eigene Domain' />
                    <ListItem title='Top Sichtbarkeit durch SEO-Optimierung' />
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>

      <Container maxWidth='md'>
        <section className='mt-24'>
          <div className='flex justify-center '>
            <p className='text-[#505050]'>
              Preise exklusive gesetzlicher MwSt. und bei jährlicher Zahlweise
            </p>
          </div>
          <div className='mt-24 flex justify-center'>
            <h2 className='text-xl  semibold'>
              Häufige Fragen auf einen Blick
            </h2>
          </div>
          <div className='mt-12 '>
            <p className='text-1xl semibold'>
              Wie viel kosten die Xpera Vorteile?
            </p>
          </div>
          <div className='mt-4 text-[#505050] '>
            <p>
              Die aktuell geltenden Kosten können sie den obigen Paketpreisen
              entnehmen. Die angegebenen Preise sind exklusive der gesetzlichen
              MwSt und als Jahrenbeitrag zu zahlen.{' '}
            </p>
          </div>
          <div className='mt-12 '>
            <p className='text-1xl semibold'>
              Welche Laufzeit hat der Vertrag?
            </p>
          </div>
          <div className='mt-4 text-[#505050] '>
            <p>
              Die Vertragslaufzeit beginnt mit der Aktivierung des
              Premium-Profils und beträgt je nach Paketauswahl Premium 12 bzw.
              Premium + Web 24 Monate.
            </p>
          </div>
          <div className='mt-12 '>
            <p className='text-1xl semibold'>
              Kann jederzeit gekündigt werden?
            </p>
          </div>
          <div className='mt-4 text-[#505050] '>
            <p>
              Eine Kündigung ist jederzeit möglich, jedoch gilt auch bei einer
              vorzeitigen Kündigung die vertraglich vereinbarte Paketgebühr.
              Bereits gezahlte Beträge können nicht zurückerstattet werden. Wenn
              Sie ihren Vertrag kündigen oder das jeweilige Laufzeitende
              erreicht ist, wird das Premium-Profil automatisch in ein
              kostenloses Basis-Profil umgewandelt und Ihre Anzeigen von den
              jeweiligen Themenseiten entfernt. Sind die Premium + Web Kunde
              nehmen wir mit Ihnen Kontakt auf, um mit Ihnen gemeinsam die
              weitere Vorgehensweise zu besprechen.
            </p>
          </div>
          <div className='mt-12 '>
            <p className='text-1xl semibold'>Was sind die Zahlungsoptionen?</p>
          </div>
          <div className='mt-4 text-[#505050] '>
            <p>
              Premium oder Premium + Web Nutzer können zwischen folgenden
              Zahlungsmethoden wählen. Die Jahresgebühr wird beim Kauf fällig
              und kann bequem per Paypal, Amazonpay, Banküberweisung oder
              Kreditkarte bezahlt werden.
            </p>
          </div>
        </section>
      </Container>
    </div>
  );
}

export default Pricing;
