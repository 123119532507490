import React from 'react';
import {
  localStorageData,
  storeLocalData,
} from 'services/auth/localStorageData';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useTranslation from 'common/customHooks/translations';
import Grid from '@mui/material/Grid';
import userService from 'services/httpService/userAuth/userServices';
import ErrorService from 'services/formatError/ErrorService';
import { useMutation, useQuery } from 'react-query';
import { ImageEndPoint } from 'config/config';
import userServices from 'services/httpService/userAuth/userServices';
import { TOOGLE_PROFILE, EMPTYSOTRE } from 'reactStore/actions/Actions';
import ServicesData from 'components/Data/ServicesData';
import CheckBoxUi from 'components/CheckboxUi';
import { LOGIN } from 'reactStore/actions/Actions';

export default function ExtendedProfile() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation();
  const ref = React.useRef();
  const [profile, setProfile] = React.useState('');
  const [initialValues, setInitialValues] = React.useState({
    userId: localStorageData('_id'),
    uniqueProfileName: '',
    coverimg: 'https://i.ibb.co/2jxznfx/TitelPic.jpg',
    title: '',
    phone: '',
    website: '',
    address: '',
    welcome: '',
    services: ServicesData,
    info: '',
  });

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      title: Yup.string().min(2, t.mincharacters2).required(t.required),
      uniqueProfileName: Yup.string()
        .min(2, t.mincharacters2)
        .required(t.required),
    }),
    onSubmit: (values) => {
      // console.log(values);
      const formData = new FormData();
      formData.append('pics', values.coverimg);
      formData.append('title', values.title);
      formData.append('uniqueProfileName', values.uniqueProfileName);
      formData.append('phone', values.phone);
      formData.append('website', values.website);
      formData.append('address', values.address);
      formData.append('welcome', values.welcome);
      formData.append('info', values.info);
      formData.append('userId', localStorageData('_id'));
      formik.values.services.forEach((item) =>
        formData.append('services', JSON.stringify(item))
      );

      mutate(formData);
    },
  });

  const onChangeCover = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById('cover');
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      formik.setFieldValue('coverimg', file);
    }
  };

  const getExtendedproflie = useQuery(
    'extendedProfile',
    () =>
      userServices.commonGetService(
        `/social/extendedPofile/${localStorageData('_id')}`
      ),
    {
      enabled: localStorageData('_id') ? true : false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        const newVal = res.data.data;
        // console.log(res.data);
        if (res.data.data) {
          setInitialValues({
            userId: localStorageData('_id'),
            uniqueProfileName: newVal.uniqueProfileName,
            coverimg: ImageEndPoint + newVal.coverPhoto,
            title: newVal.title,
            phone: newVal.phone,
            website: newVal.website,
            address: newVal.address,
            welcome: newVal.welcome,
            services: newVal.services.map((item) => {
              return JSON.parse(item);
            }),
            info: newVal.info,
          });
        }

        setProfile(res.data.data);
      },
    }
  );
  const { mutate } = useMutation(
    (updateExtented) =>
      userService.commonPostService('/social/extendedPofile', updateExtented),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        // console.log('mnew data------------', data.data.data);

        let res = data.data.data;
        res['pic'] = ImageEndPoint + res.pic;

        dispatch(LOGIN(res));

        toast.success(t.update);
      },
    }
  );

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <div className='text-xs text-black'>Dein Titelbild</div>
            </Grid>

            <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
              <img
                // src={ImageEndPoint + myimg}
                src={formik.values.coverimg}
                alt='img'
                id='cover'
                className='object-cover w-32 h-8 rounded '
              />
            </Grid>
            <Grid item xl={3} lg={3} md={5} sm={6} xs={6}>
              <label
                className='text-color-btn font-semibold float-left '
                htmlFor='contained-button-cover'
              >
                <input
                  type='file'
                  onChange={onChangeCover}
                  accept='image/*'
                  id='contained-button-cover'
                />
                Titelbild ändern
              </label>
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Titeltext</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='title'
                name='title'
                placeholder='Titeltext'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.title}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Domain-Name</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='uniqueProfileName'
                name='uniqueProfileName'
                placeholder='Domain-Name'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.uniqueProfileName}
              />
              {formik.touched.uniqueProfileName &&
              formik.errors.uniqueProfileName ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.uniqueProfileName}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <hr className='' />

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Telefon</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='phone'
                name='phone'
                placeholder='Telefon'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.phone}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Webseite</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='website'
                name='website'
                placeholder='Webseite'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.website}
              />
              {formik.touched.website && formik.errors.website ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.website}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Adresse</label>
            </Grid>

            <Grid item xl={3} lg={4} md={5} sm={6} xs={6}>
              <input
                type='text'
                id='address'
                name='address'
                placeholder='Adresse'
                className=' bg-color-lightgrey w-full text-color-btn border-search focus:bg-white p-1'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.address}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <hr className='border-t border-red-500' />

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Begrüßungstext</label>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <textarea
                type='text'
                id='welcome'
                name='welcome'
                placeholder='Begrüßungstext'
                className='bg-white w-full text-color-btn border-search focus:bg-white p-1 border border-color-grey'
                rows='4'
                cols='50'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.welcome}
              />
              {formik.touched.welcome && formik.errors.welcome ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.welcome}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Schwerpunkte</label>
            </Grid>

            <Grid item xl={10} lg={10} md={10} sm={12} xs={12}>
              <div className='grid grid-cols-2 gap-2 lg:grid-cols-3 md:grid-cols-3'>
                {formik.values.services.map((item) => (
                  <CheckBoxUi item={item} formik={formik} data={ServicesData} />
                ))}
              </div>

              {formik.touched.services && formik.errors.services ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.services}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>

        <div className='flex justify-start mt-2 py-6 '>
          <Grid container spacing={2}>
            <Grid item xl={2} lg={2} md={2} sm={6} xs={6}>
              <label className='text-xs text-black'>Weitere infos</label>
            </Grid>

            <Grid item xl={5} lg={5} md={5} sm={6} xs={6}>
              <textarea
                type='text'
                id='info'
                name='info'
                placeholder='Weitere infos'
                className='bg-white w-full text-color-btn border-search focus:bg-white p-1 border border-color-grey'
                rows='4'
                cols='50'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.info}
              />
              {formik.touched.info && formik.errors.info ? (
                <div className='text-red-500 mt-2 text-xs'>
                  {formik.errors.info}
                </div>
              ) : null}
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={8} xs={8}>
            <div className='text-center my-6 '>
              <button className='btn-styl' type='submit'>
                Einstellungen speichern
              </button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
