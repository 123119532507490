import React, { useState } from "react";

export default function UploadImage({ setState }) {
  const onChangeHandler = async (e) => {
    var reader = new FileReader();
    reader.onload = function () {
      var output = document.getElementById("output1");
      output.src = reader.result;
    };
    if (e.target.files[0]) {
      const file = e.target.files[0];

      setState((prevState) => ({
        ...prevState,
        file: file,
      }));
      console.log(file, "file-------");
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="grid content-center items-center justify-center">
      <label
        className="text-color-btn font-semibold mb-4 float-left border border-color-grey"
        htmlFor="contained-button-file"
      >
        <input
          type="file"
          onChange={onChangeHandler}
          accept="image/*"
          id="contained-button-file"
        />
        <img
          id="output1"
          src="https://img.freepik.com/premium-vector/gallery-icon-picture-landscape-vector-sign-symbol_660702-224.jpg?"
          className="w-40 h-40 rounded object-cover"
        />
      </label>

      <div className="">Eigenes Foto hinzufügen (optional)</div>
    </div>
  );
}
