import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import { Helmet } from 'react-helmet';
import { animateScroll as scroll } from 'react-scroll';
import { Link } from 'react-router-dom';
import { Helmet, HelmetData } from 'react-helmet-async';

import 'assets/css/landing.css';

function LandingPage() {
  let navigate = useNavigate();
  const helmetData = new HelmetData({});

  function Card({ img, title }) {
    return (
      <div className=' landing-cards  h-32 mt-4'>
        <div className='w-20 h-12 mbl-cards'>
          <img
            src={img}
            className='w-36  h-20 rounded-full object-cover '
            alt=''
          />
          <p className='text-center text-md semibold  mt-2 text-cards-bg font-bold mbl-cards'>
            {title}
          </p>
        </div>
      </div>
    );
  }
  return (
    <>
      <Helmet prioritizeSeoTags helmetData={helmetData}>
        <meta charSet='utf-8' />
        <title>XPera | Erfahrungsaustausch in allen Gesundheitsthemen</title>
        <link rel='canonical' href='https://xpera.de/account/themaDetail' />
        <meta
          name='description'
          content='Finde das beste Mittel, die beste Therapie oder die besten Fachleute, auf Basis der Erfahrung von Menschen mit dem gleichen Problem wie Du'
        />
      </Helmet>
      <div className=' w-full pb-16 landing-mbl-resp bg-gradiant '>
        <Container maxWidth='lg'>
          <Grid container spacing={0}>
            <Grid item lg={6} xs={12} className=''>
              <div className='mt-24   '>
                <div className=' flex xs:items-center xs:justify-center lg:items-center lg:justify-start  '>
                  <h3 className='bg-[#1d91a6] main-text  text-center px-2 semibold text-white'>
                    Du bist gesundheitlich angeschlagen?
                  </h3>
                </div>

                <div className='mt-10 flex center-styl heading-main '>
                  <h1 className='  textBold  border-black text-white  landing-shadow'>
                    Erfahre bei uns, was anderen geholfen hat
                  </h1>
                </div>
                <div className='mt-10 w-full flex center-styl   '>
                  <p className=' paragraph text-white '>
                    Es gibt viele Menschen, mit dem gleichen Thema wie du.
                    Erfahre welche Mittel, Therapien oder Fachleute ihnen helfen
                    konnten und was Heilpraktiker und Therapeuten berichten.
                  </p>
                </div>
                <div className='flex gap-10 mt-12 landing-buttons'>
                  <div className=' flex justify-center '>
                    <button
                      onClick={() => scroll.scrollTo(1000)}
                      className='btn-sizes bg-white rounded-md  text-color-btn semibold hover:bg-yellow-300 landing-btn '
                    >
                      Mehr erfahren
                    </button>
                  </div>
                  <div className=' flex justify-center mobile-btn-margin '>
                    <Link
                      reloadDocument
                      className=' second-btn semibold rounded-md  text-white border-white landing-btn border-2 text-center  pt-2 font-bold  hover:bg-sky-600 '
                      to={'/account/home'}
                    >
                      Erfahrungen finden
                    </Link>
                    {/* <button onClick={() => navigate('/account/home')}>
                      Erfahrungen finden
                    </button> */}
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item lg={6} xs={12} className='center-styl'>
              <div className='mbl-commnts img-locations   mt-24'>
                <div className='  flex justify-start items-center  '>
                  <img
                    src={require('assets/img/cmnt1.png')}
                    className='	image-sizes object-cover mbl-img-size rounded-xl'
                    alt=''
                  />
                </div>
                <div className='mt-4  flex justify-start items-center'>
                  <img
                    src={require('assets/img/cmnt2.png')}
                    className='	image-sizes object-cover mbl-img-size rounded-xl'
                    alt=''
                  />
                </div>
                <div className='mt-4   flex justify-start items-center '>
                  <img
                    src={require('assets/img/cmnt3.png')}
                    className='	image-sizes object-cover mbl-img-size rounded-xl'
                    alt=''
                  />
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
      <section className='py-12'>
        <div className='flex justify-center items-center   '>
          <p className='title-koshto  text-center textBold text-text-clr  '>
            Kostenlos - von Mensch zu Mensch
          </p>
        </div>

        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 flex justify-center'>
                <img
                  src={require('assets/img/Bild1.png')}
                  className=''
                  alt=''
                />
              </div>
              <div className='flex justify-center font-bold text-text-clr -mt-8 ml-3 '>
                <h2 className='Number-style my-2 textBold'>400+</h2>
              </div>
              <p className='-mt-1 semibold flex justify-center text-text-clr name-style'>
                Gesundheitsthemen
              </p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 flex justify-center'>
                <img
                  src={require('assets/img/Bildb.png')}
                  className=''
                  alt=''
                />
              </div>
              <div className='flex justify-center font-bold text-text-clr -mt-8 ml-3 '>
                <h2 className='Number-style textBold my-2'>1100+</h2>
              </div>
              <p className='-mt-1 semibold flex justify-center text-text-clr name-style'>
                Behandlungserfolge
              </p>
            </Grid>
            <Grid item lg={4} xs={12}>
              <div className='mt-8 flex justify-center'>
                <img
                  src={require('assets/img/Bild3.png')}
                  className=''
                  alt=''
                />
              </div>
              <div className='flex justify-center font-bold text-text-clr -mt-8 ml-3 '>
                <h2 className='Number-style textBold my-2'>1200+</h2>
              </div>
              <p className='-mt-1 semibold flex justify-center text-text-clr name-style'>
                geholfenen Menschen
              </p>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section className='bg-color-bg   py-12  justify-center items-center landing-mbl-back landing-mbl-resp'>
        <Container maxWidth='lg'>
          <div className='grid justify-center items-center   '>
            <div className=' center-styl'>
              <p className='title-koshto second-title textBold  text-text-clr  '>
                Die derzeitigen Top -Themen auf Xpera
              </p>
            </div>
          </div>
          <div className='flex center-styl mt-12'>
            <div className='flex flex-wrap gap-10'>
              <Card title='Akne' img='https://i.ibb.co/t3hz2L7/rashes.jpg' />
              <Card
                title='Depressionen'
                img='https://i.ibb.co/zRxLBVP/Depression.jpg'
              />

              <Card title='Husten' img='https://i.ibb.co/m6v1zzr/Husten.jpg' />

              <Card
                title='Panikattacke'
                img='https://i.ibb.co/sPTpbzL/Panikattacke.jpg'
              />

              <Card
                title='Übergewicht'
                img='https://i.ibb.co/q750GDQ/pic1.jpg'
              />

              <Card
                title='Erschöpfung'
                img='https://i.ibb.co/FDKgWx3/pic2.jpg'
              />

              <Card title='Asthma' img='https://i.ibb.co/82ZH0Y6/Asthma.jpg' />
              <Card
                title='Allergie'
                img='https://i.ibb.co/3f4ptLx/Allergie.jpg'
              />
            </div>
          </div>
        </Container>
      </section>
      <section className='h-auto py-16 w-full'>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={12}>
              <div className='center-styl '>
                <div className='xpera-center'>
                  <p className='text-expera-bg  semibold xpera-heading'>
                    XPERA
                  </p>
                  <h2 className=' font-bold text-black text-3xl textBold para-above'>
                    Alle Informationen zu deinen Gesundheitsthemen auf einer
                    einzigen Plattform - wo immer du willst
                  </h2>
                  <p className='mt-4 text-md xpera-para'>
                    Die Erfahrungen und Behandlungserfolge anderer eröffnen dir
                    neue Perspektiven für dein persönliches Thema. Tausche dich
                    aus und entdecke Behandlungsmöglichkeiten, an die du oder
                    dein Arzt bisher nicht gedacht haben.
                  </p>
                  <div className='mt-6'>
                    <Link
                      reloadDocument
                      className=' koshto-btn textBold text-center py-2 rounded-md hover:bg-[#20A3BB] text-white   px-4'
                      to={'/auth/signup'}
                    >
                      Kostenlos registrieren
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className='mt-16  '>
                <img
                  src={require('assets/img/Bild5.png')}
                  className=' w-full h-full object-cover'
                  alt=''
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className='py-20 bg-color-bg'>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12}>
              <div className=' '>
                <img
                  src={require('assets/img/Bild6.png')}
                  className=' w-full h-full object-cover'
                  alt=''
                />
              </div>
            </Grid>
            <Grid item lg={6} sm={12}>
              <div className='    center-styl '>
                <div className='xpera-center'>
                  <p className='text-expera-bg semibold font-bold xpera-heading'>
                    THEMENSEITEN
                  </p>
                  <h2 className=' font-bold text-black textBold Aless-text'>
                    Alles Wichtige zu deinem Thema auf einem Blick
                  </h2>
                  <p className=' mt-2 text-xl xpera-para'>
                    Erfahre, wie viele Menschen außer dir ebenfalls betroffen
                    sind. Wie viele unterschiedliche Behandlungsmethoden
                    Anwendung fanden, wie vielen Menschen bereits geholfen wurde
                    und wie hoch die Erfolgsrate für dieses Thema ist.
                  </p>
                  <div className=''>
                    <Link
                      reloadDocument
                      className=' themen-btn font-bold textBold rounded-md hover:bg-[#f2b619] text-white py-2  px-4 '
                      to={'/account/home'}
                    >
                      Themen durchstöbern
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className='h-auto  py-10 w-full'>
        <Container maxWidth='lg'>
          <Grid container spacing={2}>
            <Grid item lg={6} sm={12}>
              <div className='  block  center-styl '>
                <div className='xpera-center '>
                  <p className='text-expera-bg  semibold xpera-heading'>
                    BEHANDLUNGSERFOLGE
                  </p>
                  <h2 className='mt-2 textBold  text-black para-above'>
                    Die besten Mittel, die besten Therapien und die besten
                    Fachleute
                  </h2>
                  <p className='-mt-6  w-5/6  text-md para-content'>
                    Jeder einzelne Beitrag auf Xpera ist ein Behandlungserfolg!
                    Wie sehr die jeweilige Behandlung geholfen hat, siehst du an
                    der Bewertung. Hat diese Behandlung anderen Betroffenen mit
                    dem selben Problem ebenfalls geholfen, wird (mit einem Klick
                    auf das Herz im Beitrag) deren Erfahrung und individuelle
                    Bewertung der Behandlung hinzugefügt. So etablieren sich mit
                    der Zeit, für jedes Thema die wirksamsten
                    Behandlungsmethoden.
                  </p>
                  <div className='mt-8 button-koshto'>
                    <Link
                      reloadDocument
                      className=' themen-btn font-bold textBold rounded-md hover:bg-[#f2b619] text-white py-2  px-4 '
                      to={'/account/home'}
                    >
                      Erfolge entdecken
                    </Link>
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item lg={6} xs={12}>
              <div className='mt-12  '>
                <img
                  src={require('assets/img/Bild7.png')}
                  className=' w-full h-full'
                  alt=''
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className=' mt-12 bg-color-bg flex w-full data-placing '>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <div className=' img-wrapping'>
              <img
                src={require('assets/img/Bild8.png')}
                className='origin-bottom -rotate-12 border-white ml-20 border-8  w-11/12 mt-20 curved-img'
                alt=''
              />
            </div>
          </Grid>
          <Grid item lg={7} xs={12}>
            <div className='bg-white w-10/12 mt-14 p-4 mobile-responsiveness mb-6'>
              <div className='mt-20 ml-4 content-placing'>
                <p className='text-expera-bg text-1xl semibold  '>
                  WARUM GIBT ES XPERA?
                </p>
                <h2 className=' text-2xl textBold mt-4'>
                  Wie ein Erlebnis mein Leben verändert hat und wie es deines
                  verändern könnte
                </h2>
                <h3 className='font-bold text-xl semibold  mt-6'>
                  Mit Anfang 20 bekam ich sehr hohen Blutdruck ( 175 / 105 )
                </h3>
                <p className='  mt-2'>
                  und wurde, nachdem man keine körperliche Ursache dafür
                  ausmachen konnte, auf Betablocker eingestellt
                </p>
                <p className='mt-8'>
                  Aber selbst mit Tabletten war mein Blutdruck nie unter 145 /
                  95. Die kommenden Jahre waren eine einzige Arzt-Odyssee. Ich
                  wurde von Experte zu Experte geschickt aber ohne nennenswerte
                  Erkenntnisse oder Besserung für mein Problem.
                </p>

                <p className='mt-8 '>
                  Mit der Zeit gesellten sich sogar weitere Symptome, wie
                  Herzrythmusstörungen, Lebensmittelunverträglichkeiten und
                  Allergien hinzu. Mit Mitte 30 war ich eine einzige
                  "gesundheitliche Baustelle". Schon geringe Anstrengungen oder
                  ein zu üppiges Essen sorgten für Herzrasen, Herzstolpern und
                  schlaflose Nächte, die nicht selten in Panikattacken mündeten.
                </p>
                <p className='font-bold text-xl semibold  mt-6'>
                  Der Tag, der mein Leben veränderte.
                </p>
                <p className='mt-8 '>
                  Durch eine Bekannte wurde ich auf einen Kinesiologen
                  aufmerksam gemacht. Ich hatte mich zuvor noch nie mit
                  alternativer Medizin auseinandergesetzt und von Kinesiologie
                  hatte ich erst recht nichts gehört, aber ich griff nach jedem
                  Strohhalm, der Besserung versprach.
                </p>
                <p className='mt-8 '>
                  Gleich bei meinem ersten Termin ermittelte er den Grund für
                  meine Probleme. Seine Behandlung führte dazu, dass sich mein
                  Bluthochdruck innerhalb weniger Wochen normalisierte und alle
                  damit verbundenen Symptome rund um Herz und Kreislauf in
                  Wohlgefallen auflösten ... heute bin ich komplett
                  beschwerdefrei und kann das Leben wieder genießen! ( siehe
                  Bild )
                </p>
                <p className='mt-8 '>
                  Dieses Erlebnis hat mein Leben verändert und war die
                  Geburtsstunde für den Wunsch nach einer Plattform wie Xpera.
                  Und was war diesem Erlebnis vorausgegangen? Ein Tipp, eine
                  Empfehlungen eines lieben Menschen - basierend auf dessen
                  Erfahrungen.
                </p>
                <p className='font-bold text-xl semibold  mt-6'>
                  Das ist der Kern von Xpera.
                </p>
                <p className='mt-8 '>
                  Hier findest du das beste Mittel, die beste Therapie oder die
                  besten Fachleute auf Basis der Erfahrungen und den
                  Behandlungserfolgen von Menschen mit dem gleichen Problem wie
                  du. Kostenlos - von Mensch zu Mensch
                </p>
                <p className='mt-8 '>
                  Wer und was genau mir geholfen hat wieder gesund zu werden und
                  welche Behandlungserfolge andere bei ihren Themen erlebt
                  haben, findet du auf ...
                </p>
                <div className='mt-8 ml-4'>
                  <button
                    onClick={() => navigate('/account/home')}
                    className=' xpera-btn font-bold rounded-md hover:bg-[#20A3BB] text-white  landing-text-shdw '
                  >
                    Xpera
                  </button>
                </div>
                <p className='text-xl mt-8 ml-6 '>Liebe Grüße</p>
                <p className='text-xl mt-3 ml-6'>Euer Jens</p>
              </div>
              <br />
              <br />
            </div>
          </Grid>
        </Grid>
      </section>
    </>
  );
}

export default LandingPage;
