import React from "react";
import useTranslation from "common/customHooks/translations";

function Information({ state, setState }) {
  const t = useTranslation();

  return (
    <div>
      <h3 className="center-styl my-4 text-base font-semibold text-color-yellow">
        {/* {t.informationaffected} */}
        Nutze bitte das Textfeld für genauere Informationen an unsere
        Mitglieder.
      </h3>

      <div className="center-styl mt-6 mx-10">
        <textarea
          type="text"
          rows="4"
          cols="50"
          name="desc"
          id="desc"
          value={state.own_experience}
          onChange={(e) =>
            setState((prevState) => ({
              ...prevState,
              own_experience: e.target.value,
            }))
          }
          className="input-styl desc-styl"
          placeholder="Sei bitte so ausführlich wie möglich"
        ></textarea>
      </div>
    </div>
  );
}

export default Information;
