import React from "react";
import Container from "@mui/material/Container";
import Symptoms from "./Symptoms";
import RateTreatment from "./RateTreatment";
import Helped from "./Helped";
import Information from "./Information";
import useTranslation from "common/customHooks/translations";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";
import { useUploadPost } from "hooks";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import CircularProgress from "@mui/material/CircularProgress";

function UploadPost() {
  let { newValue } = useParams();
  const location = useLocation();

  const [activeStep, setActiveStep] = React.useState(0);
  let navigate = useNavigate();

  const { IsCognito, currentName, currentPic, profile } = useSelector(
    (state) => state.User
  );

  console.log("post=>", location.state.newValue);

  const { mutateAsync: UploadPost, isLoading } = useUploadPost();
  const localtz = moment.tz.guess();
  const t = useTranslation();
  const [state, setState] = React.useState({
    disease: [],
    selectDisease: "",
    symptons_id: "",

    meantreatment: [],
    meanselectTreatment: "",

    therapytreatment: [],
    therapyselectTreatment: "",
    means_thearay_id: "",

    name: "",
    address: "",
    lat: "",
    long: "",

    others: "",

    rating: 0,
    own_experience: "",
    dataBaseEnter: "",
    whatHelped: "",
  });

  // function selectvalue(val) {
  //   setState({ ...state, selectDisease: val });
  // }

  const getAllDisease = useQuery(
    "diseaseData",
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setState((prevState) => ({ ...prevState, disease: res.data.data }));
      },
    }
  );

  const getallTreatment = useQuery(
    "treatmentData",
    () =>
      userServices.commonGetService(
        `/admin/getallTreatment/${localStorageData("_id")}`
      ),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        const meanpre = res.data.data.filter(
          (item) => item.category == "means/preparation"
        );

        const thact = res.data.data.filter(
          (item1) => item1.category == "therapy/activity"
        );

        setState((prevState) => ({ ...prevState, meantreatment: meanpre }));

        setState((prevState) => ({ ...prevState, therapytreatment: thact }));
      },
    }
  );

  // validation
  const handleNext = () => {
    if (activeStep === 0) {
      if (state.symptons_id === "") {
        toast.error(
          "Bitte wählen sie ein Thema aus der Liste aus. Eigene Eingaben sind nicht möglich"
        );
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 1) {
      if (
        state.means_thearay_id == "" &&
        state.name == "" &&
        state.others == ""
      ) {
        toast.error(
          'Dein(e) Mittel ist/sind noch nicht in unserer Datenbank. Klicke auf "Etwas Anderes" um sie als Freitext eingeben zu können'
        );
        return;
      }

      // console.log(state);
      setActiveStep(activeStep + 1);
    }
    if (activeStep === 2) {
      if (state.rating === 0) {
        toast.error("Enter rating");
        return;
      }
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const steps = ["1", "2", "3", "4"];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Symptoms
            state={state}
            setState={setState}
            routevalue={location.state.newValue}
          />
        );
      case 1:
        return <RateTreatment state={state} setState={setState} />;
      case 2:
        return <Helped state={state} setState={setState} />;
      case 3:
        return <Information state={state} setState={setState} />;
      default:
        return;
    }
  };

  const onSubmit = async () => {
    let help;

    if (state.meanselectTreatment != "" && state.others == "") {
      help = 1;
    } else if (state.therapyselectTreatment != "" && state.others == "") {
      help = 2;
    } else if (state.name != "") {
      help = 3;
    } else if (state.others != "") {
      help = 4;
    }

    let data = {
      Isincognito: IsCognito,
      userId: profile._id,
      symptons_id: state.symptons_id,
      means_thearay_id: state.means_thearay_id,
      whatHelped: help,
      own_experience: state.own_experience,
      rating: state.rating,
      dataBaseEnter: state.dataBaseEnter,
      professional_other:
        state.others != ""
          ? { name: state.others }
          : {
              name: state.name,
              address: state.address,
              lat: state.lat,
              long: state.long,
            },
      timeZone: localtz,
      dateTime: new Date(),
    };
    const response = await UploadPost(data);

    if (response.status) {
      navigate("/account/home");
    }
  };

  return (
    <div className='min-h-screen'>
      <Container maxWidth='lg'>
        <div className='my-6 mx-4 py-6'>
          <h1 className='text-sm center-styl text-color-btn font-semibold tracking-wider'>
            {t.uploadposthead}
          </h1>

          <div className='bg-white w-full container-post  p-10 my-6 border rounded	border-color-yellow'>
            {getStepContent(activeStep)}

            {activeStep === steps.length - 1 ? (
              <div className='center-styl my-10'>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <button
                    className='btn2-styl h-12 w-6/12 '
                    onClick={() => onSubmit()}
                  >
                    {t.finish}
                  </button>
                )}
              </div>
            ) : null}

            <div className='center-styl my-6'>
              {activeStep !== 0 && (
                <button className='btn2-styl mx-4' onClick={handleBack}>
                  {t.back}
                </button>
              )}

              {activeStep === steps.length - 1 ? (
                ""
              ) : (
                <button className='btn2-styl' onClick={handleNext}>
                  {activeStep === steps.length - 1 ? t.next : t.next}
                </button>
              )}
            </div>

            <div className='flex flex-row center-styl'>
              {steps.map((item) =>
                activeStep == item - 1 ? (
                  <div className='bg-color-yellow h-1.5 w-7 rounded mx-1' />
                ) : (
                  <div className='text-sm text-color-dotgrey mx-1'>&#8226;</div>
                )
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default UploadPost;
