import React from 'react';
import Container from '@mui/material/Container';

function PrivacyPolicy() {
  return (
    <div className='mb-10'>
      <Container maxWidth='lg'>
        <div>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '48px', lineHeight: '107%' }}>
              Datenschutzbestimmungen
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              1. Datenschutz auf einen Blick
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Allgemeine Hinweise
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die folgenden Hinweise geben einen einfachen Überblick darüber,
              was mit Ihren personenbezogenen Daten passiert, wenn Sie diese
              Website besuchen. Personenbezogene Daten sind alle Daten, mit
              denen Sie persönlich identifiziert werden können. Ausführliche
              Informationen zum Thema Datenschutz entnehmen Sie unserer unter
              diesem Text aufgeführten Datenschutzerklärung.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Datenerfassung auf dieser Website
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wer ist verantwortlich für die Datenerfassung auf dieser Website?
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Datenverarbeitung auf dieser Website erfolgt durch den
              Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
              „Hinweis zur Verantwortlichen Stelle“ in dieser
              Datenschutzerklärung entnehmen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wie erfassen wir Ihre Daten?
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
              mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in
              ein Kontaktformular eingeben.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Andere Daten werden automatisch oder nach Ihrer Einwilligung beim
              Besuch der Website durch unsere IT- Systeme erfasst. Das sind vor
              allem technische Daten (z. B. Internetbrowser, Betriebssystem oder
              Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt
              automatisch, sobald Sie diese Website betreten.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wofür nutzen wir Ihre Daten?
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Ein Teil der Daten wird erhoben, um eine fehlerfreie
              Bereitstellung der Website zu gewährleisten. Andere Daten können
              zur Analyse Ihres Nutzerverhaltens verwendet werden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Welche Rechte haben Sie bezüglich Ihrer Daten?
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sie haben jederzeit das Recht, unentgeltlich Auskunft über
              Herkunft, Empfänger und Zweck Ihrer gespeicherten
              personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht,
              die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie
              eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
              diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem
              haben Sie das Recht, unter bestimmten Umständen die Einschränkung
              der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des
              Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen
              Aufsichtsbehörde zu.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie
              sich jederzeit an uns wenden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Analyse-Tools und Tools von Drittanbietern
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
              ausgewertet werden. Das geschieht vor allem mit sogenannten
              Analyseprogrammen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Detaillierte Informationen zu diesen Analyseprogrammen finden Sie
              in der folgenden Datenschutzerklärung.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              2. Hosting und Content Delivery Networks (CDN)
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Externes Hosting
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Diese Website wird bei einem externen Dienstleister gehostet
              (Hoster). Die personenbezogenen Daten, die auf dieser Website
              erfasst werden, werden auf den Servern des Hosters gespeichert.
              Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta-
              und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen,
              Websitezugriffe und sonstige Daten, die über eine Website
              generiert werden, handeln.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung
              gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs.
              1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und
              effizienten Bereitstellung unseres Online-Angebots durch einen
              professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO).
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Unser Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies
              zur Erfüllung seiner Leistungspflichten erforderlich ist und
              unsere Weisungen in Bezug auf diese Daten befolgen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wir setzen folgenden Hoster ein:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              1&amp;1 IONOS SE
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Elgendorfer Str. 57
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              56410 Montabaur
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Abschluss eines Vertrages über Auftragsverarbeitung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Um die datenschutzkonforme Verarbeitung zu gewährleisten, haben
              wir einen Vertrag über Auftragsverarbeitung mit unserem Hoster
              geschlossen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              3. Allgemeine Hinweise und Pflichtinformationen
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Datenschutz
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen
              Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
              vertraulich und entsprechend der gesetzlichen
              Datenschutzvorschriften sowie dieser Datenschutzerklärung.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie diese Website benutzen, werden verschiedene
              personenbezogene Daten erhoben.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Personenbezogene Daten sind Daten, mit denen Sie persönlich
              identifiziert werden können. Die vorliegende Datenschutzerklärung
              erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
              erläutert auch, wie und zu welchem Zweck das geschieht.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wir weisen darauf hin, dass die Datenübertragung im Internet (z.
              B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen
              kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch
              Dritte ist nicht möglich.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Hinweis zur verantwortlichen Stelle
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die verantwortliche Stelle für die Datenverarbeitung auf dieser
              Website ist:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Jens Jung und Bettina Henk GbR
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Telefon: 036087-90092
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              E-Mail: kontakt@halfmir.de
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Verantwortliche Stelle ist die natürliche oder juristische Person,
              die allein oder gemeinsam mit anderen über die Zwecke und Mittel
              der Verarbeitung von personenbezogenen Daten (z. B. Namen,
              E-Mail-Adressen o. Ä.) entscheidet.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Speicherdauer
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Soweit innerhalb dieser Datenschutzerklärung keine speziellere
              Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen
              Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt.
              Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine
              Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten
              gelöscht, sofern wir keinen anderen rechtlich zulässigen Gründe
              für die Speicherung Ihrer personenbezogenen Daten haben (z.B.
              steuer- oder handelsrechtliche Aufbewahrungsfristen); im
              letztgenannten Fall erfolgt die Löschung nach Fortfall dieser
              Gründe.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Hinweis zur Datenweitergabe in die USA
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf unserer Website sind unter anderem Tools von Unternehmen mit
              Sitz in den USA eingebunden. Wenn diese Tools aktiv sind, können
              Ihre personenbezogenen Daten an die US-Server der jeweiligen
              Unternehmen weitergegeben werden. Wir weisen darauf hin, dass die
              USA kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts
              sind. US-Unternehmen sind dazu verpflichtet, personenbezogene
              Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als
              Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher
              nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste)
              Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken
              verarbeiten, auswerten und dauerhaft speichern. Wir haben auf
              diese Verarbeitungstätigkeiten keinen Einfluss.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Widerruf Ihrer Einwilligung zur Datenverarbeitung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
              Einwilligung möglich. Sie können eine bereits erteilte
              Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum
              Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf
              unberührt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen
              sowie gegen Direktwerbung (Art. 21 DSGVO)
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E
              ODER F DSGVO
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS
              IHRER BESONDEREN
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN
              DATEN
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE
              BESTIMMUNGEN GESTÜTZTES
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE
              VERARBEITUNG BERUHT,
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH
              EINLEGEN,
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR
              VERARBEITEN, ES
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
              VERARBEITUNG
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN
              ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
              VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1
              DSGVO).
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG
              ZU BETREIBEN,
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
              VERARBEITUNG SIE
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT
              SOLCHER DIREKTWERBUNG IN
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE
              PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER
              DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Beschwerderecht bei der zuständigen Aufsichtsbehörde
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
              Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
              Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
              oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
              besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
              gerichtlicher Rechtsbehelfe.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Recht auf Datenübertragbarkeit
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sie haben das Recht, Daten, die wir auf Grundlage Ihrer
              Einwilligung oder in Erfüllung eines Vertrags automatisiert
              verarbeiten, an sich oder an einen Dritten in einem gängigen,
              maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die
              direkte Übertragung der Daten an einen anderen Verantwortlichen
              verlangen, erfolgt dies nur, soweit es technisch machbar ist.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              SSL- bzw. TLS-Verschlüsselung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
              Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen
              oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine
              SSL- bzw. TLS-Verschlüsselung. Eine verschlüsselte Verbindung
              erkennen Sie daran, dass die Adresszeile des Browsers von
              „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in
              Ihrer Browserzeile.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
              Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
              werden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auskunft, Löschung und Berichtigung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen
              jederzeit das Recht auf unentgeltliche Auskunft über Ihre
              gespeicherten personenbezogenen Daten, deren Herkunft und
              Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht
              auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
              weiteren Fragen zum Thema personenbezogene Daten können Sie sich
              jederzeit an uns wenden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Recht auf Einschränkung der Verarbeitung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen. Hierzu können Sie sich
              jederzeit an uns wenden. Das Recht auf Einschränkung der
              Verarbeitung besteht in folgenden Fällen:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten
              personenbezogenen Daten bestreiten, benötigen wir in der Regel
              Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie
              das Recht, die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
              geschah/geschieht, können Sie statt der Löschung die Einschränkung
              der Datenverarbeitung verlangen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie
              sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von
              Rechtsansprüchen benötigen, haben Sie das Recht, statt der
              Löschung die Einschränkung der Verarbeitung Ihrer
              personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt
              haben, muss eine Abwägung zwischen Ihren und unseren Interessen
              vorgenommen werden. Solange noch nicht feststeht, wessen
              Interessen überwiegen, haben Sie das Recht, die Einschränkung der
              Verarbeitung Ihrer personenbezogenen Daten zu verlangen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten
              eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung
              abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung,
              Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz
              der Rechte einer anderen natürlichen oder juristischen Person oder
              aus Gründen eines wichtigen öffentlichen Interesses der
              Europäischen Union oder eines Mitgliedstaats verarbeitet werden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              4. Datenerfassung auf dieser Website
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Cookies
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Unsere Internetseiten verwenden so genannte „Cookies“. Cookies
              sind kleine Textdateien und richten auf Ihrem Endgerät keinen
              Schaden an. Sie werden entweder vorübergehend für die Dauer einer
              Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf
              Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres
              Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem
              Endgerät gespeichert, bis Sie diese selbst löschen oder eine
              automatische Löschung durch Ihren Webbrowser erfolgt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Teilweise können auch Cookies von Drittunternehmen auf Ihrem
              Endgerät gespeichert werden, wenn Sie unsere Seite betreten
              (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die
              Nutzung bestimmter Dienstleistungen des Drittunternehmens (z.B.
              Cookies zur Abwicklung von Zahlungsdienstleistungen).
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
              technisch notwendig, da bestimmte Websitefunktionen ohne diese
              nicht funktionieren würden (z.B. die Warenkorbfunktion oder die
              Anzeige von Videos). Andere Cookies dienen dazu, das
              Nutzerverhalten auszuwerten oder Werbung anzuzeigen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Cookies, die zur Durchführung des elektronischen
              Kommunikationsvorgangs (notwendige Cookies) oder zur
              Bereitstellung bestimmter, von Ihnen erwünschter Funktionen
              (funktionale Cookies, z. B. für die Warenkorbfunktion) oder zur
              Optimierung der Website (z.B. Cookies zur Messung des
              Webpublikums) erforderlich sind, werden auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO gespeichert, sofern keine andere
              Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein
              berechtigtes Interesse an der Speicherung von Cookies zur
              technisch fehlerfreien und optimierten Bereitstellung seiner
              Dienste. Sofern eine Einwilligung zur Speicherung von Cookies
              abgefragt wurde, erfolgt die Speicherung der betreffenden Cookies
              ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1
              lit. a DSGVO); die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sie können Ihren Browser so einstellen, dass Sie über das Setzen
              von Cookies informiert werden und Cookies nur im Einzelfall
              erlauben, die Annahme von Cookies für bestimmte Fälle oder
              generell ausschließen sowie das automatische Löschen der Cookies
              beim Schließen des Browsers aktivieren. Bei der Deaktivierung von
              Cookies kann die Funktionalität dieser Website eingeschränkt sein.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Soweit Cookies von Drittunternehmen oder zu Analysezwecken
              eingesetzt werden, werden wir Sie hierüber im Rahmen dieser
              Datenschutzerklärung gesondert informieren und ggf. eine
              Einwilligung abfragen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Server-Log-Dateien
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Der Provider der Seiten erhebt und speichert automatisch
              Informationen in so genannten Server-LogDateien, die Ihr Browser
              automatisch an uns übermittelt. Dies sind:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Browsertyp und Browserversion
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              verwendetes Betriebssystem
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Referrer URL
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Hostname des zugreifenden Rechners
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Uhrzeit der Serveranfrage
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              IP-Adresse
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird
              nicht vorgenommen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
              lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse
              an der technisch fehlerfreien Darstellung und der Optimierung
              seiner Website – hierzu müssen die Server-Log-Files erfasst
              werden.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Anfrage per E-Mail, Telefon oder Telefax
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird
              Ihre Anfrage inklusive aller daraus hervorgehenden
              personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung
              Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten
              geben wir nicht ohne Ihre Einwilligung weiter.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
              Vertrags zusammenhängt oder zur Durchführung vorvertraglicher
              Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die
              Verarbeitung auf unserem berechtigten Interesse an der effektiven
              Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f
              DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
              sofern diese abgefragt wurde.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die von Ihnen an uns per Kontaktanfragen übersandten Daten
              verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre
              Einwilligung zur Speicherung widerrufen oder der Zweck für die
              Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung
              Ihres Anliegens). Zwingende gesetzliche Bestimmungen –
              insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Kommentarfunktion auf dieser Website
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Für die Kommentarfunktion auf dieser Seite werden neben Ihrem
              Kommentar auch Angaben zum Zeitpunkt der Erstellung des
              Kommentars, Ihre E-Mail-Adresse und, wenn Sie nicht anonym posten,
              der von Ihnen gewählte Nutzername gespeichert.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Speicherung der IP-Adresse
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Unsere Kommentarfunktion speichert die IP-Adressen der Nutzer, die
              Kommentare verfassen. Da wir Kommentare auf dieser Website nicht
              vor der Freischaltung prüfen, benötigen wir diese Daten, um im
              Falle von Rechtsverletzungen wie Beleidigungen oder Propaganda
              gegen den Verfasser vorgehen zu können.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Abonnieren von Kommentaren
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Als Nutzer der Seite können Sie nach einer Anmeldung Kommentare
              abonnieren. Sie erhalten eine Bestätigungsemail, um zu prüfen, ob
              Sie der Inhaber der angegebenen E-Mail-Adresse sind. Sie können
              diese Funktion jederzeit über einen Link in den Info-Mails
              abbestellen. Die im Rahmen des Abonnierens von Kommentaren
              eingegebenen Daten werden in diesem Fall gelöscht; wenn Sie diese
              Daten für andere Zwecke und an anderer Stelle (z. B.
              Newsletterbestellung) an uns übermittelt haben, verbleiben diese
              Daten jedoch bei uns.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Speicherdauer der Kommentare
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Kommentare und die damit verbundenen Daten werden gespeichert
              und verbleiben auf dieser Website, bis der kommentierte Inhalt
              vollständig gelöscht wurde oder die Kommentare aus rechtlichen
              Gründen gelöscht werden müssen (z. B. beleidigende Kommentare).
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Rechtsgrundlage
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Speicherung der Kommentare erfolgt auf Grundlage Ihrer
              Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können eine von
              Ihnen erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine
              formlose Mitteilung per EMail an uns. Die Rechtmäßigkeit der
              bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              5. Soziale Medien
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Facebook Plugins (Like &amp; Share-Button)
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf dieser Website sind Plugins des sozialen Netzwerks Facebook
              integriert. Anbieter dieses Dienstes ist die Facebook Ireland
              Limited, 4 Grand Canal Square, Dublin 2, Irland. Die erfassten
              Daten werden nach Aussage von Facebook jedoch auch in die USA und
              in andere Drittländer übertragen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Facebook Plugins erkennen Sie an dem Facebook-Logo oder dem
              „Like-Button“ („Gefällt mir“) auf dieser Website. Eine Übersicht
              über die Facebook Plugins finden Sie hier:
              https://developers.facebook.com/docs/plugins/?locale=de_DE.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie diese Website besuchen, wird über das Plugin eine direkte
              Verbindung zwischen Ihrem Browser und dem Facebook-Server
              hergestellt. Facebook erhält dadurch die Information, dass Sie mit
              Ihrer IPAdresse diese Website besucht haben. Wenn Sie den Facebook
              „Like-Button“ anklicken, während Sie in Ihrem Facebook-Account
              eingeloggt sind, können Sie die Inhalte dieser Website auf Ihrem
              Facebook-Profil verlinken. Dadurch kann Facebook den Besuch dieser
              Website Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass
              wir als Anbieter der Seiten keine Kenntnis vom Inhalt der
              übermittelten Daten sowie deren Nutzung durch Facebook erhalten.
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Facebook unter:
              https://de-de.facebook.com/privacy/explanation.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie nicht wünschen, dass Facebook den Besuch dieser Website
              Ihrem Facebook-Nutzerkonto zuordnen kann, loggen Sie sich bitte
              aus Ihrem Facebook-Benutzerkonto aus.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Verwendung der Facebook Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Details finden Sie hier:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://www.facebook.com/legal/EU_data_transfer_addendum,
              https://de-de.facebook.com/help/566994660333381 und
              https://www.facebook.com/policy.php.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Twitter Plugin
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf dieser Website sind Funktionen des Dienstes Twitter
              eingebunden. Diese Funktionen werden angeboten durch die Twitter
              International Company, One Cumberland Place, Fenian Street, Dublin
              2, D02 AX07, Irland. Durch das Benutzen von Twitter und der
              Funktion „Re-Tweet“ werden die von Ihnen besuchten Websites mit
              Ihrem Twitter-Account verknüpft und anderen Nutzern bekannt
              gegeben. Dabei werden auch Daten an Twitter übertragen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Twitter
              erhalten. Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Twitter unter:
              https://twitter.com/de/privacy.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Verwendung des Twitter-Plugins erfolgt auf Grundlage von Art.
              6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an einer möglichst umfangreichen Sichtbarkeit in den
              Sozialen Medien. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt. Details
              finden Sie hier:
              https://gdpr.twitter.com/en/controller-to-controller-transfers.html.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Ihre Datenschutzeinstellungen bei Twitter können Sie in den
              Konto-Einstellungen unter https://twitter.com/account/settings
              ändern.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Instagram Plugin
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf dieser Website sind Funktionen des Dienstes Instagram
              eingebunden. Diese Funktionen werden angeboten durch die Facebook
              Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin
              2, Irland integriert.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie in Ihrem Instagram-Account eingeloggt sind, können Sie
              durch Anklicken des Instagram-Buttons die Inhalte dieser Website
              mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den
              Besuch dieser Website Ihrem Benutzerkonto zuordnen. Wir weisen
              darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom
              Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram
              erhalten.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Details finden Sie hier:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://www.facebook.com/legal/EU_data_transfer_addendum,
              https://help.instagram.com/519522125107875 und
              https://de-de.facebook.com/help/566994660333381.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Weitere Informationen hierzu finden Sie in der
              Datenschutzerklärung von Instagram:
              https://instagram.com/about/legal/privacy/.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Pinterest Plugin
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Auf dieser Website verwenden wir Social Plugins des sozialen
              Netzwerkes Pinterest, das von der Pinterest Europe Ltd.,
              Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Irland
              betrieben wird.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie eine Seite aufrufen, die ein solches Plugin enthält,
              stellt Ihr Browser eine direkte Verbindung zu den Servern von
              Pinterest her. Das Plugin übermittelt dabei Protokolldaten an den
              Server von Pinterest in die USA. Diese Protokolldaten enthalten
              möglicherweise Ihre IP-Adresse, die Adresse der besuchten
              Websites, die ebenfalls Pinterest-Funktionen enthalten, Art und
              Einstellungen des Browsers, Datum und Zeitpunkt der Anfrage, Ihre
              Verwendungsweise von Pinterest sowie Cookies.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse an einer möglichst umfangreichen
              Sichtbarkeit in den Sozialen Medien. Sofern eine entsprechende
              Einwilligung abgefragt wurde, erfolgt die Verarbeitung
              ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die
              Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Weitere Informationen zu Zweck, Umfang und weiterer Verarbeitung
              und Nutzung der Daten durch Pinterest sowie Ihre diesbezüglichen
              Rechte und Möglichkeiten zum Schutz Ihrer Privatsphäre finden Sie
              in den Datenschutzhinweisen von Pinterest:
              https://policy.pinterest.com/de/privacy-policy.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              6. Analyse-Tools und Werbung
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WordPress Statistik
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Diese Website nutzt „WordPress Statistik“, um Besucherzugriffe
              statistisch auszuwerten. Anbieter ist die Automattic Inc., 60 29th
              Street #343, San Francisco, CA 94110-4929, USA.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              WordPress Statistik verwendet Technologien, die die
              Wiedererkennung des Nutzers zum Zwecke der Analyse des
              Nutzerverhaltens ermöglichen (z.B. Cookies oder
              Device-Fingerprinting). WordPress Statistik erfasst zur Analyse u.
              a. Logdateien (Referrer, IP-Adresse, Browser u. a.), die Herkunft
              der Websitebesucher (Land, Stadt) und welche Aktionen sie auf der
              Seite getätigt haben (z.B. Klicks, Ansichten, Downloads). Die so
              erfassten Informationen über die Benutzung dieser Website werden
              auf Servern in den USA gespeichert. Ihre IP-Adresse wird nach der
              Verarbeitung und vor der Speicherung anonymisiert.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Nutzung dieses Analyse-Tools erfolgt auf Grundlage von Art. 6
              Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
              Interesse an der anonymisierten Analyse des Nutzerverhaltens, um
              sowohl sein Webangebot als auch seine Werbung zu optimieren.
              Sofern eine entsprechende Einwilligung abgefragt wurde (z. B. eine
              Einwilligung zur Speicherung von Cookies), erfolgt die
              Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a
              DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              7. Newsletter
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Newsletterdaten
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie den auf der Website angebotenen Newsletter beziehen
              möchten, benötigen wir von Ihnen eine EMail-Adresse sowie
              Informationen, welche uns die Überprüfung gestatten, dass Sie der
              Inhaber der angegebenen E-Mail-Adresse sind und mit dem Empfang
              des Newsletters einverstanden sind. Weitere Daten werden nicht
              bzw. nur auf freiwilliger Basis erhoben. Diese Daten verwenden wir
              ausschließlich für den Versand der angeforderten Informationen und
              geben diese nicht an Dritte weiter.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Verarbeitung der in das Newsletteranmeldeformular eingegebenen
              Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung
              (Art. 6 Abs. 1 lit. a DSGVO). Die erteilte Einwilligung zur
              Speicherung der Daten, der E-Mail-Adresse sowie deren Nutzung zum
              Versand des Newsletters können Sie jederzeit widerrufen, etwa über
              den „Austragen“-Link im Newsletter. Die Rechtmäßigkeit der bereits
              erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf
              unberührt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns
              hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem
              Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert
              und nach der Abbestellung des Newsletters oder nach Zweckfortfall
              aus der Newsletterverteilerliste gelöscht. Wir behalten uns vor,
              E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem
              Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6
              Abs. 1 lit. f DSGVO zu löschen oder zu sperren.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre
              E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in
              einer Blacklist gespeichert, um künftige Mailings zu verhindern.
              Die Daten aus der Blacklist werden nur für diesen Zweck verwendet
              und nicht mit anderen Daten zusammengeführt. Dies dient sowohl
              Ihrem Interesse als auch unserem Interesse an der Einhaltung der
              gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes
              Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die
              Speicherung in der Blacklist ist zeitlich nicht befristet. Sie
              können der Speicherung widersprechen, sofern Ihre Interessen unser
              berechtigtes Interesse überwiegen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              8. Plugins und Tools
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              YouTube mit erweitertem Datenschutz
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Diese Website bindet Videos der YouTube ein. Betreiber der Seiten
              ist die Google Ireland Limited („Google“), Gordon House, Barrow
              Street, Dublin 4, Irland.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wir nutzen YouTube im erweiterten Datenschutzmodus. Dieser Modus
              bewirkt laut YouTube, dass YouTube keine Informationen über die
              Besucher auf dieser Website speichert, bevor diese sich das Video
              ansehen. Die Weitergabe von Daten an YouTube-Partner wird durch
              den erweiterten Datenschutzmodus hingegen nicht zwingend
              ausgeschlossen. So stellt YouTube – unabhängig davon, ob Sie sich
              ein Video ansehen – eine Verbindung zum Google
              DoubleClick-Netzwerk her.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Sobald Sie ein YouTube-Video auf dieser Website starten, wird eine
              Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem
              YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht
              haben.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie
              YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil
              zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem
              YouTubeAccount ausloggen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Des Weiteren kann YouTube nach Starten eines Videos verschiedene
              Cookies auf Ihrem Endgerät speichern oder vergleichbare
              Wiedererkennungstechnologien (z.B. Device-Fingerprinting)
              einsetzen. Auf diese Weise kann YouTube Informationen über
              Besucher dieser Website erhalten. Diese Informationen werden u. a.
              verwendet, um Videostatistiken zu erfassen, die
              Anwenderfreundlichkeit zu verbessern und Betrugsversuchen
              vorzubeugen.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Gegebenenfalls können nach dem Start eines YouTube-Videos weitere
              Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen
              Einfluss haben.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden
              Darstellung unserer Online-Angebote.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1
              lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt
              wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von
              Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit
              widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Weitere Informationen über Datenschutz bei YouTube finden Sie in
              deren Datenschutzerklärung unter:
              https://policies.google.com/privacy?hl=de.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Google Maps
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Diese Seite nutzt den Kartendienst Google Maps.&nbsp;
            </span>
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Anbieter ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre
              IP-Adresse zu speichern. Diese Informationen werden in der Regel
              an einen Server von Google in den USA übertragen und dort
              gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf
              diese Datenübertragung.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Nutzung von Google Maps erfolgt im Interesse einer
              ansprechenden Darstellung unserer OnlineAngebote und an einer
              leichten Auffindbarkeit der von uns auf der Website angegebenen
              Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6
              Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung
              abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf
              Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist
              jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Datenübertragung in die USA wird auf die
              Standardvertragsklauseln der EU-Kommission gestützt.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Details finden Sie hier:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://privacy.google.com/businesses/gdprcontrollerterms/ und
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://privacy.google.com/businesses/gdprcontrollerterms/sccs/.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der
              Datenschutzerklärung von Google:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://policies.google.com/privacy?hl=de.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Google reCAPTCHA
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Wir nutzen „Google reCAPTCHA“ (im Folgenden „reCAPTCHA“) auf
              dieser Website.&nbsp;
            </span>
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Anbieter ist die Google Ireland Limited („Google“), Gordon House,
              Barrow Street, Dublin 4, Irland.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf
              dieser Website (z. B. in einem Kontaktformular) durch einen
              Menschen oder durch ein automatisiertes Programm erfolgt. Hierzu
              analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
              verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald
              der Websitebesucher die Website betritt. Zur Analyse wertet
              reCAPTCHA verschiedene Informationen aus (z. B. IP-Adresse,
              Verweildauer des Websitebesuchers auf der Website oder vom Nutzer
              getätigte Mausbewegungen). Die bei der Analyse erfassten Daten
              werden an Google weitergeleitet.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die reCAPTCHA-Analysen laufen vollständig im Hintergrund.
              Websitebesucher werden nicht darauf hingewiesen, dass eine Analyse
              stattfindet.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Die Speicherung und Analyse der Daten erfolgt auf Grundlage von
              Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein
              berechtigtes Interesse daran, seine Webangebote vor
              missbräuchlicher automatisierter Ausspähung und vor SPAM zu
              schützen. Sofern eine entsprechende Einwilligung abgefragt wurde,
              erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6
              Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <br />
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              Weitere Informationen zu Google reCAPTCHA entnehmen Sie den
              Google-Datenschutzbestimmungen und den Google Nutzungsbedingungen
              unter folgenden Links:
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://policies.google.com/privacy?hl=de und
            </span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>&nbsp;</span>
          </p>
          <p
            style={{
              marginTop: '0in',
              marginRight: '0in',
              marginBottom: '0in',
              marginLeft: '0in',
              lineHeight: '107%',
              fontSize: '15px',
              fontFamily: '"Calibri",sans-serif',
            }}
          >
            <span style={{ fontSize: '14px', lineHeight: '107%' }}>
              https://policies.google.com/terms?hl=de.
            </span>
          </p>
        </div>
      </Container>
    </div>
  );
}

export default PrivacyPolicy;
