import React from "react";
import { localStorageData } from "services/auth/localStorageData";

function HeartButton({ handleExpandClick, index, item }) {
  const val = item.expereince.filter(
    (item1) => localStorageData("_id") == item1.user[0]._id
  );

  const [value, setValue] = React.useState(val == "" ? false : true);
  const [hoverChange, setHoverChange] = React.useState(false);

  return (
    <>
      <button className="flex">
        {item.whatHelped === "5" ? (
          <>
            <img
              src={require("assets/img/heartfill.png")}
              alt="logo"
              className=" h-5 w-6 object-cover"
            />

            <span className="text-color-btn test-xs ml-3 break-all mb-2">
              {item.expereince.length}&nbsp;Antworten
            </span>
          </>
        ) : (
          <>
            <span className="text-color-btn test-xs  break-all mb-2">Half</span>
            <img
              src={require("assets/img/heartfill.png")}
              alt="logo"
              className=" h-5 w-6 object-cover ml-2"
            />{" "}
            <span className="text-color-btn test-xs ml-2 break-all mb-2">
              {item.expereince.length}&nbsp;mal
            </span>
          </>
        )}
      </button>
    </>
  );
}

export default HeartButton;
