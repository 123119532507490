import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  LoadScript,
} from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "270px",
};

function MapView({ lat, lng }) {
  const [map, setMap] = React.useState(null);
  let isConsent = false;
  if (
    window.Cookiebot.consent.preferences ||
    window.Cookiebot.consent.statistics ||
    window.Cookiebot.consent.marketing
  ) {
    isConsent = true;
  }

  const reloadCookie = async () => {
    window.Cookiebot.renew();
  };

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <div
      className="w-full h-64 rounded-lg"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {!isConsent && (
        <>
          <button
            type="button"
            className="border border-color-btn bg-white text-color-btn text-xs font-bold w-full px-6 py-1 rounded shadow hover:shadow-lg  focus:bg-color-btn focus:text-white ease-linear mt-2"
            onClick={reloadCookie}
            style={{
              width: "175px",
            }}
          >
            Allow Cookies to see map
          </button>
        </>
      )}
      {isConsent && (
        <LoadScript googleMapsApiKey="AIzaSyBu2WqDbYFglNC_u5HHcoFQmCgnxps6vH8">
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{ lat: lat, lng: lng }}
            zoom={10}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <>
              {" "}
              <Marker position={{ lat: lat, lng: lng }} />
            </>
          </GoogleMap>
        </LoadScript>
      )}
    </div>
  );
}

export default MapView;
