import React from 'react';

export default function LockProfile() {
  return (
    <div className='relative pt-16 mb-20 flex content-center items-center justify-center min-h-screen-75  header-static'>
      <div
        className='absolute top-0 w-full h-full bg-center bg-cover blur rounded-xl '
        style={{
          backgroundImage:
            'url("https://i.ibb.co/16p87pw/Screenshot-2022-10-20-at-1-14-34-AM.jpg")',
          // borderRadius: 15,
        }}
      >
        <span
          id='blackOverlay'
          className='w-full h-full absolute blur-xl	 rounded-xl '
        ></span>
      </div>
      <div className='container relative mx-auto'>
        <div className='items-center flex flex-wrap'>
          <div className='w-full px-4 ml-auto mr-auto text-center'>
            <div className='pr-1 mt-8'>
              <i className='fas fa-lock text-lg text-black'></i>
              <h1 className='text-black bold-styl mt-2 text-xs'>
                Werde Xpera Mitglied
              </h1>
              <p className='text text-xs'>
                und schalte dein professionelles Profil + 3 Anzeigenplätze auf
                Themenseiten deiner Wahl frei. Sei dort sichtbar, wo potenzielle
                Kunden als erstes nach Hilfe suchen.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
