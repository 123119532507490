import React from "react";
import Container from "@mui/material/Container";
import Tabs from "components/Tabs/Tab";
import TabPane from "components/Tabs/TabPane";
import UpdatePassword from "./UpdatePassword";
import ProfileSettings from "./ProfileSettings";
import ExtendedProfile from "./ExtendedProfile";

import useTranslation from "common/customHooks/translations";

function Settings() {
  const t = useTranslation();
  // console.log('again');
  return (
    <div className='min-h-screen '>
      <Container maxWidth='xl'>
        {/* <br /> */}
        <h1 className='md:text pt-16 text-black sm:text-sm font-semibold '>
          {t.settings}
        </h1>

        <Tabs>
          <TabPane name={t.profile} key='1'>
            <ProfileSettings />
          </TabPane>
          <TabPane name='öffentliches Profil' key='2'>
            <ExtendedProfile />
          </TabPane>
          <TabPane name={t.password} key='3'>
            <UpdatePassword />
          </TabPane>
        </Tabs>
      </Container>
    </div>
  );
}

export default Settings;
