import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import AutoSearch from 'components/AutoSearch';
import AutoAddress from 'components/AutoAddress';
import { Helmet } from 'react-helmet';

import useTranslation from 'common/customHooks/translations';
import { useNavigate } from 'react-router-dom';

function RateTreatment({ state, setState }) {
  const t = useTranslation();
  let navigate = useNavigate();

  // mean/treatmen
  const [meantreatment, setmeantreatment] = useState(state.meanselectTreatment);

  function selectvaluemean(val, id) {
    setState((prevState) => ({ ...prevState, meanselectTreatment: val }));
    setState((prevState) => ({ ...prevState, means_thearay_id: id }));
  }

  // thrapy/activity
  const [therapytreatment, setTherapytreatment] = useState(
    state.therapyselectTreatment
  );

  function selectvaluetherapy(val, id) {
    setState((prevState) => ({
      ...prevState,
      therapyselectTreatment: val,
    }));
    setState((prevState) => ({ ...prevState, means_thearay_id: id }));
  }

  // address lat and long
  function changeaddress(address, lat, long) {
    setState((prevState) => ({ ...prevState, address: address }));

    setState((prevState) => ({ ...prevState, lat: lat }));

    setState((prevState) => ({ ...prevState, long: long }));
  }

  const [expanded, setExpanded] = React.useState(false);
  const [expanded2, setExpanded2] = React.useState(false);
  const [expanded3, setExpanded3] = React.useState(false);
  const [expanded4, setExpanded4] = React.useState(false);

  // means/prepration
  const handleExpandClick = () => {
    setExpanded(!expanded);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded4(false);

    selectvaluetherapy('', '');
    setTherapytreatment('');
    setState((prevState) => ({ ...prevState, address: '' }));
    setState((prevState) => ({ ...prevState, lat: '' }));
    setState((prevState) => ({ ...prevState, long: '' }));
    setState((prevState) => ({ ...prevState, name: '' }));

    setState((prevState) => ({ ...prevState, others: '' }));
  };

  // therapy/activity
  const handleExpandClick2 = () => {
    setExpanded2(!expanded2);
    setExpanded(false);
    setExpanded3(false);
    setExpanded4(false);

    selectvaluemean('', '');
    setmeantreatment('');
    setState((prevState) => ({ ...prevState, address: '' }));
    setState((prevState) => ({ ...prevState, lat: '' }));
    setState((prevState) => ({ ...prevState, long: '' }));
    setState((prevState) => ({ ...prevState, name: '' }));

    setState((prevState) => ({ ...prevState, others: '' }));
  };

  // professional
  const handleExpandClick3 = () => {
    setExpanded3(!expanded3);
    setExpanded2(false);
    setExpanded(false);
    setExpanded4(false);

    selectvaluetherapy('', '');
    setTherapytreatment('');
    selectvaluemean('', '');
    setmeantreatment('');
    setState((prevState) => ({ ...prevState, others: '' }));
  };

  // others
  const handleExpandClick4 = () => {
    setExpanded4(!expanded4);
    setExpanded2(false);
    setExpanded3(false);
    setExpanded(false);

    selectvaluetherapy('', '');
    setTherapytreatment('');
    selectvaluemean('', '');
    setmeantreatment('');
    setState((prevState) => ({ ...prevState, address: '' }));
    setState((prevState) => ({ ...prevState, lat: '' }));
    setState((prevState) => ({ ...prevState, long: '' }));
    setState((prevState) => ({ ...prevState, name: '' }));
  };

  return (
    <div className=''>
      <Helmet>
        <script src='https://maps.googleapis.com/maps/api/js?key=AIzaSyB1vbNaII-bU8q1AYpLZHKZ2pfJcwfKrIo&libraries=places'></script>
      </Helmet>
      <h3 className='center-styl my-4 text-base font-semibold text-color-yellow'>
        {t.whathelped}
      </h3>

      <div className='my-8 '>
        <div className=' center-styl'>
          <button
            onClick={() => handleExpandClick()}
            className='btn2-styl h-14 treatment-view'
          >
            {t.meanspreparation}
          </button>
        </div>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <div className='center-styl'>
            <div className='border border-t-0 border-color-btn p-6 treatment-view'>
              <h4 className='text-center my-2 text-xs font-semibold text-color-btn'>
                Bitte wähle das Mittel, dass dir geholfen hat, aus unserer Liste
                aus oder gib es ein.
              </h4>

              {state.meantreatment != '' ? (
                <AutoSearch
                  input={meantreatment}
                  defaultvalue={state.meanselectTreatment}
                  setInput={setmeantreatment}
                  selectvalue={selectvaluemean}
                  placeholder={'Wie lautet der Name des Mittels?'}
                  suggestions={state.meantreatment}
                  noFound='no'
                  className='input-styl w-10'
                  alreadyExist={true}
                  countValue={1}
                  state={state}
                  setState={setState}
                />
              ) : (
                'loading'
              )}
            </div>
          </div>
        </Collapse>
      </div>

      <div className='my-8'>
        <div className=' center-styl'>
          <button
            onClick={() => handleExpandClick2()}
            className='btn2-styl h-14 treatment-view'
          >
            {t.therapyactivity}
          </button>
        </div>

        <Collapse in={expanded2} timeout='auto' unmountOnExit>
          <div className='center-styl'>
            <div className='border border-t-0 border-color-btn p-6 treatment-view'>
              <h4 className='text-center my-2 text-xs font-semibold text-color-btn'>
                Bitte wähle die Therapie oder die Aktivität, die dir geholfen
                hat, aus unserer Liste aus oder gib sie ein.
              </h4>

              {state.therapytreatment != '' ? (
                <AutoSearch
                  input={therapytreatment}
                  defaultvalue={state.therapyselectTreatment}
                  setInput={setTherapytreatment}
                  selectvalue={selectvaluetherapy}
                  placeholder={
                    'Wie lautet der Name der Therapie oder der Aktivität?'
                  }
                  suggestions={state.therapytreatment}
                  noFound='no'
                  countValue={2}
                  setState={setState}
                  state={state}
                  className='input-styl w-10'
                  alreadyExist={false}
                />
              ) : (
                'loading'
              )}
            </div>
          </div>
        </Collapse>
      </div>

      <div className='my-8'>
        <div className=' center-styl'>
          <button
            onClick={() => handleExpandClick3()}
            className='btn2-styl h-14 treatment-view'
          >
            {t.professionals}
          </button>
        </div>

        <Collapse in={expanded3} timeout='auto' unmountOnExit>
          <div className='center-styl'>
            <div className='border border-t-0 border-color-btn p-6 treatment-view'>
              <input
                id='username'
                placeholder={t.professionals_placeholder}
                name='username'
                type='username'
                className='input-styl mb-4'
                value={state.name}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    name: e.target.value,
                  }))
                }
              />

              <AutoAddress
                label={t.address_placeholder}
                changeaddress={changeaddress}
                value={state.address}
              />
            </div>
          </div>
        </Collapse>
      </div>
      {/* 
      <div className='my-6'>
        <div className=' center-styl'>
          <button
            onClick={() => navigate('/account/addquestion')}
            className='btn2-styl h-14 treatment-view'
          >
            {t.others}
          </button>
        </div>

         <Collapse in={expanded4} timeout='auto' unmountOnExit>
          <div
            className='center-styl'
            onClick={() => navigate('/account/addquestion')}
          >
            <div className='border border-t-0 border-color-btn'>
              <input
                id='username'
                placeholder={t.others_placeholder}
                name='username'
                type='username'
                className='input-styl mb-4'
                value={state.others}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    others: e.target.value,
                  }))
                }
              />
            </div>
          </div>
        </Collapse> 
      </div>
       */}
    </div>
  );
}

export default RateTreatment;
