import en from './en';
import ar from './ar';
import de from './de';

const language = {
  en,
  ar,
  de,
};

export default language;
