import React, { useState } from 'react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import useTranslation from 'common/customHooks/translations';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import GoogleApi from '../../components/SocialLogin/GoogleApi';
import FacebookApi from 'components/SocialLogin/FacebookApi';
import SwiperView from 'components/SwiperView';
import { useCheckEmail_UserNameAccount } from 'hooks';
import CircularProgress from '@mui/material/CircularProgress';

function Signup() {
  const [agbChecked, setAgbChecked] = useState(false);
  const { mutateAsync: checkEmailAccount, isLoading } =
    useCheckEmail_UserNameAccount();

  let navigate = useNavigate();
  const t = useTranslation();

  const formik = useFormik({
    initialValues: {
      pass: '',
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().min(4, t.mincharacters4).required(t.required),
      pass: Yup.string().min(8, t.mincharacters8).required(t.required),
    }),
    onSubmit: async (values) => {
      if (agbChecked) {
        const response = await checkEmailAccount({ username: values.email });

        // console.log(response);
        if (response.status) {
          navigate('/auth/addinfo', { state: values });
        }
      } else {
        // Handle case where checkboxes are not checked
        toast(
          'Bitte akzeptieren Sie die Nutzungsbedingungen und Datenschutzbestimmungen.'
        );
      }
      // toast('Add New info');

      // navigate('/addinfo', { state: values });
      // navigate('/addinfo');
    },
  });
  return (
    <div>
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <h1 className='md:text-lg mt-4 text-color-primary sm:text-sm mb-4 font-semibold text-center'>
              {t.getstart}
            </h1>
          </Grid>
        </Grid>

        <div className='mobileview'>
          <Grid container>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <SwiperView swiperkey={0} />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={5}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='text-color-grey desktopview text-center text-xs '>
              {t.signupdescription}
            </div>

            <form onSubmit={formik.handleSubmit} className='mt-4'>
              <div className='relative w-full mb-3 '>
                <input
                  id='email'
                  name='email'
                  type='email'
                  className='input-styl'
                  placeholder='Email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.email}
                  </div>
                ) : null}
              </div>

              <div className='relative w-full mb-3'>
                <input
                  name='pass'
                  id='pass'
                  type='password'
                  className='input-styl'
                  placeholder={t.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pass}
                />
                {formik.touched.pass && formik.errors.pass ? (
                  <div className='text-red-500 mt-2 text-xs'>
                    {formik.errors.pass}
                  </div>
                ) : null}
              </div>

              <div className='text-center my-6'>
                <label className='text-xs text-color-grey desktopviewnoHidden flex items-center'>
                  <input
                    type='checkbox'
                    onChange={() => setAgbChecked(!agbChecked)}
                    checked={agbChecked}
                  />
                  <span>
                    &nbsp; Ich stimme den
                    <a
                      className='text-black font-semibold'
                      target='_blank'
                      href='/agb'
                    >
                      &nbsp; AGB &nbsp;
                    </a>
                    und{' '}
                    <a
                      className='text-black font-semibold'
                      target='_blank'
                      href='/privacypolicy'
                    >
                      &nbsp;Datenschutzrichtlinien &nbsp;
                    </a>
                    sowie der Nutzung von Cookies zu und willige ein, dass ich
                    kostenlos per E-Mail benachrichtigt werde. Ich kann diese
                    Einwilligung jederzeit widerrufen.
                  </span>
                </label>
              </div>

              <div className='text-center my-6'>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <>
                    <button className='btn-styl' type='submit'>
                      {t.signupbtn}
                    </button>
                  </>
                )}
              </div>
            </form>

            <hr className='separator my-10' />

            <div className='text-center my-6'>
              <GoogleApi name={t.google} />

              <FacebookApi name={t.facebook} />
            </div>
            {/* <div className='text-color-grey text-xs  '>
              Indem du fortfährst, stimmst du den{' '}
              <a
                className='text-black font-semibold'
                target='_blank'
                href='/agb'
              >
                AGB{' '}
              </a>
              von XPera zu und bestätigst, dass du unsere
              <a
                className='text-black font-semibold'
                target='_blank'
                href='/privacypolicy'
              >
                &nbsp;Datenschutzrichtlinien &nbsp;
              </a>
              gelesen hast.
            </div> */}
            <hr className='border-color-yellow my-8' />

            <div className='text-black text-xs mb-6'>
              Du bist schon Mitglied?&nbsp;
              <button
                className='text-color-btn font-semibold'
                onClick={() => navigate('/signin')}
              >
                Hier geht’s zur Anmeldung!
              </button>
            </div>

            <div className='text-center my-6'>
              <button
                onClick={() => navigate('/auth/SignupTherapy')}
                className='btnthearpy-styl'
                type='submit'
              >
                Du bist Heilpraktiker, Therapeut oder Gesundheits-Coach?
              </button>
            </div>
          </Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className='desktopview'>
              <SwiperView swiperkey={0} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Signup;
