import React from 'react';
import Rating from '@mui/material/Rating';
import { ImageEndPoint } from 'config/config';
import { useAddThema } from 'hooks';
import { data } from 'autoprefixer';
import { localStorageData } from 'services/auth/localStorageData';
import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import ErrorService from 'services/formatError/ErrorService';
import userServices from 'services/httpService/userAuth/userServices';
import { useNavigate } from 'react-router-dom';

function PageCard({ data, thema, reftechThema }) {
  let navigate = useNavigate();

  const { mutateAsync: UplodThema, isLoading } = useAddThema();

  const submitThema = async () => {
    let data1 = {
      userId: localStorageData('_id'),
      symptons_id: data._id,
    };
    const response = await UplodThema(data1);

    reftechThema();
  };

  const deleteService = useMutation(
    (deleteService) =>
      userServices.commonPostService('/post/deleteThema', deleteService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success('Thema entfernt!');
        reftechThema();
        checkValue();
      },
    }
  );

  function checkValue() {
    const val = thema.filter((item1) => item1.symptons_id == data._id);

    if (val == '') {
      return (
        <div className='center-styl mt-4'>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <button
              type='submit'
              className='btn2-styl'
              onClick={() => submitThema()}
            >
              Thema merken
            </button>
          )}
        </div>
      );
    } else {
      return (
        <div className='center-styl mt-4'>
          <button
            type='submit'
            className='btn-color'
            onClick={() =>
              deleteService.mutate({
                Id: data._id,
                userId: localStorageData('_id'),
              })
            }
          >
            Thema entfernen
          </button>
        </div>
      );
    }
  }

  return (
    <>
      <div className='bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border border-frame-gray'>
        <div className='flex flex-row border-b p-4	border-color-grey '>
          <div className='text-xs text-black font-semibold'>
            Interessante Themen
          </div>
        </div>
        <div className='p-6'>
          <a
            className='flex flex-row cursor-pointer'
            onClick={() => {
              navigate(
                `/account/thema/${data.name.replace(/\s/g, '')}/${data._id}`
              );
            }}
          >
            <img
              id='output'
              src={ImageEndPoint + data.image}
              loading='lazy'
              className='w-10 h-10 rounded-full object-cover'
              alt='page'
            />
            <div className='text-xs font-semibold text-black m-2 '>
              {data.name}
            </div>
          </a>
          <div className='center-styl'>
            {data != '' ? (
              <Rating
                name='read-only'
                value={
                  data.expereinces == ''
                    ? 0
                    : Math.round(data.expereinces[0].avg)
                }
                readOnly
                size='small'
              />
            ) : null}
          </div>
          {checkValue()}
        </div>
      </div>
    </>
  );
}

export default PageCard;
