import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { localStorageData } from "services/auth/localStorageData";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import { toast } from "react-toastify";
import ErrorService from "services/formatError/ErrorService";

function AddExperienceCard({ routevalue }) {
  let navigate = useNavigate();
  let { id } = useParams();

  const { IsCognito, currentName, currentPic, profile } = useSelector(
    (state) => state.User
  );
  const [diseaseName, setDiseaseName] = React.useState("");

  const getAllDisease = useQuery(
    ["themadiseaseDataCard", id],
    () => userServices.commonGetService(`/admin/getallDisease/${id}`),
    {
      refetchOnWindowFocus: false,
      enabled: id == "undefined" || id == undefined ? false : true,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        console.log("new=>", res.data.data[0].name);
        setDiseaseName(res.data.data[0].name);
      },
    }
  );

  return (
    <div className='flex justify-between'>
      <button
        onClick={() =>
          navigate("/account/addquestion", {
            state: {
              newValue: diseaseName,
            },
          })
        }
        className='bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border border-color-yellow p-2 mb-4'
      >
        <div className='flex flex-row '>
          <img
            id='output'
            src={`${
              localStorageData("_id")
                ? currentPic
                : "https://ui-avatars.com/api/?name=NA"
            }`}
            className='w-10 h-10 rounded-full object-cover ml-4'
            alt=''
          />
          <div className='my-2 ml-2 mr-1'>
            <i class='far fa-question-circle text-sm text-color-yellow'></i>

            {/* <img
              src={require('assets/img/pen.png')}
              alt='logo'
              className=' h-5 lg:w-5 md:w-5 sm:w-7 xs:w-7 bg-cover object-cover'
            /> */}
          </div>
          <div className='lg:text-xs md:text-xs sm:text-xs2 xs:text-xs2 truncate  text-black my-2'>
            Frage stellen
          </div>
        </div>
      </button>

      <button
        onClick={() =>
          navigate("/account/post", {
            state: {
              newValue: diseaseName,
            },
          })
        }
        className='bg-white shadow ml-4 rounded-lg  w-full border-opacity-100 overflow-hidden border border-color-yellow p-2 mb-4'
      >
        <div className='flex flex-row '>
          <img
            id='output'
            // src={  localStorageData('_id')  currentPic}
            src={`${
              localStorageData("_id")
                ? currentPic
                : "https://ui-avatars.com/api/?name=NA"
            }`}
            className='w-10 h-10 rounded-full object-cover ml-4'
            alt=''
          />
          <div className='my-2 ml-2'>
            <i class='fas fa-info-circle text-sm text-color-btn'></i>

            {/* <img
              src={require('assets/img/pen.png')}
              alt='logo'
              className=' h-5 lg:w-5 md:w-5 sm:w-7 xs:w-7 bg-cover object-cover'
            /> */}
          </div>
          <div className='lg:text-xs md:text-xs sm:text-xs2 xs:text-xs2 truncate  text-black m-2'>
            Erfahrung posten
          </div>
        </div>
      </button>
    </div>
  );
}

export default AddExperienceCard;
