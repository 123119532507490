import React, { useEffect } from 'react';
import Container from '@mui/material/Container';
import ThemaDetailCard from '../../components/Cards/ThemaDetailCard';
import Grid from '@mui/material/Grid';
import { localStorageData } from 'services/auth/localStorageData';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import MyThema from 'components/Cards/MyThema';
import AddExperienceCard from 'components/Cards/AddExperienceCard';
import { useLocation, useParams } from 'react-router-dom';
import { useAddThema } from 'hooks';
import CircularProgress from '@mui/material/CircularProgress';
import PostCard from 'components/Cards/PostCard';
import { useNavigate } from 'react-router-dom';
import Filters from 'components/Filters';
import { Helmet } from 'react-helmet';
import AdCard from 'components/Cards/AdCard';
import AdData from 'components/Data/AdData';

function Thema(props) {
  let location = useLocation();
  let navigate = useNavigate();

  const { state } = useLocation();

  const [allPost, setallPost] = React.useState([]);
  const [filterallPost, setallfilterPost] = React.useState([]);
  const [disease, setDisease] = React.useState([]);
 const [SEOcontent, setSEOcontent] = React.useState('');
 const [allThema, setallThema] = React.useState([]);

 const [diseaseName, setDiseaseName] = React.useState('');

 let { id, name } = useParams();
 props.themaData(name);
 // React.useEffect(() => {
 //   getAllPosts.refetch();
 // }, [location, navigate]);

 const getAllThema = useQuery(
   'allpostThema',
   () =>
     userServices.commonGetService(
       `/post/getThemeByUserid/${localStorageData('_id')}`
     ),
   {
     enabled: localStorageData('_id') ? true : false,
     refetchOnWindowFocus: false,
     onError: (error) => {
       toast.error(ErrorService.uniformError(error));
     },
     onSuccess: (res) => {
       setallThema(res.data.data);
     },
   }
 );

 function reftechThema(data) {
   getAllThema.refetch();
 }

 // add thema

 const { mutateAsync: UplodThema, isLoading } = useAddThema();

 const submitThema = async () => {
   let data1 = {
     userId: localStorageData('_id'),
     symptons_id: id,
   };
   const response = await UplodThema(data1);

   reftechThema();
 };

 const deleteService = useMutation(
   (deleteService) =>
     userServices.commonPostService('/post/deleteThema', deleteService),
   {
     onError: (error) => {
       toast.error(ErrorService.uniformError(error));
     },
     onSuccess: (res) => {
       toast.success('Thema entfernt!');
       reftechThema();
       AddThema();
     },
   }
 );

 function AddThema() {
   const val = allThema.filter((item1) => item1.symptons_id == disease._id);

   if (val == '') {
     return (
       <>
         {isLoading ? (
           <CircularProgress />
         ) : (
           <button
             type='submit'
             className='border border-color-btn bg-white text-color-btn text-xs font-bold w-full uppercase px-6 py-1 rounded shadow hover:shadow-lg  focus:bg-color-btn focus:text-white ease-linear mt-2 transition-all duration-150'
             onClick={() => submitThema()}
           >
             Thema merken
           </button>
         )}
       </>
     );
   } else {
     return (
       <>
         <button
           type='submit'
           className={`border border-color-btn bg-color-btn text-white text-xs font-bold uppercase w-full px-4 py-1 mt-2 rounded shadow hover:shadow-lg focus:bg-color-btn focus:text-white ease-linear transition-all duration-150`}
           onClick={() =>
             deleteService.mutate({
               Id: id,
               userId: localStorageData('_id'),
             })
           }
         >
           Thema entfernen
         </button>
       </>
     );
   }
 }

 // All disease

 const [affectedStyl, setAffectedStyl] = React.useState('');

 const getAllDisease = useQuery(
   ['themadiseaseDatanew', id],
   () => userServices.commonGetService(`/admin/getallDisease/${id}`),
   {
     refetchOnWindowFocus: false,
     onError: (error) => {
       toast.error(ErrorService.uniformError(error));
     },
     onSuccess: (res) => {
       // getAllDisease.refetch();
       setDisease(res.data.data[0]);
       setDiseaseName(res.data.data[0].name);

       let keywords = '';

       res.data.data[0].keywords.map((item) => {
         keywords = keywords + ',' + item;
       });

       keywords = keywords.slice(1);

       setSEOcontent(keywords);

       setAffectedStyl(
         res.data.data[0].sympaffected.filter(
           (item1) => localStorageData('_id') == item1.userId
         )
       );
     },
   }
 );

  // affected

  const affectedService = useMutation(
    (affectedService) =>
      userServices.commonPostService('/social/updateaffected', affectedService),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // reftechThema();
        getAllDisease.refetch();

        window.location.reload();
      },
    }
  );

  // get filtered post
  function reftechPost(data) {
    //// getAllPosts.refetch();

    const newState = allPost.map((obj) => {
      // 👇️ if id equals 2 replace object
      if (obj._id === data[0]._id) {
        return data[0];
      }

      // 👇️ otherwise return object as is
      return obj;
    });

    setallPost(newState);
  }

  const getAllPosts = useQuery(
    ['allfilterpostdata', id],
    () => userServices.commonGetService(`/post/getAllPostByDiseaseId/${id}`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i]['avgRating'] =
            res.data.data[i].expereince != ''
              ? Math.round(
                  res.data.data[i].expereince.reduce(
                    (sum, { rating }) => sum + rating,
                    0
                  ) / res.data.data[i].expereince.length
                )
              : 0;
        }

        setallPost(res.data.data);

        setallfilterPost(res.data.data);
      },
    }
  );

  // filters

  const filterValue = (value) => {
    let newarr = [];
    for (let i = 0; i < value.length; i++) {
      newarr.push(value[i]);
    }

    setallPost(newarr);
  };

  return (
    <div className='min-h-screen lg:mx-28 md:mx-4 sm:mx-1'>
      {disease != '' ? (
        <Helmet>
          <meta charSet='utf-8' />
          <title>{disease.name}</title>
          <link rel='canonical' href='https://xpera.de/account/themaDetail' />
          <meta name='description' content={disease.desc} />
          <meta name='keywords' content={SEOcontent} />
        </Helmet>
      ) : null}

      <Container maxWidth='lg'>
        <Grid container spacing={10}>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            <ThemaDetailCard data={id} affectedStyl={affectedStyl} />

            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <div className='my-1 desktopview'>
                  {disease != '' ? (
                    <AddExperienceCard routevalue={true} />
                  ) : (
                    'loading'
                  )}
                </div>
              </Grid>

              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                <button
                  className={`border border-color-yellow ${
                    affectedStyl != ''
                      ? 'bg-color-yellow text-white'
                      : 'text-color-yellow bg-white'
                  } text-xs font-bold uppercase px-2 py-1 rounded shadow hover:shadow-lg tracking-wider focus:bg-color-yellow focus:text-white ease-linear transition-all duration-150 mt-2`}
                  onClick={() => {
                    affectedService.mutate({
                      _id: id,
                      userId: localStorageData('_id'),
                    });
                    // setAffectedStyl(!affectedStyl);
                  }}
                >
                  bin betroffen
                </button>
              </Grid>
              <Grid item xl={2} lg={2} md={3} sm={6} xs={6}>
                {AddThema()}
              </Grid>
            </Grid>
            <div className=' mb-4'>
              <Grid container spacing={2}>
                <Filters filterValue={filterValue} data={filterallPost} />
              </Grid>
            </div>
            <Grid container spacing={2}>
              <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                <PostCard
                  reftechPost={reftechPost}
                  data={allPost}
                  showAll={false}
                />
              </Grid>

              <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                <div className=''>
                  <AdCard link={window.location.href} />
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className='desktopview'>
              <MyThema data={allThema} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Thema;
