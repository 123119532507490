import React from "react";
import Rating from "@mui/material/Rating";
import { localStorageData } from "services/auth/localStorageData";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import useTranslation from "common/customHooks/translations";

import { strangerName } from "config/config";
function WriteComment(props) {
  const t = useTranslation();

  const [value, setValue] = React.useState(
    props.isEdit ? props.expereince.rating : 2
  );
  const { IsCognito, currentPic } = useSelector((state) => state.User);
  const localtz = moment.tz.guess();
  const [comment, setComment] = React.useState(
    props.isEdit ? props.expereince.expereince : ""
  );

  const addNewComment = useMutation(
    (NewComment) =>
      userServices.commonPostService("/post/uploadParentComment", NewComment),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setComment("");
        props.reftechPost(res.data.data);

        props.expand(false);
      },
    }
  );

  const EditComment = useMutation(
    (editComment) =>
      userServices.commonPostService(
        "/post/updateparentExpereince",
        editComment
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        setComment("");
        props.reftechPost(res.data.data);

        props.expand(false);
      },
    }
  );

  const PostNewComment = async () => {
    if (localStorageData("_id")) {
      if (comment) {
        let data = {
          Isincognito: IsCognito,
          userId: localStorageData("_id"),
          postId: props.item._id,
          own_experience: comment,
          rating: value,
          timeZone: localtz,
          dateTime: new Date(),
        };

        addNewComment.mutate(data);
      }
    } else {
      toast.error(t.loginfirst);
    }
  };

  const PostEditComment = async () => {
    if (localStorageData("_id")) {
      if (comment) {
        let data = {
          userId: localStorageData("_id"),
          exexperienceId: props.expereince._id,
          postId: props.item._id,
          updatedcomment: comment,
          rating: value,
        };

        EditComment.mutate(data);
      }
    } else {
      toast.error(t.loginfirst);
    }
  };

  return (
    <div className='py-2 px-4'>
      <div className='flex justify-between'>
        <div className='text-black  text-xs'>
          Wie erfolgreich war Deine Behandlung?
        </div>
        <Rating
          name='simple-controlled'
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          size='small'
        />
      </div>
      <div className='text-black  py-4'>
        Hast Du noch mehr Informationen für betroffene Menschen?
      </div>
      <div>
        <textarea
          type='text'
          rows='3'
          cols='10'
          name='desc'
          id='desc'
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className='input-styl desc-styl'
          placeholder='Erzähle mehr über Deine gemachten Erfahrungen und gib damit anderen Menschen Perspektiven und Hilfe. Dankeschön!'
        ></textarea>
      </div>

      <div className='float-right my-4'>
        <button
          type='submit'
          onClick={() => (props.isEdit ? PostEditComment() : PostNewComment())}
          className='btn2-styl'
        >
          Erfahrung posten
        </button>
      </div>
    </div>
  );
}

export default WriteComment;
