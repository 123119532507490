/*eslint-disable*/
import React from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import IndexDropdown from "components/Dropdowns/IndexDropdown.js";
import { account } from "routes/config";
import useTranslation from "common/customHooks/translations";
import { localStorageData } from "services/auth/localStorageData";

export default function Sidebar({ themaName, setThemaname }) {
  const [collapseShow, setCollapseShow] = React.useState("hidden");
  const t = useTranslation();
  let navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
  };

  function funThemaName() {
    setThemaname("");

    // let urlElements = window.location.href.split("/");

    // if (urlElements[5] != "undefined" || urlElements[5] != undefined) {
    //   setThemaname(urlElements[5]);
    // }
  }

  const style = ({ isActive }) => {
    return {
      color: "white",
      backgroundColor: isActive
        ? "rgba(255, 255, 255, 0.3)"
        : "rgba(255, 255, 255, 0.0)",
      padding: 10,
    };
  };
  return (
    <>
      <nav className='md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-sidebar flex flex-wrap items-center justify-between relative md:w-64 z-20 py-4 pl-6'>
        <div className='md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto'>
          {/* Toggler */}
          {/* <button
            className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
            type='button'
            onClick={() => setCollapseShow('bg-white m-2 py-3 px-6')}
          >
            <i className='fas fa-bars'></i>
          </button> */}
          {/* Brand */}
          <div className=' center-styl'>
            <a
              className='md:block items-center md:pb-2 text-white mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0'
              onClick={() => navigate("/account/home")}
            >
              <img
                src={require("assets/img/logosidebar.png")}
                alt='logo'
                className='xs:w-20 sm:w-20 md:w-full lg:w-full xs:h-6 sm:h-6 md:h-8 lg:h-8'
              />
            </a>
          </div>
          {/* User */}
          <ul className=' items-center flex flex-wrap mobileview list-none'>
            <li className='inline-block relative'>
              {localStorageData("_id") ? (
                <IndexDropdown />
              ) : (
                <button
                  className='text-white font-bold mr-2'
                  onClick={() => navigate("/signin")}
                  type='button'
                >
                  {t.signbtn}
                </button>
              )}
            </li>
          </ul>
          {/* Collapse */}
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            {/* Collapse header */}
            <div className='md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200'>
              <div className='flex flex-wrap'>
                <div className='w-6/12'>
                  <Link
                    className='md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-semibold p-4 px-0'
                    to='/'
                  >
                    Xpera
                  </Link>
                </div>
                <div className='w-6/12 flex justify-end'>
                  <button
                    type='button'
                    className='cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent'
                    onClick={() => setCollapseShow("hidden")}
                  >
                    <i className='fas fa-times'></i>
                  </button>
                </div>
              </div>
            </div>
            {/* Form */}
            <form className='mt-6 mb-4 md:hidden'>
              <div className='mb-3 pt-0'>
                <input
                  type='text'
                  placeholder='Search'
                  className=' px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal'
                />
              </div>
            </form>

            <ul className='md:flex-col md:min-w-full flex flex-col list-none ulHight'>
              <li className='items-center mt-2'>
                <NavLink
                  onClick={() => {
                    funThemaName();
                  }}
                  className='text-xs  py-2 flex tracking-widest	'
                  style={style}
                  to={`/${account}/home`}
                >
                  <img
                    src={require("assets/img/home.png")}
                    alt='logo'
                    className=' h-5 w-5 object-cover mr-2'
                  />

                  {t.home}
                </NavLink>
              </li>
              <li className='items-center mt-2'>
                <NavLink
                  className='text-xs  py-3 flex tracking-widest truncate '
                  style={style}
                  onClick={handleClick}
                  to={`${localStorageData("_id") ? `/${account}/thema` : "/"}`}
                >
                  <img
                    src={require("assets/img/med.png")}
                    alt='logo'
                    className=' h-5 w-5 object-cover mr-2'
                  />
                  <span className='truncate'>
                    {" "}
                    {themaName != "" ? <>{themaName}</> : <>{t.thema}</>}
                  </span>
                </NavLink>
              </li>

              <li className=' mt-2'>
                <NavLink
                  onClick={() => {
                    funThemaName();
                  }}
                  className='text-xs py-3 flex tracking-wider	'
                  style={style}
                  to={`/${account}/post`}
                  state={{ newValue: "" }}
                >
                  <img
                    src={require("assets/img/newpost.png")}
                    alt='logo'
                    className=' h-5 w-5 object-cover mr-2'
                  />
                  {t.postsidebar}
                </NavLink>
              </li>

              <li className=' mt-2'>
                <NavLink
                  onClick={() => {
                    funThemaName();
                  }}
                  className='text-xs py-3 flex tracking-wider	'
                  style={style}
                  to={`/${account}/addquestion`}
                  state={{ newValue: "" }}
                >
                  <img
                    src={require("assets/img/newpost.png")}
                    alt='logo'
                    className=' h-5 w-5 object-cover mr-2'
                  />
                  Frage stellen
                </NavLink>
              </li>
            </ul>

            <div className='border-t border-white	mr-2 desktopview'>
              <div className='text-[10px] text-color-lightgrey mt-2'>
                © Jens Jung und Bettina Henk GbR | 2023
              </div>
              <div className='text-xs2 text-color-lightgrey mt-2'>
                <a target='_blank' href='/privacypolicy'>
                  Datenschutz
                </a>{" "}
                |{" "}
                <a target='_blank' href='/agb'>
                  AGB
                </a>{" "}
                |{" "}
                <a target='_blank' href='/impressum'>
                  Impressum
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
