import React from "react";
import { localStorageData } from "services/auth/localStorageData";

function HeartButton({ handleExpandClick, index, item }) {
  const val = item.expereince.filter(
    (item1) => localStorageData("_id") == item1.user[0]._id
  );

  const [value, setValue] = React.useState(val == "" ? false : true);
  const [hoverChange, setHoverChange] = React.useState(false);

  return (
    <>
      <button
        onClick={() => {
          handleExpandClick(index);
          setValue(!value);
        }}
        className="flex"
        onMouseEnter={() => setHoverChange(true)}
        onMouseLeave={() => setHoverChange(false)}
      >
        {hoverChange ? (
          <>
            <img
              src={require("assets/img/heartfill.png")}
              alt="logo"
              className=" h-5 w-6 object-cover"
            />
          </>
        ) : (
          <>
            {val != "" ? (
              <img
                src={require("assets/img/heartfill.png")}
                alt="logo"
                className=" h-5 w-6 object-cover"
              />
            ) : (
              <img
                src={require("assets/img/heart.png")}
                alt="logo"
                className=" h-5 w-6 object-cover"
              />
            )}
          </>
        )}

        {hoverChange ? (
          <span className="text-color-btn test-xs ml-3 break-all mb-2 ">
            eigene Erfahrung hinzufügen
          </span>
        ) : (
          <>
            <span className="text-color-btn test-xs ml-3 break-all mb-2">
              {localStorageData("_id") == item.user._id ? (
                <>
                  {item.expereince.length == 1 ? (
                    "half Dir"
                  ) : (
                    <span className="-ml-4">
                      half Dir und {item.expereince.length - 1} Betroffenen
                    </span>
                  )}
                </>
              ) : <>half {item.expereince.length} Betroffenen</> ? (
                val != "" ? (
                  <>
                    {item.whatHelped === "5"
                      ? "Du hast geantwortet"
                      : "Half dir auch"}
                  </>
                ) : (
                  <>
                    {" "}
                    {/* {item.expereince.length} */}
                    {item.whatHelped === "5"
                      ? "eigene Antwort hinzufügen"
                      : "Half mir auch"}
                  </>
                )
              ) : null}
            </span>
          </>
        )}
      </button>
    </>
  );
}

export default HeartButton;
