import React from 'react';
import useTranslation from 'common/customHooks/translations';
import Rating from '@mui/material/Rating';

function Helped({ state, setState }) {
  const t = useTranslation();
  // const [value, setValue] = React.useState(0);

  return (
    <div className='mb-10'>
      <h3 className='center-styl my-4 text-base font-semibold text-color-yellow'>
        {t.treatmentsuccess}
      </h3>
      <h3 className='center-styl my-4 mt-10 text-base font-semibold text-black'>
        {t.ratetreatment}
      </h3>
      <div className='center-styl mt-6'>
        <Rating
          name='simple-controlled'
          value={state.rating}
          size='large'
          onChange={(event, newValue) => {
            setState((prevState) => ({ ...prevState, rating: newValue }));
          }}
        />
      </div>
    </div>
  );
}

export default Helped;
