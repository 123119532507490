import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import { localToken, endPoint, ImageEndPoint } from "config/config";

const columns = [
  { id: "uniqueProfileName", label: "Name", minWidth: 170 },

  { id: "title", label: "title", minWidth: 170 },

  { id: "address", label: "address", minWidth: 170 },
  { id: "phone", label: "phone", minWidth: 170 },

  { id: "website", label: "website", minWidth: 170 },

  { id: "status", label: "status", minWidth: 170 },

  { id: "options", label: "options", minWidth: 170 },
];

function ViewExtendedProfile(props) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const approveuser = (row) => {
    const r = window.confirm("Do You Really Want to do It ?");
    if (r == true) {
      props.approveuser.mutate({
        _id: row._id,
      });
    }
  };

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];

                      if (column.id === "options") {
                        return (
                          <div className="m-4">
                            <button
                              aria-label="edit"
                              className={
                                row.isApprovedByAdmin == false
                                  ? "btn-styl"
                                  : "bg-red-500 text-white text-xs2  font-bold uppercase px-3 py-2 rounded shadow hover:shadow-lg  w-full ease-linear transition-all duration-150"
                              }
                              onClick={() => approveuser(row)}
                            >
                              {row.isApprovedByAdmin == false
                                ? "Approve"
                                : "Cancel"}
                            </button>
                            &nbsp;
                          </div>
                        );
                      } else if (column.id === "status") {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {row.isApprovedByAdmin == true
                              ? "approved"
                              : "pending"}
                          </TableCell>
                        );
                      } else {
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.format && typeof value === "number"
                              ? column.format(value)
                              : value}
                          </TableCell>
                        );
                      }
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}

export default ViewExtendedProfile;
