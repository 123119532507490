import React, { useState, Suspense, lazy } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { localStorageData } from 'services/auth/localStorageData';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import MyThema from 'components/Cards/MyThema';
import UnLockProfile from './UnLockProfile';
import { useParams } from 'react-router-dom';
import { ImageEndPoint } from 'config/config';
import MainProfile from './MainProfile';
import ProfileLoading from './ProfileLoading';
import NotFound from 'pages/StaticPages/NotFound';
// const MainProfile = lazy(() => import('./MainProfile'));

function Profile() {
  const params = useParams();
  const [profileData, setProfileData] = React.useState([]);

  const [disease, setDisease] = React.useState([]);
  const [allThema, setallThema] = React.useState([]);

  const getProfile = useQuery(
    'allProfile',
    () =>
      userServices.commonGetService(
        `/social/getExtendedProfileByName/${params.name}`
      ),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);
        // console.log('=>', res.data.data.user);
        setProfileData(res.data.data);
      },
    }
  );

  const getAllDisease = useQuery(
    'allpostdisease',
    () => userServices.commonGetService(`/admin/getallDisease`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);
        const random = Math.floor(Math.random() * res.data.data.length);

        setDisease(res.data.data[random]);
      },
    }
  );

  const getAllThema = useQuery(
    'allpostThema',
    () =>
      userServices.commonGetService(
        `/post/getThemeByUserid/${localStorageData('_id')}`
      ),
    {
      enabled: localStorageData('_id') ? true : false,
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        // setDisease(res.data.data);

        setallThema(res.data.data);
      },
    }
  );

  return (
    <div className='min-h-screen pt-14'>
      <Container maxWidth='lg'>
        <Grid container spacing={4}>
          <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
            {/* {profileData == 'notfound' && profileData.user == '' ? (
              <NotFound />
            ) : (
              <Suspense fallback={<ProfileLoading />}>
                <MainProfile profileData={profileData} />
              </Suspense>
            )} */}

            {profileData == 'notfound' ? (
              <NotFound />
            ) : getProfile.isLoading ? (
              <ProfileLoading />
            ) : (
              <MainProfile profileData={profileData} />
            )}
          </Grid>

          <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
            <div className=' fixed right-0 h-full'>
              <MyThema data={allThema} disease={disease} />
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default Profile;
