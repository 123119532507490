import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import userServices from 'services/httpService/userAuth/userServices';
import { toast } from 'react-toastify';
import ErrorService from 'services/formatError/ErrorService';
import Skeleton from '@mui/material/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { store } from 'reactStore/MainStore';

import { SetOnlineUser } from 'reactStore/actions/Actions';

export default function StatsCard() {
  const [stats, setStats] = React.useState({});

  const dispatch = useDispatch();

  const { onlineUser } = useSelector((state) => state.User);

  const {
    IsCognito,
    userOnline,
    currentName,
    currentPic,
    profile,
    countRandom,
  } = useSelector((state) => state.User);

  function generateRandomPlusMinus() {
    let randomvalueToPlusOrMinus = Math.floor(Math.random() * (6 - 1 + 1)) + 1;
    ////console.log(randomvalueToPlusOrMinus)

    let plusorMinus = Math.floor(Math.random() * 2);

    ////console.log(plusorMinus)
    let randomCount;
    let online;

    if (plusorMinus == 0) {
      online = userOnline + randomvalueToPlusOrMinus;
      randomCount = countRandom + 1;
    } else {
      online = userOnline - randomvalueToPlusOrMinus;
      randomCount = countRandom + 1;
    }

    if (countRandom == 100) {
      online = Math.floor(Math.random() * (220 - 50 + 1)) + 50;
      randomCount = 1;
    }

    let data = {
      countRandom: randomCount,
      userOnline: online,
    };
    dispatch(SetOnlineUser(data));
  }

  useEffect(() => {
    // setInterval(() => {
    //   generateRandomPlusMinus();
    //   console.log("stats");
    // }, 1000);

    generateRandomPlusMinus();
  }, []);

  const getStats = useQuery(
    'allstats',
    () => userServices.commonGetService(`/post/getstatics`),
    {
      refetchOnWindowFocus: false,
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        console.log('stats=>', res.data.data);
        setStats(res.data.data);
      },
    }
  );

  return (
    <div className='bg-white shadow rounded-lg  w-full border-opacity-100 overflow-hidden border border-color-btn p-2 mb-4'>
      {getStats.isLoading ? (
        <div>
          <Skeleton variant='text' sx={{ fontSize: '1rem' }} />
        </div>
      ) : (
        <div className='grid lg:grid-cols-3 lg:gap-3 md:grid-cols-3 md:gap-3 sm:gap-2 sm:grid-cols-2 xs:gap-2 xs:grid-cols-2 ml-2 '>
          <div className='flex '>
            <img
              id='stats'
              src='https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1665133957~exp=1665134557~hmac=4110e6720c8f0607041a610ac62b4f617a296e3694ed0b92349460977defb551'
              className='w-8 h-8 object-cover rounded-full'
              alt='stats '
            />
            <h5 className='my-2 mx-2 text-xs2 text-color-btn '>
              {userOnline} online
            </h5>
          </div>

          <div className='flex '>
            <img
              id='stats'
              src={require('assets/img/behand.png')}
              className='w-6 h-6 object-contain my-1'
              alt='stats '
            />
            <h5 className='my-2 mx-2 text-xs2 text-color-btn '>
              {stats.posts} Erfahrungen
            </h5>
          </div>

          <div className='flex '>
            <img
              id='stats'
              src={require('assets/img/heartfill.png')}
              className='w-6 h-6 object-contain my-1'
              alt='stats '
            />
            <h5 className='my-2 mx-2 text-xs2 text-color-btn '>
              {stats.exp} mal geholfen
            </h5>
          </div>
        </div>
      )}
    </div>
  );
}
