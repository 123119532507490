import AutoSearch from "components/AutoSearch";
import React, { useState } from "react";
import useTranslation from "common/customHooks/translations";

function Symptoms({ state, setState, routevalue }) {
  const t = useTranslation();

  const [input, setInput] = useState(state.selectDisease);

  function selectvalue(val, id) {
    // setState({ ...state, selectDisease: val });
    setState((prevState) => ({ ...prevState, selectDisease: val }));
    setState((prevState) => ({ ...prevState, symptons_id: id }));
  }
  const lastSegment = window.location.pathname.split("/").filter(Boolean).pop();

  return (
    <div className="">
      <h3 className="center-styl my-6 text-base font-semibold text-color-yellow">
        {lastSegment == "post"
          ? "Zu welchem Thema hast du Erfahrungen gemacht?"
          : "Stelle deine Frage "}
      </h3>

      <h4 className="text-center my-6 text-xs font-semibold text-color-primary">
        Suche bitte das entsprechende Symptom oder die Krankheit über dieses
        Eingabefeld aus. Du kannst nur Themen auswählen, die in der Liste
        aufgeführt werden. Eigene Eingaben sind nicht möglich. ‌
      </h4>

      <div className="center-styl">
        <div className="treatment-view ">
          {state.disease != "" ? (
            <AutoSearch
              // input={input}
              input={`${routevalue != "" ? routevalue : input}`}
              defaultvalue={state.selectDisease}
              setInput={setInput}
              selectvalue={selectvalue}
              placeholder={t.addsymptoms}
              suggestions={state.disease}
              className="input-styl w-10"
              noFound="Dein Thema steht leider nicht zur Auswahl. Wähle etwas ähnliches aus der Liste."
              alreadyExist={false}
            />
          ) : (
            "loading"
          )}
        </div>
      </div>
    </div>
  );
}

export default Symptoms;
