import React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Collapse from "@mui/material/Collapse";
import { useMutation } from "react-query";
import {
  localStorageData,
  getLocalUserdata,
} from "services/auth/localStorageData";
import { toast } from "react-toastify";
import userServices from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { createPopper } from "@popperjs/core";

export default function Options({ reftechPost, expand, expereince, item, id }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [expanded, setExpanded] = React.useState(false);
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();

  console.log(localStorageData("isAdmin"));

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-start",
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const deleteComment = useMutation(
    (delComment) =>
      userServices.commonPostService("/post/deleteParentComment", delComment),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("comment deleted");

        // setsubComment('');
        reftechPost(res.data.data);
      },
    }
  );

  const reportComment = useMutation(
    (report) =>
      userServices.commonPostService("/post/reportParentComment", report),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (res) => {
        toast.success("Eintrag wurde gemeldet");

        // setsubComment('');
        // props.reftechPost(res.data.data);
      },
    }
  );

  return (
    <>
      {localStorageData("_id") ? (
        <a
          ref={btnDropdownRef}
          className="ml-2 text-color-grey"
          onClick={(e) => {
            dropdownPopoverShow
              ? closeDropdownPopover()
              : openDropdownPopover();
          }}
        >
          <i class="fas fa-ellipsis-v text-lg"></i>
        </a>
      ) : null}

      {/* <Menu
        id='basic-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {localStorageData('_id') == expereince.userId
          ? [
              <MenuItem
                onClick={() => {
                  handleClose();
                  expand(id);
                }}
              >
                Edit
              </MenuItem>,
              <MenuItem
                onClick={() => {
                  handleClose();
                  deleteComment.mutate({
                    exexperienceId: expereince._id,
                    postId: item._id,
                  });
                }}
              >
                Delete
              </MenuItem>,
            ]
          : null}

        {localStorageData('_id') == expereince.userId ? null : (
          <MenuItem
            onClick={() => {
              handleClose();
              reportComment.mutate({
                exexperienceId: expereince._id,
                userId: localStorageData('_id'),
                postId: item._id,
              });
            }}
          >
            Report
          </MenuItem>
        )}
      </Menu> */}

      <div
        ref={popoverDropdownRef}
        onBlur={closeDropdownPopover}
        onMouseLeave={closeDropdownPopover}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-xs2 z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48 absolute"
        }
      >
        {localStorageData("_id") == expereince.userId ||
        localStorageData("isAdmin") == true
          ? [
              <a
                href="#edit"
                className={
                  "text-xs2 py-2 px-4 font-normal block w-full hover:bg-gray-300 whitespace-nowrap bg-transparent text-blueGray-700"
                }
                onClick={() => {
                  closeDropdownPopover();
                  expand(id);
                }}
              >
                Beitrag bearbeiten
              </a>,
              <a
                href="#delete"
                className={
                  "text-xs2 py-2 px-4 font-normal hover:bg-gray-300 block w-full whitespace-nowrap bg-transparent text-blueGray-700"
                }
                onClick={() => {
                  closeDropdownPopover();
                  deleteComment.mutate({
                    exexperienceId: expereince._id,
                    postId: item._id,
                  });
                }}
              >
                Beitrag löschen
              </a>,
            ]
          : null}
        {localStorageData("_id") == expereince.userId ? null : (
          <a
            href="#report"
            className={
              "text-xs2 hover:bg-gray-300 py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
            }
            onClick={() => {
              closeDropdownPopover();
              reportComment.mutate({
                experienceId: expereince._id,
                userId: localStorageData("_id"),
                postId: item._id,
              });
            }}
          >
            Beitrag melden
          </a>
        )}
      </div>
    </>
  );
}
